import styles from './../ReportModal.module.scss';

export function PolicyFire() {
  return (
    <>
      <div className={styles.ReportModalBody__title}>Di cosa si tratta?</div>
      <div className={styles.ReportModalBody__text}>
        L’assicurazione incendio è la polizza assicurativa che ti fornisce una <strong>copertura finanziaria</strong> per i danni causati da incendi. La sua
        finalità è di proteggere la proprietà dai rischi associati agli incendi e ai danni ad essi connessi.
      </div>
      {/* Protection */}
      <div className={styles.ReportModalBody__title}>Da cosa protegge?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>danni alle abitazioni</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>danni agli edifici commerciali o industriali</span>
        </div>
      </div>
      {/* Cover */}
      <div className={styles.ReportModalBody__title}>Che cosa copre?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>costi di riparazione</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>costi di ricostruzione</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>danni subiti ai contenuti interni</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>responsabilità civile</span>
        </div>
      </div>
    </>
  );
}
