import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { ClaimDetailsCard } from '../ClaimDetailsCard';
import styles from '../../Claims.module.scss';

export function ClaimActive() {
  const [claims, setClaims] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const fetchClaims = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL || '/api'}/user/claims`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        if (response.data.status === 'success') {
          const filteredClaims = response.data.data.filter((claim: any) => {
            return claim.status === 'draft' || claim.status === 'open';
          });
          setClaims(filteredClaims);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClaims();
  }, [updated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (claims.length === 0) {
    return (
      <div className={styles.ClaimsBody}>
        <div className={styles.ClaimsEmpty__box}>
          <h5>Congratulazioni!</h5>
          <p>Non ci sono sinistri attualmente attivi</p>
        </div>
      </div>
    );
  }

  return <ClaimDetailsCard claims={claims} onUpdate={() => setUpdated(!updated)} />;
}
