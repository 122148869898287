import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { AppProvider } from './imb-react';
import itTranslation from './imb-react/locales/it.json';
import { PolarisVizProvider } from '@shopify/polaris-viz';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AppProvider i18n={itTranslation}>
    <PolarisVizProvider>
      <App />
    </PolarisVizProvider>
  </AppProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
