import { Button, Link } from '../../../../imb-react';
import styles from './Quotes.module.scss';

import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { classNames } from '../../../../imb-react/utilities/css';
import { Order } from '../../../../types';
import { getQuoteExpirationDate } from '../../../../utils/Common';

export function Quotes() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quotes, setQuotes] = useState<any[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  /**
   * Fetch user personal quotes
   * TODO: Remove this when the API will be ready (fetch also orders)
   */
  useEffect(() => {
    const fetchUserPersonalQuotes = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/quotes', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setQuotes(data.data);
          console.log(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserPersonalQuotes();
  }, []);

  /**
   * Empty markup
   */
  const emptyMarkup = (
    <div className={styles.QuotesBody}>
      <div className={styles.QuotesEmpty__box}>
        <Button outline>Nuovo preventivo</Button>
      </div>
    </div>
  );

  /**
   * Render status text
   * If the policy date_end is less than 30 days, show the text "In scadenza"
   * @param {Date} date
   */
  const renderStatus = (date: Date) => {
    const expirationDate = new Date(date);
    expirationDate.setDate(expirationDate.getDate() + 30);

    const today = new Date();
    if (expirationDate < today) {
      return { expiring: true, message: 'In scadenza' };
    } else {
      return { expiring: false, message: 'Attiva' };
    }
  };

  const cardWidth = 250;
  const totalCards = quotes.length;
  const getContainerWidth = () => {
    return totalCards * cardWidth + 'px';
  };

  const getTransformValue = () => {
    const translateValue = currentSlide * cardWidth;
    return `translateX(${translateValue}px)`;
  };

  /**
   * Quotes markup
   */
  const quotesMarkup = (
    <div className={styles.QuotesContainer}>
      <div className={styles.QuotesBody} style={{ transform: getTransformValue(), width: getContainerWidth() }}>
        {quotes.map((order: Order) => (
          <div key={order._id} className={styles.QuotesBox}>
            <div className={styles.QuotesBox__data}>
              <div>
                <div className={styles.QuotesBox__title}>{order.line_items[0].name}</div>

                <div
                  className={`${styles.QuotesBox__text} ${styles.QuotesBox__date} ${
                    renderStatus(new Date(order.date_created)).expiring ? styles.expiredDate : styles.activeDate
                  }`}
                >
                  <img src="/icon_time.svg" alt="" />
                  {getQuoteExpirationDate(new Date(order.date_created))}
                </div>
                <div className={styles.QuotesBox__text}>
                  premio
                  <div className={styles.QuotesBox__price}>{order.total_price} € /anno </div>
                </div>
              </div>
              <div className={styles.QuotesBox__icon}>
                <img
                  src={order.line_items[0].product.icon && process.env.REACT_APP_BLOB_IMAGES_URL + order.line_items[0].product.icon.key}
                  alt={order.line_items[0].product.icon && order.line_items[0].product.icon.title}
                />
              </div>
            </div>
            <div className={styles.QuotesBox__button}>
              <Button
                primary={!renderStatus(new Date(order.date_created)).expiring}
                disabled={renderStatus(new Date(order.date_created)).expiring}
                url="/products/quotes"
              >
                Acquista
              </Button>
            </div>
          </div>
        ))}
        {/* TODO: percorso per aggiungere nuovo preventivo dalla bacheca */}
        {/* <div className={styles.QuotesBody__button}>
        <a href="#">+</a>
      </div> */}
      </div>
    </div>
  );

  return <div>{quotes.length > 0 ? quotesMarkup : emptyMarkup}</div>;
}
