import { initializeApp } from 'firebase/app';
import { MessagePayload, getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export let app: any,
  messaging: any = null;

// Initialize Firebase only if the configuration is set
if (
  !firebaseConfig.apiKey ||
  !firebaseConfig.authDomain ||
  !firebaseConfig.projectId ||
  !firebaseConfig.storageBucket ||
  !firebaseConfig.messagingSenderId ||
  !firebaseConfig.appId ||
  !firebaseConfig.measurementId
) {
  console.error('Firebase configuration is not set!');
} else {
  app = initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  messaging = getMessaging(app);
}

/**
 * Request for a token from Firebase Cloud Messaging
 */
export const requestForToken = () => {
  if (!process.env.REACT_APP_FIREBASE_CM_KEY) {
    console.error('Firebase key is not set');
    return;
  }

  if (!messaging) {
    console.error('Firebase Cloud Messaging is not initialized');
    return;
  }

  return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_CM_KEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

/**
 * Handle incoming messages
 * @returns Promise
 */
export const onMessageListener = () =>
  new Promise<MessagePayload>((resolve) => {
    onMessage(messaging, (payload: MessagePayload) => {
      console.log('payload', payload);
      resolve(payload);
    });
  });
