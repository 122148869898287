import React from 'react';

import { Box } from '../../../Box';
import { CloseButton } from '../CloseButton';
import { HorizontalGrid } from '../../../HorizontalGrid';
import { HorizontalStack } from '../../../HorizontalStack';
import { Text } from '../../../Text';
import { useFeatures } from '../../../../utilities/features';

import styles from './Header.module.scss';

export interface HeaderProps {
  id: string;
  titleHidden: boolean;
  closing: boolean;
  children?: React.ReactNode;
  /** The icon for the title of the modal */
  iconSource?: string;
  /** The content to display on the right side of the modal */
  rightContent?: React.ReactNode;
  onClose(): void;
}

export function Header({ id, children, closing, titleHidden, iconSource, rightContent, onClose }: HeaderProps) {
  const { polarisSummerEditions2023 } = useFeatures();

  if (titleHidden || !children) {
    return (
      <Box position="absolute" insetInlineEnd={polarisSummerEditions2023 ? '1' : '0'} zIndex="1">
        <HorizontalStack gap="4" align="end" blockAlign="center">
          <CloseButton titleHidden={titleHidden} onClick={onClose} />
        </HorizontalStack>
      </Box>
    );
  }

  const iconMarkup = iconSource && <img src={iconSource} alt="" className={styles.Icon} />;

  const rightContentMarkup = rightContent && <div className={styles.RightContent}>{rightContent}</div>;

  return (
    <Box
      paddingBlockStart="4"
      paddingBlockEnd="4"
      paddingInlineStart="5"
      paddingInlineEnd="5"
      borderBlockEndWidth={polarisSummerEditions2023 ? undefined : '3'}
      borderColor="imb"
      background={polarisSummerEditions2023 ? 'bg-subdued' : undefined}
    >
      <HorizontalGrid columns={{ xs: '1fr auto' }} gap="4">
        <HorizontalStack gap="4" blockAlign="center">
          {iconMarkup}
          <Text id={id} as="h2" color="imb-dark" variant={polarisSummerEditions2023 ? 'headingMd' : 'headingLg'} breakWord>
            {children}
          </Text>
          {rightContentMarkup}
        </HorizontalStack>
        <CloseButton pressed={closing} titleHidden={titleHidden} onClick={onClose} />
      </HorizontalGrid>
    </Box>
  );
}
