import { TextField, FormLayout } from '../../../../imb-react';
import { classNames } from '../../../../imb-react/utilities/css';
import styles from '../StartNewClaim/StartNewClaim.module.scss';

type PaymentIBANProps = {
  accountHolderField: any;
  IBANField: any;
};

export function PaymentIBAN({ accountHolderField, IBANField }: PaymentIBANProps) {
  return (
    <div className={classNames(styles.Claims_form_maxWidth, styles.Claims_form_margin)}>
      <FormLayout.Group>
        <TextField
          label="Intestatario del conto"
          type="text"
          autoComplete="off"
          placeholder="Inserisci l’intestatario del conto"
          {...accountHolderField}
          isAccountField
        />
        <TextField label="IBAN" type="text" autoComplete="off" placeholder="Inserisci l’IBAN" {...IBANField} isAccountField />
      </FormLayout.Group>
    </div>
  );
}
