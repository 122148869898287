import styles from './../ReportModal.module.scss';

export function PolicyHealth() {
  return (
    <>
      <div className={styles.ReportModalBody__title}>Di cosa si tratta?</div>
      <div className={styles.ReportModalBody__text}>
        L’assicurazione malattia è la polizza assicurativa che ti fornisce copertura finanziaria per le spese mediche e sanitarie sostenute a seguito di
        malattie o infortuni.
      </div>
      {/* Protection */}
      <div className={styles.ReportModalBody__title}>Da cosa protegge?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>elevati costi dei trattamenti ospedalieri</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>cure mediche e visite specialistiche</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>farmaci</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>esami diagnostici</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>altre spese correlate alla salute</span>
        </div>
      </div>
      {/* Cover */}
      <div className={styles.ReportModalBody__title}>Che cosa copre?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>possibilità di ricevere cure mediche di qualità</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>assistenza sanitaria convenzionata</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>supporto finanziario</span>
        </div>
      </div>
    </>
  );
}
