import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './utils/PrivateRoute';
import routes from './routes';

import './App.scss';

/** Pages */
import { ForgotPasswordPage, LoginPage, RegisterPage } from './pages';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />

          {/* Private routes */}
          <Route element={<PrivateRoute />}>
            {routes.map((route, idx) => {
              return <Route key={idx} path={route.path} element={<route.component />} />;
            })}
          </Route>
          {/* {routes.map((route, idx) => {
            return <Route key={idx} path={route.path} element={<route.component />} />;
          })} */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
