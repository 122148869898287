import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';
import { verifyToken } from './Common';

type ContextType = { user: any | null };

/**
 * Private route component
 * Compatible with react-router-dom v6
 */
export function PrivateRoute({ roles, organization }: any) {
  const [auth, setAuth] = useState(false);
  const [goOn, setGoOn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    verifyToken()
      .then((res: any) => {
        setAuth(res.status);
        if (res.data) {
          setUser(res.data.user);
        }
      })
      .then(() => {
        setGoOn(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!goOn) {
    return <div>Loading...</div>;
  }

  let res = <Navigate to="/login" />;

  if (auth) {
    // @ts-ignore
    if ((roles && roles.includes(user.role)) || !roles) {
      res = <Outlet context={{ user }} />;
      // @ts-ignore
    } else if (organization && user && user.organization_id && user.organization_id.length > 0) {
      res = <Outlet context={{ user }} />;
    } else {
      res = <Navigate to="/" />;
    }
  }

  return res;
}

export function useUser() {
  return useOutletContext<ContextType>();
}
