import React from 'react';

import { Box } from '../../../Box';
import { classNames } from '../../../../utilities/css';

import styles from './Section.module.scss';

export interface SectionProps {
  children?: React.ReactNode;
  flush?: boolean;
  subdued?: boolean;
  titleHidden?: boolean;
}

export function Section({ children, flush = false, subdued = false, titleHidden = false }: SectionProps) {
  const className = classNames(styles.Section, titleHidden && styles.titleHidden);

  return (
    <div className={className}>
      <Box as="section" padding={flush ? '0' : '5'} {...(titleHidden && { paddingInlineEnd: '0' })} {...(subdued && { background: 'bg-subdued' })}>
        {children}
      </Box>
    </div>
  );
}
