import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../imb-react';
import styles from './ComingSoonPage.module.scss';
import { useCallback } from 'react';

export function ComingSoonPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleButtonClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const consultancyMarkup =
    location.pathname === '/consultancies' ? (
      <div className={styles.ComingSoonPage__cta}>
        <Button url={process.env.REACT_APP_IMBA_URL + '/consultancy'} primary size="large">
          Iscriviti alla lista di attesa
        </Button>
      </div>
    ) : null;

  return (
    <div className={styles.ComingSoonPage__container}>
      <div className={styles.ComingSoonPage__header}>
        <div className={styles.ComingSoonPage__header__logo}>
          <img src="/coming_soon.svg" alt="Coming Soon" />
        </div>
        <div className={styles.ComingSoonPage__header__text}>
          <h1 className={styles.ComingSoonPage__title}>Coming soon</h1>
          <p className={styles.ComingSoonPage__subtitle}>
            Stiamo lavorando veloci come razzi per rendere presto disponibile questa pagina. Nel frattempo goditi le aree attive ;)
          </p>
        </div>
      </div>
      {/* Consultancy */}
      {consultancyMarkup}
      {/* Home button */}
      <div className={styles.ComingSoonPage__cta}>
        <Button onClick={handleButtonClick} primary size="large">
          Torna alla home
        </Button>
      </div>
    </div>
  );
}
