import styles from '../../../Dashboard/components/Policies/Policies.module.scss';
import stylesGeneral from '../StartNewClaim/StartNewClaim.module.scss';
import { classNames } from '../../../../imb-react/utilities/css';
import { getPolicyExpirationDate } from '../../../../utils/Common';
import { useCallback } from 'react';
import { ClaimFile } from './StartNewClaim';

type PreviewProps = {
  selectedPolicy: any;
  claimDateField: { value: string };
  regionField: { value: string };
  cityField: { value: string };
  addressField: { value: string };
  descriptionField: { value: string };
  fileField: { value: ClaimFile[]; onChange: (files: ClaimFile[]) => void };
  imageReportField?: { value: ClaimFile | null; onChange: (file: ClaimFile | null) => void };
};

export function Preview({ selectedPolicy, claimDateField, regionField, cityField, addressField, descriptionField, fileField, imageReportField }: PreviewProps) {
  const renderStatus = (date: Date) => {
    const expirationDate = new Date(date);
    expirationDate.setDate(expirationDate.getDate() + 30);

    const today = new Date();
    if (expirationDate < today) {
      return { expiring: true, message: 'Scaduta' };
    } else {
      return { expiring: false, message: 'Attiva' };
    }
  };

  const cardWidth = 250;
  const totalCards = 1;

  const getContainerWidth = () => {
    return totalCards * cardWidth + 'px';
  };

  const getTransformValue = () => {
    const translateValue = -0 * cardWidth;
    return `translateX(${translateValue}px)`;
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const handleRemoveFile = useCallback(
    (index: number) => {
      if (Array.isArray(fileField.value)) {
        const updatedFiles = fileField.value.filter((_: any, i: number) => i !== index);
        fileField.onChange(updatedFiles);
      }
    },
    [fileField],
  );

  const handleRemoveImageReportFile = useCallback(() => {
    if (imageReportField) {
      imageReportField.onChange(null);
    }
  }, [imageReportField]);

  return (
    <>
      <div className={stylesGeneral.previewDetailsContainer}>
        <div className={stylesGeneral.previewCard}>
          <span className={stylesGeneral.helperText}>Polizza utilizzata:</span>
          <div className={styles.PoliciesContainer}>
            <div className={classNames(styles.PoliciesBody, stylesGeneral.PoliciesBody)} style={{ transform: getTransformValue(), width: getContainerWidth() }}>
              <div
                className={classNames(
                  styles.PoliciesBox,
                  selectedPolicy.date_created && renderStatus(new Date(selectedPolicy.date_created)).expiring ? styles.expiring : styles.active,
                )}
              >
                <div className={styles.PoliciesBox__data}>
                  <div>
                    <div className={styles.PoliciesBox__title}>{selectedPolicy.line_items[0].name}</div>
                    <div className={`${styles.PoliciesBox__text} ${styles.PoliciesBox__status}`}>
                      {selectedPolicy.date_created && renderStatus(new Date(selectedPolicy.date_created)).message}
                    </div>
                    <div className={styles.PoliciesBox__text}>
                      scade il
                      <div className={styles.PoliciesBox__expiration}>{getPolicyExpirationDate(new Date(selectedPolicy.date_created))}</div>
                    </div>
                  </div>
                  <div className={styles.PoliciesBox__icon}>
                    <img
                      src={selectedPolicy.line_items[0].product.icon && process.env.REACT_APP_BLOB_IMAGES_URL + selectedPolicy.line_items[0].product.icon.key}
                      alt={selectedPolicy.line_items[0].product.icon && selectedPolicy.line_items[0].product.icon.title}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={stylesGeneral.previewCard}>
          <span className={stylesGeneral.helperText}>Dettagli sinistro:</span>
          <div className={stylesGeneral.boxData}>
            <div className={stylesGeneral.dFlex}>
              <span className={stylesGeneral.helperText}>Quando:</span> {formatDate(new Date(claimDateField.value))}
            </div>
            <div className={stylesGeneral.dFlex}>
              <span className={stylesGeneral.helperText}>Dove:</span>
              <ul>
                <li>{regionField.value}</li> <li>{cityField.value}</li> <li>{addressField.value}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={stylesGeneral.previewCard}>
          <span className={stylesGeneral.helperText}>Descrizione dell'incidente:</span>
          <div className={stylesGeneral.boxData}>{descriptionField.value}</div>
        </div>
      </div>
      <div className={stylesGeneral.uploadedFiles}>
        <span className={stylesGeneral.helperText}>I tuoi caricamenti</span>
        {/* Conditional rendering based on file and imageReportField */}
        <div className={stylesGeneral.uploadedFilesContainer}>
          {fileField.value.length > 0 || (imageReportField && imageReportField.value) ? (
            <ul>
              {fileField.value.map((file: ClaimFile, index: number) => (
                <li key={index} className={stylesGeneral.uploadedSingleFile}>
                  <span>{file.title}</span> {/* Usa file.title per visualizzare il nome del file */}
                  <div onClick={() => handleRemoveFile(index)}>
                    <img src="/icon_x.svg" />
                  </div>
                </li>
              ))}
              {imageReportField && imageReportField.value && (
                <li className={stylesGeneral.uploadedSingleFile}>
                  <span>{imageReportField.value.title}</span>
                  <div onClick={() => handleRemoveImageReportFile()}>
                    <img src="/icon_x.svg" />
                  </div>
                </li>
              )}
            </ul>
          ) : (
            <div className={stylesGeneral.noFilesText}>Nessun file caricato</div>
          )}
        </div>
      </div>
    </>
  );
}
