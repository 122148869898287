import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Banner, Frame, Layout, Loading, Page, Link } from '../../imb-react';
import { TopBarMarkup, NavigationMarkup } from '../../components';
import { Policies, ReportModal } from './components';
import { Quotes } from './components/Quotes';
import { Report } from './components/Report';
import { ReccomendPolicies } from './components/ReccomendPolicies';
import { Claims } from './components/Claims';

import styles from './Dashboard.module.scss';
import { ProductsReportType } from './components/Report/productsReport';
//import { ProductType } from './components/Report/produtcs';
import { useUser } from '../../utils/PrivateRoute';

export function Dashboard() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);
  const [printError, setPrintError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Report modal
   */
  const [reportModalActive, setReportModalActive] = useState(false);
  // The product that should be displayed
  const [reportItem, setReportItem] = useState<ProductsReportType>({} as ProductsReportType);

  const handleReportModalChange = useCallback(() => setReportModalActive((reportModalActive) => !reportModalActive), []);
  const handleOpenReportModal = useCallback((item: ProductsReportType) => {
    setReportModalActive((reportModalActive) => !reportModalActive);
    setReportItem(item);
  }, []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Analytics
   */
  const [totalPrice, setTotalPrice] = useState(-1);
  const [provvTot, setProvvTot] = useState(-1);
  const [customers, setCustomers] = useState(-1);
  const [deadlines, setDeadlines] = useState([]);
  const [frontItems, setFrontItems] = useState([]);

  // Loading
  useEffect(() => {
    if (customers !== -1) setIsLoading(false);
  }, [customers]);

  // Pass function to DashboardDatePicker
  const handleAnalytics = (data: any) => {
    setTotalPrice(data.totalPrice);
    setCustomers(data.customers);
    setDeadlines(data.deadlines);
    setFrontItems(data.deadlines);
  };

  // Pass function to ScadenzeList
  const handleFrontItems = (data: any) => {
    setFrontItems(data);
  };

  const handleFrontItemsSort = (order: number) => {
    const tmp = [...frontItems];
    // @ts-ignore
    tmp.sort((a, b) => (a.date_scadenza > b.date_scadenza ? order : -order));
    setFrontItems(tmp);
  };

  /**
   * Banner markup
   */
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  const printBannerMarkup = printError && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nella stampa del report" status="critical" onDismiss={() => setPrintError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  /**
   * Page markup
   */
  const pageMarkup = (
    <Page fullWidth>
      {/* Banners */}
      {bannerMarkup}
      {printBannerMarkup}

      {/* Header */}
      <div className={styles.Header}>
        {/* title */}
        <div className={styles.Header__title}>Il tuo portafoglio assicurativo</div>
        {/* search input */}
      </div>

      {/* Main */}
      <div className={styles.DashboardMain}>
        {/* Policies */}
        <div className={`${styles.DashboardCardsSections} ${styles.DashboardBigCard}`}>
          <div className={styles.DashboardCardContainer}>
            <div>
              <h5 className={styles.DashboardCardTitle}>Le tue polizze:</h5>
            </div>
            <Policies purpose="view" />
            <div className={styles.DashboardUnderlineBtn}>
              <div>
                <Link url="/products/policies" imbDarkLow>
                  Vedi storico polizze
                </Link>
              </div>
              <div className={styles.DashboardIconBtn}>
                <img src="/icon_arrow_right.svg" alt="icon arrow policies" />
              </div>
            </div>
          </div>
        </div>
        {/* Recommend Policies/Report */}
        <div className={`${styles.DashboardCardsSections} ${styles.DashboardMediumCard} ${styles.DashboardRecommendCard}`}>
          <div>
            <h5 className={styles.DashboardCardTitle}>Polizze consigliate:</h5>
          </div>
          <div className={styles.DashboardReccomendPolicies}>
            <Report handleOpenReportModal={handleReportModalChange} />
          </div>
        </div>
      </div>

      <div className={styles.DashboardMain}>
        {/* Claims */}
        <div className={`${styles.DashboardCardsSections} ${styles.DashboardMediumCard} ${styles.DashboardClaimsCard}`}>
          <div className={styles.DashboardCardContainer}>
            <div>
              <h5 className={styles.DashboardCardTitle}>I tuoi sinistri:</h5>
            </div>
            <Claims />
            <div className={styles.DashboardUnderlineBtn}>
              <div>
                <Link url="/claims/claimActive" imbDarkLow>
                  Visualizza scheda sinistri completa
                </Link>
              </div>
              <div className={styles.DashboardIconBtn}>
                <img src="/icon_arrow_right.svg" alt="icon arrow policies" />
              </div>
            </div>
          </div>
        </div>
        {/* Quotes */}
        <div className={`${styles.DashboardCardsSections} ${styles.DashboardBigCard}`}>
          <div className={styles.DashboardCardContainer}>
            <div>
              <h5 className={styles.DashboardCardTitle}>Preventivi polizze:</h5>
            </div>
            <Quotes />
            <div className={styles.DashboardUnderlineBtn}>
              <div>
                <Link url="/products/quotes" imbDarkLow>
                  Vedi preventivi
                </Link>
              </div>
              <div className={styles.DashboardIconBtn}>
                <img src="/icon_arrow_right.svg" alt="icon arrow policies" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );

  return (
    <div className="AppContainer">
      <Frame
        topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
        navigation={<NavigationMarkup user={user} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
        offset="2rem"
      >
        {/* {loadingMarkup} */}
        {pageMarkup}
        {<ReportModal active={reportModalActive} onClose={handleReportModalChange} item={reportItem} />}
      </Frame>
    </div>
  );
}
