import { useCallback } from 'react';
import { FormLayout, DropZone, VerticalStack } from '../../../../imb-react';
import styles from '../StartNewClaim/StartNewClaim.module.scss';
import { classNames } from '../../../../imb-react/utilities/css';
import { ClaimFile } from './StartNewClaim';

type ClaimMediaUploadProps = {
  fileField: {
    value: ClaimFile[];
    onChange: (files: ClaimFile[]) => void;
  };
};

export function ClaimMediaUpload({ fileField }: ClaimMediaUploadProps) {
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const updatedFiles: ClaimFile[] = acceptedFiles.map((file) => ({
        key: file.name,
        title: file.name,
        file_type: 'image',
        file: file,
      }));
      fileField.onChange([...fileField.value, ...updatedFiles]);
    },
    [fileField],
  );

  const handleRemoveFile = useCallback(
    (indexToRemove: number) => {
      const updatedFiles = fileField.value.filter((_, index) => index !== indexToRemove);
      fileField.onChange(updatedFiles);
    },
    [fileField],
  );

  return (
    <div className={classNames(styles.Claims_form_maxWidth)}>
      <FormLayout.Group>
        <VerticalStack>
          <DropZone label="Carica qua i documenti" onDrop={handleDrop} accept="image/*">
            <DropZone.FileUpload />
          </DropZone>
          {fileField.value && fileField.value.length > 0 && (
            <div>
              <ul className={styles.fileContainer}>
                {fileField.value.map((file: ClaimFile, index: number) => (
                  <li className={styles.singleFile} key={file.key} style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{file.title}</span>
                    <div onClick={() => handleRemoveFile(index)}>
                      <img src="/icon_x.svg" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </VerticalStack>
      </FormLayout.Group>
    </div>
  );
}
