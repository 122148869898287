import styles from './../ReportModal.module.scss';

export function PolicyTheft() {
  return (
    <>
      <div className={styles.ReportModalBody__title}>Di cosa si tratta?</div>
      <div className={styles.ReportModalBody__text}>
        L’assistenza furto è la <strong>polizza assicurativa finanziaria</strong> che ti protegge dagli eventi di furto o furto qualificato di beni personali o
        proprietà.
      </div>
      {/* Protection */}
      <div className={styles.ReportModalBody__title}>Da cosa protegge?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>furto di beni nell'abitazione</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>furto di auto o motocicli</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>furto di oggetti personali durante un viaggio</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>varie altre tipologie di furto</span>
        </div>
      </div>
      {/* Cover */}
      <div className={styles.ReportModalBody__title}>Che cosa copre?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>risarcimento valore del bene rubato</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>risarcimento valore del bene danneggiato</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>furto di oggetti personali durante un viaggio</span>
        </div>
      </div>
    </>
  );
}
