import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Banner, ChoiceList, ContextualSaveBar, FormLayout, Icon, TextField, Toast } from '../../../../imb-react';
import styles from '../../Account.module.scss';
import { asChoiceList, useField, useForm } from '@shopify/react-form';
import axios, { AxiosError } from 'axios';
import { generateFiscalCode } from '../../../../utils/fiscalcode';
import { SearchMinor } from '@shopify/polaris-icons';
import React from 'react';
import { refreshToken } from '../../../../utils/Common';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.tz.setDefault('Europe/Rome');

export function PersonalData() {
  const [success, setSuccess] = useState(false);
  const [errorBannerVisible, setErrorBannerVisible] = useState(false);

  /**
   * Gender
   */
  enum Gender {
    Male = 'male',
    Female = 'female',
  }
  const genderChoices = [
    { label: 'Donna', value: Gender.Female },
    { label: 'Uomo', value: Gender.Male },
  ];

  /**
   * Birth city
   */
  const [birthCity, setBirthCity] = useState<{ label: string; value: string }>();
  const deselectedOptions = useMemo(() => [], []);
  const [birthCityOptions, setBirthCityOptions] = useState<{ label: string; value: string }[]>([]);
  const [birthCitySelectedOptions, setBirthCitySelectedOptions] = useState<string[]>([]);
  const [birthCityInputValue, setBirthCityInputValue] = useState('');
  const [birthCityLoading, setBirthCityLoading] = useState(false);

  const updateBirthCityText = useCallback(
    (value: string) => {
      setBirthCityInputValue(value);

      if (!birthCityLoading) {
        setBirthCityLoading(true);
      }

      if (value === '') {
        setBirthCityOptions(deselectedOptions);
        setBirthCityLoading(false);
        return;
      }

      // When the user has typed at least 3 characters, request
      // the list from the server
      if (value.length < 3) {
        setBirthCityLoading(false);
        return;
      } else {
        // Fetch birth city options
        setBirthCityLoading(true);
        axios
          .get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/user/cadastral_codes/search/${value}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          })
          .then((response) => {
            const data = response.data;

            if (data.status === 'success') {
              setBirthCityOptions(data.data);
            }
          })
          .catch((error) => {
            const axiosError = error as AxiosError;
            console.log(axiosError);
          })
          .finally(() => {
            setBirthCityLoading(false);
          });
      }
    },
    [birthCityOptions, birthCityLoading, deselectedOptions],
  );

  const updateBirthCitySelection = useCallback(
    (selected: string[]) => {
      const selectedText = selected.map((selectedItem) => {
        const matchedOption = birthCityOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });
      setBirthCitySelectedOptions(selected);
      setBirthCity({
        label: selectedText[0] || '',
        value: selected[0] || '',
      });
      setBirthCityInputValue(selectedText[0] || '');
    },
    [birthCityOptions],
  );

  /**
   * Use form
   */
  const {
    fields: { name, lastname, birth_date, gender, fiscal_code, email, phone, line, city, zip, province },
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: {
      name: useField(''),
      lastname: useField(''),
      birth_date: useField(''),
      fiscal_code: useField(''),
      email: useField(''),
      phone: useField(''),
      line: useField(''),
      city: useField(''),
      zip: useField(''),
      province: useField(''),
      gender: useField(''),
    },
    onSubmit: async (fieldValues) => {
      try {
        setErrorBannerVisible(false);

        console.log('Data di nascita', fieldValues.birth_date);
        console.log('Sesso', fieldValues.gender);

        const response = await axios.put(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/account',
          {
            name: fieldValues.name,
            lastname: fieldValues.lastname,
            email: fieldValues.email,
            phone: fieldValues.phone,
            birth_date: fieldValues.birth_date,
            birth_city: birthCity,
            fiscal_code: fieldValues.fiscal_code,
            address: {
              line: fieldValues.line,
              city: fieldValues.city,
              zip: fieldValues.zip,
              province: fieldValues.province,
            },
            gender: fieldValues.gender,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        const data = response.data;

        if (data.status === 'success') {
          // Show success toast
          setSuccess(true);

          // Refresh token
          await refreshToken();

          // Wait two seconds and reload the page
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          return { status: 'success' };
        } else {
          setErrorBannerVisible(true);
          return {
            status: 'fail',
            errors: [
              {
                message: "Errore durante l'invio della richiesta",
              },
            ],
          };
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;

        setErrorBannerVisible(true);
        return {
          status: 'fail',
          errors: [
            {
              message: "Errore durante l'invio della richiesta",
            },
          ],
        };
      }
    },
  });

  function handleDiscard(): void {
    alert('Function not implemented.');
  }

  /**
   * Fetch user data
   */
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/account', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          // Set user data
          name.newDefaultValue(data.data.name);
          lastname.newDefaultValue(data.data.lastname);

          const { customer } = data.data;

          // Birth date - format YYYY-MM-DD
          if (customer && customer.birth_date && dayjs(customer.birth_date).isValid()) {
            const birthDate = new Date(customer && customer.birth_date ? data.data.customer.birth_date : '');
            const birthDateFormatted = `${birthDate.getFullYear()}-${(birthDate.getMonth() + 1).toString().padStart(2, '0')}-${birthDate
              .getDate()
              .toString()
              .padStart(2, '0')}`;
            birth_date.newDefaultValue(birthDateFormatted);
          }

          setBirthCity(customer && customer.birth_city ? data.data.customer.birth_city : '');
          // Autocomplete
          if (customer && customer.birth_city) {
            setBirthCitySelectedOptions([data.data.customer.birth_city.value]);
            setBirthCityInputValue(data.data.customer.birth_city.label);
          }

          gender.newDefaultValue(customer && customer.gender ? customer.gender : '');
          fiscal_code.newDefaultValue(customer && customer.fiscal_code ? customer.fiscal_code : '');
          email.newDefaultValue(data.data.email);
          phone.newDefaultValue(data.data.phone);
          line.newDefaultValue(customer && customer.address ? customer.address.line : '');
          city.newDefaultValue(customer && customer.address ? customer.address.city : '');
          zip.newDefaultValue(customer && customer.address ? customer.address.zip : '');
          province.newDefaultValue(customer && customer.address ? customer.address.province : '');
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      }
    };
    fetchUserData();
  }, []);

  /**
   * Handle generate fiscal code
   */
  const handleGenerateFC = useCallback(() => {
    // Check if all fields are filled
    let isFilled = true;
    if (name.value === '') isFilled = false;
    if (lastname.value === '') isFilled = false;
    if (birth_date.value === '') isFilled = false;
    if (!birthCity?.value) isFilled = false;
    if (gender.value === '') isFilled = false;

    if (isFilled && birthCity?.value) {
      // Generate fiscal code italy
      const computedFiscalCode = generateFiscalCode(name.value, lastname.value, new Date(birth_date.value), birthCity.value, gender.value);
      fiscal_code.newDefaultValue(computedFiscalCode);
    } else {
      alert('Compila tutti i campi per generare il codice fiscale');
      // TODO: show error
    }
  }, [name, lastname, birth_date, birthCity, gender]);

  /**
   * Render account label
   * @param text string
   * @returns JSX.Element
   */
  const renderAccountLabel = (text: string) => {
    return <span className={styles.AccountFieldLabel}>{text}</span>;
  };

  /**
   * Birth city autocomplete
   */
  const birthCityTextField = (
    <Autocomplete.TextField
      label="Luogo di nascita"
      autoComplete="off"
      value={birthCityInputValue}
      prefix={<Icon source={SearchMinor} color="base" />}
      placeholder="Cerca"
      onChange={updateBirthCityText}
      isAccountField
    />
  );

  const birthCityEmptyState = (
    <React.Fragment>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: 'center' }}>Non sono presenti risultati</div>
    </React.Fragment>
  );

  /**
   * Save bar
   */
  const contextualSaveBarMarkup = dirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: submit,
        loading: submitting,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
    />
  ) : null;

  /**
   * Toast markup
   */
  const toggleToastActive = useCallback(() => setSuccess((success) => !success), []);
  const toastMarkup = success ? <Toast textColor="white" content="I dati sono stati aggiornati con successo." onDismiss={toggleToastActive} /> : null;

  /**
   * Banner markup
   */
  const bannerMarkup = errorBannerVisible && (
    <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setErrorBannerVisible(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </div>
  );

  return (
    <div className={styles.PersonalData}>
      {contextualSaveBarMarkup}

      {/* Banners */}
      {bannerMarkup}

      {/* Personal data */}
      <div className={styles.PersonalDataCard}>
        <div className={styles.PersonalDataCardHeader}>Dati personali</div>
        <div className={styles.PersonalDataCardBody}>
          <FormLayout>
            <FormLayout.Group>
              <TextField label={renderAccountLabel('Nome')} autoComplete="off" isAccountField {...name} />
              <TextField label={renderAccountLabel('Cognome')} autoComplete="off" isAccountField {...lastname} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField label={renderAccountLabel('Data di nascita')} type="date" autoComplete="off" isAccountField {...birth_date} />
              {/* <TextField label={renderAccountLabel('Luogo di nascita')} autoComplete="off" isAccountField {...birth_city} /> */}
              <Autocomplete
                options={birthCityOptions}
                selected={birthCitySelectedOptions}
                onSelect={updateBirthCitySelection}
                emptyState={birthCityEmptyState}
                loading={birthCityLoading}
                textField={birthCityTextField}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              {/* <ChoiceList title="Genere" choices={genderChoices} selected={gender} onChange={handleGenderChange} inline /> */}
              <ChoiceList title="Genere" choices={genderChoices} inline {...asChoiceList(gender)} />
              <TextField
                label={renderAccountLabel('Codice Fiscale')}
                labelAction={{ content: 'Genera', onAction: handleGenerateFC }}
                autoComplete="off"
                isAccountField
                {...fiscal_code}
              />
            </FormLayout.Group>
          </FormLayout>
        </div>
      </div>

      {/* Contact data */}
      <div className={styles.PersonalDataCard}>
        <div className={styles.PersonalDataCardHeader}>Dati di contatto</div>
        <div className={styles.PersonalDataCardBody}>
          <FormLayout>
            <FormLayout.Group>
              <TextField label={renderAccountLabel('Email')} autoComplete="off" isAccountField {...email} />
              <TextField label={renderAccountLabel('Cellulare')} autoComplete="off" isAccountField {...phone} />
            </FormLayout.Group>
          </FormLayout>
        </div>
      </div>

      {/* Address data */}
      <div className={styles.PersonalDataCard}>
        <div className={styles.PersonalDataCardHeader}>Residenza</div>
        <div className={styles.PersonalDataCardBody}>
          <FormLayout>
            <FormLayout.Group>
              <TextField label={renderAccountLabel('Città')} autoComplete="off" isAccountField {...city} />
              <TextField label={renderAccountLabel('CAP')} autoComplete="off" isAccountField {...zip} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField label={renderAccountLabel('Provincia')} autoComplete="off" isAccountField {...province} />
              <TextField label={renderAccountLabel('Indirizzo')} autoComplete="off" isAccountField {...line} />
            </FormLayout.Group>
          </FormLayout>
        </div>
      </div>

      {/* Toast */}
      {toastMarkup}
    </div>
  );
}
