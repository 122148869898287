import { useState } from 'react';
import { DatePicker, FormLayout } from '../../../../imb-react';
import styles from '../StartNewClaim/StartNewClaim.module.scss';

type ClaimDateProps = {
  claimDateField: any;
};

export function ClaimDate({ claimDateField }: ClaimDateProps) {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const handleDateChange = (date: any) => {
    claimDateField.onChange(date.start);
  };

  const handleMonthChange = (newMonth: any, newYear: any) => {
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  return (
    <FormLayout.Group>
      <div className={styles.DatePickerContainer}>
        <DatePicker
          month={currentMonth}
          year={currentYear}
          disableDatesAfter={today}
          allowRange={false}
          selected={claimDateField.value}
          onChange={handleDateChange}
          onMonthChange={handleMonthChange}
        />
      </div>
    </FormLayout.Group>
  );
}
