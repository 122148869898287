import { Account, Consultancies, Dashboard, NewsAll, Products, QuoteAll, RewardAll, SupportAll, Claims } from './pages';
import { Starter, StartNewClaim } from './pages/Claims/components';
import { ClaimActive } from './pages/Claims/components/ClaimActive';
import { ClaimHistory } from './pages/Claims/components/ClaimHistory';

const routes = [
  { path: '/', name: 'Dashboard', component: Dashboard },
  // { path: '/disabled', name: 'Account disattivato', component: Disabled, exact: true },
  // { path: '/logout', name: 'Logout', component: Logout, exact: true },
  // { path: '/analytics', name: 'Anaytics', component: Analytics, exact: true },
  /**
   * Account
   */
  { path: '/account', name: 'Account', component: Account },
  { path: '/account/:id', name: 'Account', component: Account },
  // { path: '/customers/new', name: 'Nuovo cliente', component: CustomerNew, exact: true },
  // { path: '/customers/:id', name: 'Dettagli cliente', component: CustomerDetails, exact: true },
  /**
   * Policies & Quotes
   */
  { path: '/products', name: 'Polizze', component: Products },
  { path: '/products/:id', name: 'Polizze', component: Products },
  // { path: '/products/new', name: 'Nuova Polizza', component: ProductNew, exact: true },
  // { path: '/products/categories', name: 'Categorie', component: CategoryAll, exact: true },
  // { path: '/products/:id', name: 'Dettagli polizza', component: ProductDetails, exact: true },
  /** Products Variants */
  // { path: '/products/:id/variants/new', name: 'Nuova variante', component: VariantNew, exact: true },
  // { path: '/products/:id/variants/:variantId', name: 'Dettagli variante', component: VariantDetails, exact: true },
  /** Orders */
  // { path: '/orders', name: 'Polizze', component: OrderAll, exact: true },
  // { path: '/orders/new', name: 'Nuova Polizza', component: OrderNew, exact: true },
  // { path: '/orders/:id', name: 'Dettagli polizza', component: OrderDetails, exact: true },
  /**
   * Quotes
   */
  { path: '/quotes', name: 'Preventivi', component: QuoteAll },
  // { path: '/quotes/new', name: 'Nuova Polizza', component: QuoteNew, exact: true },
  // { path: '/quotes/:id', name: 'Dettagli polizza', component: QuoteDetails, exact: true },
  /**
   * Consultancies
   */
  { path: '/consultancies', name: 'Consulenze', component: Consultancies },
  /**
   * Rewards
   */
  { path: '/rewards', name: 'Rewards', component: RewardAll },
  /**
   * News
   */
  { path: '/news', name: 'News', component: NewsAll },
  /**
   * Support
   */
  { path: '/support', name: 'Supporto', component: SupportAll },

  /** Claims */
  { path: '/claims', name: 'Sinistri', component: Claims },
  { path: '/StartNewClaim', name: 'Nuovo sinistro', component: StartNewClaim },
  { path: '/claims/:id', name: 'Sinistri', component: Claims },
  // { path: '/claims/:id', name: 'Dettagli sinistro', component: ClaimDetails, exact: true },
  /** Alien */
  // { path: '/alien', name: 'Alien', component: Alien, exact: true },
  // /** Settings */
  // { path: '/settings', name: 'Impostazioni', component: Settings, exact: true },
  // // Account
  // { path: '/settings/account', name: 'Account', component: Account, exact: true },
  // // Payments
  // { path: '/settings/payments', name: 'Pagamento', roles: ['partner'], component: Payments, exact: true },
  // // Files
  // { path: '/settings/templates', name: 'Files', roles: ['partner'], component: Templates, exact: true },
  // /** Organization */
  // { path: '/organization', name: 'Organizzazione', organization: true, component: OrganizationAnalytics, exact: true },
  // /** Admin */
  // // Users
  // { path: '/admin/users', name: 'Utenti', roles: ['admin'], component: UserAll, exact: true },
  // { path: '/admin/users/new', name: 'Nuovo utente', roles: ['admin'], component: UserNew, exact: true },
  // { path: '/admin/users/:id', name: 'Utente', roles: ['admin'], component: UserDetails, exact: true },
  // // Organizations
  // { path: '/admin/organizations', name: 'Organizzazioni', roles: ['admin'], component: OrganizationAll, exact: true },
  // { path: '/admin/organizations/new', name: 'Nuova organizzazione', roles: ['admin'], component: OrganizationNew, exact: true },
  // { path: '/admin/organizations/:id', name: 'Dettagli Organizzazione', roles: ['admin'], component: OrganizationDetails, exact: true },
  // { path: '/admin/organizations/:id/products', name: 'Prodotti organizzazione', roles: ['admin'], component: OrganizationProducts, exact: true },
  // Reports
  // { path: '/admin/reports', name: 'Reports', roles: ['admin'], component: Reports, exact: true },
];

export default routes;
