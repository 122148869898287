import React, { useCallback, useState } from 'react';

import { Button, Form, FormLayout, TextField, InlineError, Link, Frame, Toast } from '../../imb-react';

import './../LoginPage/Login.css';
import logo from '../../logo_bw.svg';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

export function ForgotPasswordPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  // Used for generic error
  const [error, setError] = useState(false);
  // Used for user not found error
  const [userError, setUserError] = useState(false);
  // Used for toast
  const [success, setSuccess] = useState(false);
  // Used for error on fields
  const [emptyFields, setEmptyFields] = useState({
    email: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(false);

      // Check if fields are empty and set the corresponging emptyFields state
      if (email === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, email: true }));
        return;
      } else {
        setEmptyFields((emptyFields) => ({ ...emptyFields, email: false }));
      }

      const req = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/reset-password',
        { email },
        {
          timeout: 5000,
        },
      );
      const response = req.data;

      if (response.status === 'success') {
        setSuccess(true);

        // Redirect to login page after 5 seconds
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;
      if (status === 404) {
        setUserError(true);
      } else {
        setError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [email, history, emptyFields]);

  /** Email handler */
  const handleEmailChange = useCallback(
    (value: any) => {
      // If the field is not empty, remove the error
      if (value !== '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, email: false }));
      }
      setEmail(value);
    },
    [emptyFields],
  );

  /**
   * Toast markup
   */
  const toggleToastActive = useCallback(() => setSuccess((success) => !success), []);
  const toastMarkup = success ? (
    <Toast
      textColor="white"
      content="Ti è stata inviata un'email contentente le credenziali. A breve sarai reindirizzato alla pagina di login."
      onDismiss={toggleToastActive}
    />
  ) : null;

  /**
   * Errors markup
   */
  const userErrorMarkup = userError && (
    <InlineError message="Non è stato trovato nessun utente associato a questo indirizzo. Controlla i dati." fieldID="myFieldID" />
  );

  const errorMarkup = error && <InlineError message="Qualcosa è andato storto. Riprovare più tardi." fieldID="myFieldID" />;

  return (
    <Frame offset="0">
      <div className="page-main">
        <div className="page-content">
          <div className="login-card">
            <header className="login-card__header">
              <h1 className="login-card__logo forgot">
                <a href="/">
                  <img src={logo} />
                </a>
              </h1>
            </header>

            <div className="login-card__content forgot">
              <div className="main-card-section">
                <h2 className="ui-heading forgot">Ristabilisci l'accesso</h2>
                <h3 className="ui-subheading ui-subheading--subdued forgot">Ti aiutiamo noi a recuperare la password, non preoccuparti</h3>
                <div style={{ margin: '4rem auto 0 auto', maxWidth: '600px' }}>
                  <Form onSubmit={handleSubmit}>
                    <FormLayout>
                      <TextField
                        autoComplete="on"
                        value={email}
                        onChange={handleEmailChange}
                        label="Email"
                        type="email"
                        placeholder="Scrivi il tuo indirizzo email..."
                        error={emptyFields.email && 'Il campo è obbligatorio'}
                      />
                      <h3 className="ui-subheading ui-subheading--subdued">
                        A breve riceverai un’email con il link per il recupero password. In poche mosse potrai rientrare sul tuo profilo. Ti aspettiamo!
                      </h3>
                      {/* Errors markup */}
                      {userErrorMarkup}
                      {errorMarkup}
                      <div className="login-card__button forgot">
                        <Button primary loading={isLoading} size="large" submit>
                          Invia
                        </Button>
                      </div>
                    </FormLayout>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <footer className="login-footer">
            <a className="login-footer__link" target="_blank" href="#" title="Centro di assistenza">
              Assistenza
            </a>
            <a className="login-footer__link" target="_blank" href="#" title="Privacy Policy">
              Privacy
            </a>
            <a className="login-footer__link" target="_blank" href="#" title="Termini di servizio">
              Termini
            </a>
          </footer>
        </div>
      </div>

      {/* Toast markup */}
      {toastMarkup}
    </Frame>
  );
}
