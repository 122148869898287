import { ChevronRightMinor, ChevronLeftMinor } from '@shopify/polaris-icons';
import { Button, Link } from '../../../../imb-react';
import styles from './Claims.module.scss';

import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { classNames } from '../../../../imb-react/utilities/css';
import { Order } from '../../../../types';

export function Claims() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [claims, setClaims] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [transformValue, setTransformValue] = useState<number>(0);

  /**
   * Fetch user personal claims
   * TODO: API of claims
   */
  // useEffect(() => {
  //   const fetchUserPersonalClaims = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/claims', {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
  //         },
  //       });
  //       const data = response.data;

  //       if (data.status === 'success') {
  //         setClaims(data.data);
  //       }
  //     } catch (error) {
  //       const axiosError = error as AxiosError;
  //       console.log(axiosError);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchUserPersonalClaims();
  // }, []);

  /**
   * Empty markup
   */
  const emptyMarkup = (
    <div className={styles.ClaimsBody}>
      <div className={styles.ClaimsEmpty__box}>
        <Button url="/StartNewClaim">Avvia sinistro</Button>
      </div>
    </div>
  );

  /**
   * Render status text
   * If the policy date_end is less than 30 days, show the text "In scadenza"
   * @param {Date} date
   */
  const renderStatus = (date: Date) => {
    const expirationDate = new Date(date);
    expirationDate.setDate(expirationDate.getDate() + 30);

    const today = new Date();
    if (expirationDate < today) {
      return { expiring: true, message: 'In scadenza' };
    } else {
      return { expiring: false, message: 'Attiva' };
    }
  };

  const handleNext = () => {
    if (currentIndex < claims.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setTransformValue(transformValue - 220);
      console.log('Current Index (Next):', currentIndex);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setTransformValue(transformValue + 220);
      console.log('Current Index (prev):', currentIndex);
    }
  };

  /**
   * Claims markup
   */
  const claimsMarkup = (
    <div className={styles.ClaimsBody}>
      <img onClick={handlePrev} src="/icon_arrow_slider_left.svg" alt="" />
      <div className={styles.ClaimsSlider} style={{ transform: `translateX(${transformValue}px)` }}>
        {claims.map((claim: Order, index: number) => (
          <div key={index} className={`${styles.ClaimsBox} ${index !== currentIndex ? styles.Hidden : ''}`}>
            <div className={styles.ClaimsBox__icon}>
              <img src="/icon_car.svg" alt="" />
            </div>
            <div className={styles.ClaimsBox__title}>Sinistro 1</div>
            <div className={`${styles.ClaimsBox__status} ${styles.expiring}`}>
              <img src="/icon_claim_exp.svg" alt="" />
              scaduto
            </div>
          </div>
        ))}
      </div>
      <img onClick={handleNext} src="/icon_arrow_slider_right.svg" alt="" />
    </div>
  );

  return <div>{claims.length > 0 ? claimsMarkup : emptyMarkup}</div>;
}
