import { TextField, FormLayout } from '../../../../imb-react';
import styles from '../StartNewClaim/StartNewClaim.module.scss';

type ClaimLocationProps = {
  regionField: any;
  cityField: any;
  addressField: any;
};

export function ClaimLocation({ regionField, cityField, addressField }: ClaimLocationProps) {
  return (
    <div className={`${styles.Claims_form_maxWidth} ${styles.Claims_form_margin}`}>
      <FormLayout.Group>
        <TextField label="Regione" autoComplete="off" placeholder="scrivi la regione dell'incidente" {...regionField} isAccountField />
        <TextField label="Città" type="text" autoComplete="off" placeholder="scrivi la città dell'incidente" {...cityField} isAccountField />
        <TextField label="Indirizzo" type="text" autoComplete="off" placeholder="scrivi l'indirizzo dell'incidente" {...addressField} isAccountField />
      </FormLayout.Group>
    </div>
  );
}
