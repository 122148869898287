import { useCallback, useRef, useState } from 'react';
import { ComingSoonPage, NavigationMarkup, TopBarMarkup } from '../../components';
import { Frame } from '../../imb-react';
import { useUser } from '../../utils/PrivateRoute';

export function RewardAll() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  return (
    <div className="AppContainer">
      <Frame
        topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} isComingSoonPage />}
        navigation={<NavigationMarkup user={user} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
        offset="2rem"
        isComingSoonPage
      >
        <ComingSoonPage />
      </Frame>
    </div>
  );
}
