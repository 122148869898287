import { useEffect, useState } from 'react';
import { Badge, Banner, EmptySearchResult, IndexTable, Link, Text, useIndexResourceState } from '../../../../imb-react';
import styles from '../../Products.module.scss';
import axios, { AxiosError } from 'axios';
import { Order } from '../../../../types';
import { getQuoteExpirationDate } from '../../../../utils/Common';

type QuotesType = {
  /** Handler for selecting the single quote */
  onSelect: (quote: Order) => void;
};

export function Quotes({ onSelect }: QuotesType) {
  const [errorBannerVisible, setErrorBannerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [quotes, setQuotes] = useState([]);
  const resourceName = {
    singular: 'preventivo',
    plural: 'preventivi',
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(quotes);

  /**
   * Fetch user quotes
   */
  useEffect(() => {
    const fetchUserQuotes = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/quotes', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          console.log(data);
          // TODO: improve setting quotes in the array
          setQuotes(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserQuotes();
  }, []);

  /**
   * Index table empty state markup
   */
  const emptyStateMarkup = <EmptySearchResult title={'Nessun preventivo'} description={'Aggiungi un preventivo'} withIllustration />;

  /**
   * Index table row markup
   */
  const rowMarkup = quotes.map((order: Order, index) => (
    <IndexTable.Row id={order._id} key={order._id} selected={selectedResources.includes(order._id)} position={index}>
      <IndexTable.Cell>
        <img
          className={styles.Products_icon}
          src={order.line_items[0].product.icon && process.env.REACT_APP_BLOB_IMAGES_URL + order.line_items[0].product.icon.key}
          alt={order.line_items[0].product.icon && order.line_items[0].product.icon.title}
        />
        <Link dataPrimaryLink onClick={() => onSelect(order)} removeUnderline imbDarkUp>
          <Text fontWeight="bold" as="span">
            {order.line_items[0].name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Badge imb>{order.line_items[0].product.category.name}</Badge>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Badge>{getQuoteExpirationDate(new Date(order.date_created))}</Badge>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="span" numeric>
          {order.total_price.toFixed(2)} €
        </Text>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  /**
   * Banner markup
   */
  const bannerMarkup = errorBannerVisible && (
    <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setErrorBannerVisible(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </div>
  );

  return (
    <div className={styles.ProductsData}>
      {/* Banners */}
      {bannerMarkup}

      {/* Personal data */}
      <div className="TableWrapBorder">
        <IndexTable
          resourceName={resourceName}
          itemCount={quotes.length}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          selectable={false}
          emptyState={emptyStateMarkup}
          loading={isLoading}
          headings={[
            { title: 'Preventivo' },
            { title: 'Categoria' },
            {
              id: 'expirationDate',
              title: <Text as="span">Scadenza</Text>,
            },
            {
              id: 'amount',
              hidden: false,
              title: <Text as="span">Totale</Text>,
            },
          ]}
        >
          {rowMarkup}
        </IndexTable>
      </div>
      {/* <div className={styles.ProductsBigBtn}>
        <Button primary sizeLarge>
          Nuovo preventivo
        </Button>
      </div> */}
      {/* expired quote history */}
      {/* <div className={styles.ProductsDataList}>
        <div className={styles.ProductsDataListHeader}>Storico preventivi scaduti</div>
        <ResourceList resourceName={resourceName} items={quotes} emptyState={emptyStateMarkup} renderItem={rowMarkup} loading={isLoading} />
      </div> */}
    </div>
  );
}
