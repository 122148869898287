import { useCallback, useEffect, useState } from 'react';
import { Banner, EmptySearchResult, IndexTable, useIndexResourceState, Button, Modal } from '../../../../imb-react';
import styles from '../../Account.module.scss';
import axios, { AxiosError } from 'axios';
import { Order, UserData } from '../../../../types';
import { UploadDocument } from './UploadDocument';
import { FileTypeEnum } from './UploadDocument';
import { saveAs } from 'file-saver';

const translateFileType = (fileType: string) => {
  switch (fileType) {
    case FileTypeEnum.Identity:
      return "Carta d'Identità";
    case FileTypeEnum.Passport:
      return 'Passaporto';
    case FileTypeEnum.DriverLicense:
      return 'Patente';
    case FileTypeEnum.Other:
      return 'Altro';
    default:
      return fileType;
  }
};

type ImagePreviewProps = {
  imageUrl: string;
  onClose: () => void;
};

const ImagePreview = ({ imageUrl, onClose }: ImagePreviewProps) => {
  return (
    <Modal open onClose={onClose} title="Anteprima Immagine">
      <div className={styles.imageContainer}>
        <img src={imageUrl} alt="Anteprima Documento" className={styles.image} />
      </div>
    </Modal>
  );
};

export function Documents() {
  const [isLoading, setIsLoading] = useState(false);
  const [policies, setPolicies] = useState<Order[]>([]);
  const [userFiles, setUserFiles] = useState<UserData | null>(null);
  const [errorBannerVisible, setErrorBannerVisible] = useState(false);
  const { selectedResources, allResourcesSelected } = useIndexResourceState(policies.map((order) => ({ id: order._id })));
  const [showUpload, setShowUpload] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');

  // State for delete confirmation
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserPolicies = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/policies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setPolicies(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserPolicies();
  }, []);

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/user/account`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setUserFiles(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserFiles();
    setUpdatePage(false);
  }, [updatePage]);

  const handleDownloadFile = useCallback(async (key: string, title: string) => {
    try {
      const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/user/files/${key}`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;
      saveAs(data, title);
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
    }
  }, []);

  const handlePreviewImage = useCallback(async (key: string) => {
    try {
      const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/user/files/${key}`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(blob);
      setPreviewImageUrl(url);
      setIsPreviewOpen(true);
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
    }
  }, []);

  const handleDeleteFile = useCallback(async (key: string) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL || '/api'}/user/account/file/${key}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      if (response.status === 200) {
        setUpdatePage(true); // Forza l'aggiornamento della pagina
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setErrorBannerVisible(true); // Mostra un messaggio di errore
    } finally {
      setIsDeleteModalOpen(false); // Chiudi la modale di conferma
    }
  }, []);

  const openDeleteModal = (key: string) => {
    setFileToDelete(key);
    setIsDeleteModalOpen(true);
  };

  if (!userFiles) {
    return <div>Loading...</div>;
  }

  const itemCount = userFiles ? userFiles.customer.files.length : 0;

  const emptyStateMarkup = <EmptySearchResult title={'Nessun documento'} description={'Aggiungi un documento'} withIllustration />;

  const filesTableMarkup = policies.map((order: Order) =>
    order.line_items[0].files.map((file: any, fileIndex: number) => (
      <IndexTable.Row id={file.key} key={file.key} selected={selectedResources.includes(file.key)} position={fileIndex}>
        <IndexTable.Cell>Allegato</IndexTable.Cell>
        <IndexTable.Cell>
          {order.line_items[0].files.map((file: any) => (
            <a
              key={file.key}
              style={{ color: '#2e455c' }}
              target="__blank"
              href={process.env.REACT_APP_STORAGE_PUBLIC_DOCUMENTS ? process.env.REACT_APP_STORAGE_PUBLIC_DOCUMENTS + file.key : '#'}
            >
              {file.title}
            </a>
          ))}
        </IndexTable.Cell>
      </IndexTable.Row>
    )),
  );

  const userFilesTableMarkup = userFiles.customer.files.map((file: any, fileIndex: number) => (
    <IndexTable.Row id={file.key} key={file.key} selected={selectedResources.includes(file.key)} position={fileIndex}>
      <IndexTable.Cell>{translateFileType(file.file_type)}</IndexTable.Cell>
      <IndexTable.Cell>{file.title}</IndexTable.Cell>
      <IndexTable.Cell>
        <div style={{ display: 'flex', gap: '10px' }}>
          <img src="/icon_download.svg" alt="Download" onClick={() => handleDownloadFile(file.key, file.title)} style={{ cursor: 'pointer' }} />
          <img src="/icon_eye.svg" alt="View" onClick={() => handlePreviewImage(file.key)} style={{ cursor: 'pointer' }} />
          <img src="/icon_x.svg" alt="Delete" onClick={() => openDeleteModal(file.key)} style={{ cursor: 'pointer' }} />
        </div>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const bannerMarkup = errorBannerVisible && (
    <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setErrorBannerVisible(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </div>
  );

  return (
    <div className={styles.Documents}>
      {/* Banners */}
      {bannerMarkup}

      {/* Personal Documents */}
      <div className="TableWrapBorder">
        <div className={styles.PersonalDataCardHeader}>Documenti personali:</div>
        <IndexTable
          emptyState={emptyStateMarkup}
          itemCount={itemCount}
          selectable={false}
          loading={isLoading}
          headings={[{ title: 'Tipologia' }, { title: 'Descrizione' }, { title: 'Azioni' }]}
        >
          {userFilesTableMarkup}
        </IndexTable>
        <div className={styles.buttonContainer}>
          <Button primary onClick={() => setShowUpload(true)}>
            Inserisci documento
          </Button>
          <Button>Modifica</Button>
        </div>
        {showUpload && <UploadDocument onUploadComplete={() => setUpdatePage(true)} onCancel={() => setShowUpload(false)} />}
      </div>

      {/* Policies Documents */}
      <div className="TableWrapBorder">
        <div className={styles.PersonalDataCardHeader}>Documenti polizze:</div>
        <IndexTable
          emptyState={emptyStateMarkup}
          itemCount={policies.length}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
          selectable={false}
          loading={isLoading}
          headings={[{ title: 'Tipologia' }, { title: 'Descrizione' }]}
        >
          {filesTableMarkup}
        </IndexTable>
      </div>

      {/* Image Preview Modal */}
      {isPreviewOpen && (
        <ImagePreview
          imageUrl={previewImageUrl}
          onClose={() => {
            setIsPreviewOpen(false);
            URL.revokeObjectURL(previewImageUrl); // Liberiamo la memoria quando l'immagine non è più necessaria
          }}
        />
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <Modal
          open
          onClose={() => setIsDeleteModalOpen(false)} // Funzione per chiudere la modale
          title="Conferma Eliminazione"
          primaryAction={{
            content: 'Elimina', // Testo del pulsante di azione primaria
            destructive: true, // Indica che l'azione è distruttiva
            onAction: () => {
              // Funzione chiamata al clic del pulsante
              if (fileToDelete) handleDeleteFile(fileToDelete);
            },
          }}
          secondaryActions={[
            {
              content: 'Annulla',
              onAction: () => setIsDeleteModalOpen(false),
            },
          ]}
        >
          <Modal.Section>
            <p>Sei sicuro di voler eliminare questo documento? L'azione è irreversibile.</p>
          </Modal.Section>
        </Modal>
      )}
    </div>
  );
}
