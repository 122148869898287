import React, { forwardRef } from 'react';
import type { RefObject } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PolarisContainerProps {}

function PortalsContainerComponent(_props: PolarisContainerProps, ref: RefObject<HTMLDivElement>) {
  return <div id="PolarisPortalsContainer" ref={ref} />;
}

// @ts-ignore
export const PortalsContainer = forwardRef(PortalsContainerComponent);
