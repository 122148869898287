import styles from './../ReportModal.module.scss';

export function PolicyLegal() {
  return (
    <>
      <div className={styles.ReportModalBody__title}>Di cosa si tratta?</div>
      <div className={styles.ReportModalBody__text}>
        L’assicurazione di tutela legale è la polizza assicurativa che ti fornisce assistenza legale tramite <strong>consulenza professionale</strong> e la{' '}
        <strong>copertura</strong> di tali spese in caso di <strong>controversie o situazioni legali</strong>. Inoltre, ti offre supporto nella gestione delle
        procedure legali, per garantire una difesa adeguata e l'accesso a un sistema legale giusto ed equo.
      </div>
      {/* Protection */}
      <div className={styles.ReportModalBody__title}>Da cosa protegge?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>cause civili</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>dispute commerciali</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>controversie di lavoro</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>questioni di responsabilità</span>
        </div>
      </div>
      {/* Cover */}
      <div className={styles.ReportModalBody__title}>Che cosa copre?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>costi degli avvocati</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>spese processuali</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>perizie legali</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>supporto nella gestione delle procedure legali, con consulenza e assistenza</span>
        </div>
      </div>
    </>
  );
}
