import { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Banner, ChoiceList, ContextualSaveBar, FormLayout, Icon, TextField, Toast, Avatar, Button, Link } from '../../../../imb-react';
import styles from '../../Account.module.scss';
import { asChoiceList, useField, useForm } from '@shopify/react-form';
import axios, { AxiosError } from 'axios';

import React from 'react';
import { refreshToken } from '../../../../utils/Common';

export function Profile() {
  const [success, setSuccess] = useState(false);
  const [errorBannerVisible, setErrorBannerVisible] = useState(false);

  /**
   * Use form
   */
  const {
    fields: { name, lastname, phone, email, password },
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean,
  } = useForm({
    fields: {
      name: useField(''),
      lastname: useField(''),
      phone: useField(''),
      email: useField(''),
      password: useField(''),
    },
    onSubmit: async (fieldValues) => {
      try {
        setErrorBannerVisible(false);

        const response = await axios.put(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/account',
          {
            name: fieldValues.name,
            lastname: fieldValues.lastname,
            phone: fieldValues.phone,
            email: fieldValues.email,
            password: fieldValues.password,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        const data = response.data;

        if (data.status === 'success') {
          // Show success toast
          setSuccess(true);

          // Refresh token
          await refreshToken();

          // Wait two seconds and reload the page
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          return { status: 'success' };
        } else {
          setErrorBannerVisible(true);
          return {
            status: 'fail',
            errors: [
              {
                message: "Errore durante l'invio della richiesta",
              },
            ],
          };
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        const status = axiosError.response?.status || 500;

        setErrorBannerVisible(true);
        return {
          status: 'fail',
          errors: [
            {
              message: "Errore durante l'invio della richiesta",
            },
          ],
        };
      }
    },
  });

  function handleDiscard(): void {
    alert('Function not implemented.');
  }

  /**
   * Fetch user data
   */
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/account', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          console.log(data.data);
          // Set user data
          name.newDefaultValue(data.data.name);
          lastname.newDefaultValue(data.data.lastname);

          const { customer } = data.data;

          phone.newDefaultValue(data.data.phone);
          email.newDefaultValue(data.data.email);
          password.newDefaultValue(data.data.password);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      }
    };
    fetchUserData();
  }, []);

  /**
   * Render account label
   * @param text string
   * @returns JSX.Element
   */
  const renderAccountLabel = (text: string) => {
    return <span className={styles.AccountFieldLabel}>{text}</span>;
  };

  /**
   * Save bar
   */
  const contextualSaveBarMarkup = dirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: submit,
        loading: submitting,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
    />
  ) : null;

  /**
   * Toast markup
   */
  const toggleToastActive = useCallback(() => setSuccess((success) => !success), []);
  const toastMarkup = success ? <Toast textColor="white" content="I dati sono stati aggiornati con successo." onDismiss={toggleToastActive} /> : null;

  /**
   * Banner markup
   */
  const bannerMarkup = errorBannerVisible && (
    <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setErrorBannerVisible(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </div>
  );

  return (
    <div className={styles.Profile}>
      {contextualSaveBarMarkup}

      {/* Banners */}
      {bannerMarkup}

      <div className={styles.Profile__leftSide}>
        {/* Notifications */}
        {/* <div className={styles.PersonalDataCard}>
          <div className={styles.PersonalDataCardHeader}>Notifiche</div>
          <div className={styles.PersonalDataCardBody}>
            <div className={styles.Profile__notifications}>
              <p>Messaggio</p>
              <p>Data</p>
              <p>X</p>
            </div>
          </div>
        </div> */}
        {/* Personal data */}
        <div className={styles.PersonalDataCard}>
          <div className={styles.PersonalDataCardHeader}>Dati personali</div>
          <div className={styles.PersonalDataCardBody}>
            {/* avatar */}
            {/* <div className={styles.PersonalDataCard__avatar}>
              <Avatar customer size="large" />
              <img src="/icon_edit.svg" alt="icon edit profile image" />
            </div> */}
            <FormLayout>
              <FormLayout.Group>
                <TextField label={renderAccountLabel('Nome')} autoComplete="off" isAccountField {...name} />
                <TextField label={renderAccountLabel('Cognome')} autoComplete="off" isAccountField {...lastname} />
                <TextField label={renderAccountLabel('Cellulare')} autoComplete="off" isAccountField {...phone} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField label={renderAccountLabel('Email')} autoComplete="off" isAccountField {...email} />
                <div className={styles.Profile__resetPassword}>
                  <p className={styles.Profile__resetPassword_label}>Password</p>
                  <Link imbDarkLow url="/forgot-password">
                    Reimposta la password
                  </Link>
                </div>
              </FormLayout.Group>
            </FormLayout>
          </div>
        </div>
      </div>
      <div className={styles.Profile__rigthSide}>
        <div className={styles.Profile__cta}>
          <img src="/photo_inviteFriend.jpg" alt="photo invite a friend" />
          <Button disabled primary size="large">
            Invita un amico
          </Button>
        </div>
      </div>

      {/* Toast */}
      {toastMarkup}
    </div>
  );
}
