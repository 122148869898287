import React, { useEffect, useState } from 'react';
import { onMessageListener, requestForToken } from '../../../../utils/firebase';
import styles from '../../NavigationMarkup.module.scss';
import { MessagePayload } from 'firebase/messaging';
import { classNames } from '../../../../imb-react/utilities/css';

export function Notification() {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (notification?.title) {
      setIsActive(true);
      alert(notification.title);
    }
  }, [notification]);

  requestForToken();

  onMessageListener()
    .then((payload: MessagePayload) => {
      setNotification({ title: payload?.notification?.title || '', body: payload?.notification?.body || '' });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <div className={styles.NavigationFooter}>
      <div className={styles.NavigationFooter__Box}>
        <div className={styles.NavigationFooter__BoxTitle}>Notifiche</div>
        <div className={styles.NavigationFooter__BoxIcon}>
          <img src="/icon_notification.svg" alt="Logo" />
          <div className={classNames(styles.NavigationFooter__BoxCounter, isActive && styles.active)}>1</div>
        </div>
        <div className={styles.NavigationFooter__BoxText}>
          <p>Controlla per rimanere</p>
          <p>sempre aggiornato</p>
        </div>
        <div className={styles.NavigationFooter__BoxLink} onClick={() => alert('To be implemented')}>
          Visualizza
        </div>
      </div>
    </div>
  );
}
