import React from 'react';
import { Policies } from '../../../Dashboard/components';
import styles from '../StartNewClaim/StartNewClaim.module.scss';

type PolicyChoiceProps = {
  handleSelectedPolicyField: (policy: any) => void;
};

export function PolicyChoice({ handleSelectedPolicyField }: PolicyChoiceProps) {
  return (
    <div className={styles.Claims_form_maxWidth}>
      <Policies purpose="claim" handleSelectedPolicy={handleSelectedPolicyField} />
    </div>
  );
}
