import styles from './../ReportModal.module.scss';

export function PolicyInjuries() {
  return (
    <>
      <div className={styles.ReportModalBody__title}>Di cosa si tratta?</div>
      <div className={styles.ReportModalBody__text}>
        L’assicurazione infortuni è la polizza assicurativa che ti fornisce una <strong>copertura finanziaria</strong> in caso di infortunio o lesione
        personale. La sua principale finalità è di garantirti una sicurezza finanziaria aggiuntiva{' '}
        <strong>per affrontare le conseguenze economiche degli infortuni</strong>, regalandoti una certa{' '}
        <strong>serenità durante il processo di recupero</strong>.
      </div>
      {/* Protection */}
      <div className={styles.ReportModalBody__title}>Da cosa protegge?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>danni fisici</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>invalidità a seguito di incidenti</span>
        </div>
      </div>
      {/* Cover */}
      <div className={styles.ReportModalBody__title}>Che cosa copre?</div>
      <div className={styles.ReportModalBody__list}>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>coperture spese mediche</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>invalidità permanente o temporanea</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>indennità giornaliera per assenza al lavoro</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>costi di riabilitazione</span>
        </div>
        <div className={styles.ReportModalBody__list__item}>
          <span className={styles.ReportModalBody__list__item__img}>
            <img src="/icon_check.svg" />
          </span>
          <span>spese correlate agli infortuni</span>
        </div>
      </div>
    </>
  );
}
