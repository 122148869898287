import React from 'react';

import type { ComplexAction } from '../../../../types';
import { buttonsFrom } from '../../../Button';
import { Box } from '../../../Box';
import { HorizontalStack } from '../../../HorizontalStack';
import { useFeatures } from '../../../../utilities/features';

export interface FooterProps {
  /** Primary action */
  primaryAction?: ComplexAction;
  /** Primary action centered button */
  primaryActionCentered?: boolean;
  /** Primary action bold text */
  primaryActionBold?: boolean;
  /** Primary action uppercase text */
  primaryActionUppercase?: boolean;
  /** Collection of secondary actions */
  secondaryActions?: ComplexAction[];
  /** The content to display inside modal */
  children?: React.ReactNode;
  /** Size button */
  buttonSizeLarge?: boolean;
}

export function Footer({
  primaryAction,
  primaryActionCentered,
  primaryActionBold,
  primaryActionUppercase,
  secondaryActions,
  children,
  buttonSizeLarge,
}: FooterProps) {
  console.log('Valore di sizeLarge:', buttonSizeLarge);
  const { polarisSummerEditions2023 } = useFeatures();

  const primaryActionButton =
    (primaryAction && buttonsFrom(primaryAction, { primary: true, uppercase: primaryActionUppercase, bold: primaryActionBold, sizeLarge: buttonSizeLarge })) ||
    null;
  const secondaryActionButtons = (secondaryActions && buttonsFrom(secondaryActions, { sizeLarge: buttonSizeLarge })) || null;

  const actions =
    primaryActionButton || secondaryActionButtons ? (
      <HorizontalStack gap="2">
        {secondaryActionButtons}
        {primaryActionButton}
      </HorizontalStack>
    ) : null;

  return (
    <HorizontalStack gap="4" blockAlign="center">
      <Box
        borderColor="imb"
        borderBlockStartWidth={polarisSummerEditions2023 ? undefined : '3'}
        minHeight="var(--p-space-16)"
        padding="4"
        paddingInlineStart="5"
        paddingInlineEnd="5"
        width="100%"
      >
        <HorizontalStack gap="4" blockAlign="center" align={primaryActionCentered ? 'center' : 'space-between'}>
          <Box>{children}</Box>
          {actions}
        </HorizontalStack>
      </Box>
    </HorizontalStack>
  );
}
