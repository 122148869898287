/**
 * Generate the fiscal code
 * @param {string} name
 * @param {string} lastname
 * @param {Date} birth_date
 * @param {string} birth_city
 * @param {string} gender (M or F)
 */
export const generateFiscalCode = (name: string, lastname: string, birth_date: Date, birth_city: string, gender: string): string => {
  // Check if there are any missing parameters
  if (name.length === 0) {
    throw new Error('Name is missing');
  }
  if (lastname.length === 0) {
    throw new Error('Lastname is missing');
  }
  if (birth_date === undefined) {
    throw new Error('Birth date is missing');
  }
  if (birth_city.length === 0) {
    throw new Error('Birth city is missing');
  }
  if (gender.length === 0) {
    throw new Error('Gender is missing');
  }

  // Step 1: Name
  const nameVowels: string[] = [];
  const nameConsonants: string[] = [];
  const lastnameVowels: string[] = [];
  const lastnameConsonants: string[] = [];
  let vowels: any[] = [];
  let consonants: any[] = [];
  let n_cons = 0;
  let consonants_taken_name;
  let consonants_taken_lastname;

  if (name.length < 3) {
    // If name is shorter than 3 characters, pad with X
    name = name.padEnd(3, 'X');
  } else {
    for (let i = 0; i <= name.length; i++) {
      if (name.charAt(i).match(/[aeiouAEIOU]/)) {
        // prendo le vocali
        nameVowels[i] = name.charAt(i).toUpperCase();
        vowels = nameVowels.filter(Boolean);
      } else if (name.charAt(i).match(/[/',/"]/)) {
        continue;
      } else {
        nameConsonants[i] = name.charAt(i).toUpperCase();
        consonants = nameConsonants.filter(Boolean);
        if (nameConsonants[i] != '') {
          n_cons++;
        }
      }
    }
  }

  // If there are more than 3 consonants, take the first, third and fourth
  if (consonants.length >= 4) {
    consonants_taken_name = [consonants[0], consonants[2], consonants[3]];
  }
  // If there is only one consonant, take the first and second vowel
  else if (consonants.length == 1) {
    consonants_taken_name = [consonants[0], vowels[0], vowels[1]];
  }
  // If the consonants are less than 3, take the first and second consonant and the first vowel
  else if (consonants.length < 3) {
    consonants_taken_name = [consonants[0], consonants[1], vowels[0]];
  }
  // If there are exactly 3 consonants, take the first, second and third
  if (consonants.length == 3) {
    consonants_taken_name = [consonants[0], consonants[1], consonants[2]];
  }

  // Step 2: Surname
  if (lastname.length < 3) {
    lastname += 'x';
  } else {
    for (let i = 0; i <= lastname.length; i++) {
      if (lastname.charAt(i).match(/[aeiouAEIOU]/)) {
        // prendo le vocali
        lastnameVowels[i] = lastname.charAt(i).toUpperCase();
        vowels = lastnameVowels.filter(Boolean);
      } else if (lastname.charAt(i).match(/[/',/"]/)) {
        continue;
      } else {
        lastnameConsonants[i] = lastname.charAt(i).toUpperCase();
        consonants = lastnameConsonants.filter(Boolean);

        if (lastnameConsonants[i] != '') {
          n_cons++;
        }
      }
    }
  }

  if (consonants.length < 3) {
    // Take the first and second consonant and the first vowel
    consonants_taken_lastname = [consonants[0], consonants[1], vowels[0]];
  } else {
    // Take the first, second and third consonant
    consonants_taken_lastname = [consonants[0], consonants[1], consonants[2]];
  }

  if (consonants.length == 1) {
    consonants_taken_lastname = [consonants[0], vowels[0], vowels[1]];
  }

  // Step 2: Date of Birth
  const year = birth_date.getFullYear() % 100;
  const month = 'ABCDEHLMPRST'[birth_date.getMonth()];
  let day = birth_date.getDate().toString().padStart(2, '0');
  if (gender === 'female') {
    day = (parseInt(day) + 40).toString();
  }

  // Step 3: Place of Birth Code
  const birthplaceCodePadded = birth_city.padEnd(4, 'X');

  // Combine and calculate control character
  const partialCode =
    consonants_taken_lastname.join('') + consonants_taken_name?.join('') + year.toString().padStart(2, '0') + month + day + birthplaceCodePadded;
  const controlCharacter = calculateControlCharacter(partialCode);

  // Final fiscal code
  return partialCode + controlCharacter;
};

function calculateControlCharacter(partialCode: string): string {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const oddMap: Record<string, number> = {
    '0': 1,
    '1': 0,
    '2': 5,
    '3': 7,
    '4': 9,
    '5': 13,
    '6': 15,
    '7': 17,
    '8': 19,
    '9': 21,
    A: 1,
    B: 0,
    C: 5,
    D: 7,
    E: 9,
    F: 13,
    G: 15,
    H: 17,
    I: 19,
    J: 21,
    K: 2,
    L: 4,
    M: 18,
    N: 20,
    O: 11,
    P: 3,
    Q: 6,
    R: 8,
    S: 12,
    T: 14,
    U: 16,
    V: 10,
    W: 22,
    X: 25,
    Y: 24,
    Z: 23,
  };

  let sum = 0;
  for (let i = 0; i < partialCode.length; i++) {
    const char = partialCode[i];
    sum += (i + 1) % 2 !== 0 ? oddMap[char] : !isNaN(parseInt(char)) ? parseInt(char) : alphabet.indexOf(char);
  }

  return alphabet[sum % 26];
}
