import React from 'react';

import { classNames } from '../../../../utilities/css';
import styles from '../../Layout.module.scss';

export interface SectionProps {
  children?: React.ReactNode;
  secondary?: boolean;
  fullWidth?: boolean;
  oneHalf?: boolean;
  oneThird?: boolean;
}

export function Section({ children, secondary, fullWidth, oneHalf, oneThird }: SectionProps) {
  const className = classNames(
    styles.Section,
    secondary && styles['Section-secondary'],
    fullWidth && styles['Section-fullWidth'],
    oneHalf && styles['Section-oneHalf'],
    oneThird && styles['Section-oneThird'],
  );

  return <div className={className}>{children}</div>;
}
