import React, { useCallback, useState } from 'react';
import { ActionList, TopBar } from '../../imb-react';

import { app } from '../../utils/firebase';
import { getAuth } from 'firebase/auth';

import { CircleRightMajor, ProfileMajor } from '@shopify/polaris-icons';

import logo from '../../logo_bw.svg';

import { getInitials } from '../../utils/Common';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

type TopBarMarkupProps = {
  handleMobileNavigation: (data: any) => void;
  user: any;
  isComingSoonPage?: boolean;
};

export function TopBarMarkup({ handleMobileNavigation, user, isComingSoonPage }: TopBarMarkupProps) {
  const navigate = useNavigate();

  const auth = getAuth(app);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const handleSearchFieldChange = useCallback((value: any) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);
  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
    handleMobileNavigation(mobileNavigationActive);
  }, [handleMobileNavigation, mobileNavigationActive]);
  const toggleUserMenuActive = useCallback(() => setUserMenuActive((userMenuActive) => !userMenuActive), []);

  /**
   * Logout
   */
  const logout = async () => {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Remove token from localstorage
        localStorage.removeItem('mb__access_token');

        // Redirect to login page
        window.location.href = '/login';
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userMenuActions = [
    {
      items: [
        {
          content: 'Account',
          icon: ProfileMajor,
          onAction: async () => navigate('/account/profile'),
        },
      ],
    },
    {
      items: [
        {
          content: 'Logout',
          icon: CircleRightMajor,
          onAction: async () => {
            if (auth.currentUser) {
              await auth.signOut();
            }
            await logout();
          },
        },
      ],
    },
  ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={user.lastname ? user.name + ' ' + user.lastname : user.name}
      initials={getInitials(user.lastname ? user.name + ' ' + user.lastname : user.name)}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
      accessibilityLabel="User menu"
    />
  );

  // const searchResultsMarkup = <ActionList items={[{ content: 'NANO Platform help center' }]} />;

  // const searchFieldMarkup = <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Cerca" />;

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      // searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      // searchResults={searchResultsMarkup}
      // onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
      // contextControl={
      //   <div className="ContextControl">
      //     <img height={40} src={logo} />
      //   </div>
      // }
      searchResultsOverlayVisible
      isComingSoonPage={isComingSoonPage}
    />
  );

  return topBarMarkup;
}
