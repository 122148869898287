type ProductsReportType = {
  label: string;
  type: string;
};

const products: ProductsReportType[] = [
  {
    label: 'PIANO SANITARIO',
    type: 'ASSICURAZIONE (età piano sanitario)',
  },
  {
    label: 'ERROR&OMISSION',
    type: 'ASSICURAZIONE (attività lavorativa 1)',
  },
  {
    label: 'TUTELA LEGALE',
    type: 'ASSICURAZIONE (attività lavorativa 2)',
  },
  {
    label: 'INFORTUNI',
    type: 'ASSICURAZIONE (attività lavorativa 3)',
  },
  {
    label: 'RCT',
    type: 'ASSICURAZIONE (locale commerciale rct)',
  },
  {
    label: 'INCENDIO E SCOPPIO',
    type: 'ASSICURAZIONE (locale comerciale inc&scoppio)',
  },
  {
    label: 'TUTELA LEGALE',
    type: 'ASSICURAZIONE (locale commerciale tutela legale)',
  },
  {
    label: 'FURTO',
    type: 'ASSICURAZIONE (locale commerciale furto)',
  },
  {
    label: 'RC CAPOFAMIGLIA',
    type: 'ASSICURAZIONE (figli rc capofamiglia)',
  },
  {
    label: 'INFORTUNI FAMIGLIA',
    type: 'ASSICURAZIONE (figli rc famiglia)',
  },
  {
    label: 'RC CASA',
    type: 'ASSICURAZIONE (immobili rc casa)',
  },
];

export type { ProductsReportType };
export default products;
