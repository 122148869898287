import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Banner, Button, Frame, Layout, Loading, Page, UnstyledLink } from '../../imb-react';
import { TopBarMarkup, NavigationMarkup } from '../../components';

import styles from './Account.module.scss';
import { useUser } from '../../utils/PrivateRoute';
import { classNames } from '../../imb-react/utilities/css';
import { PersonalData, Documents, Profile } from './components';
import { Order } from '../../types';
import { useParams } from 'react-router-dom';

export function Account() {
  const { user } = useUser();
  const params = useParams();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);
  const [printError, setPrintError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Documents
   */
  const [document, setDocument] = useState<Order>({} as Order);

  const handleSelectDocument = useCallback((document: any) => {
    setDocument(document);
  }, []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Render account content based on the current route
   */
  const renderAccountContent = () => {
    switch (params.id) {
      case 'profile':
        return <Profile />;
      case 'personalData':
        return <PersonalData />;
      case 'documents':
        return <Documents />;
      default:
        return <Profile />;
    }
  };

  /**
   * Banner markup
   */
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  const printBannerMarkup = printError && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nella stampa del report" status="critical" onDismiss={() => setPrintError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  /**
   * Page markup
   */
  const pageMarkup = (
    <Page fullWidth>
      {/* Banners */}
      {bannerMarkup}
      {printBannerMarkup}

      {/* Header */}
      <div className={styles.Header}>
        {/* title */}
        <div className={styles.Header__title}>Area utente</div>
        {/* search input */}
      </div>
      {/* <div className={styles.AccountHeaderProgress}>
          <div className={styles.AccountHeaderProgressBox}>
            <div className={styles.AccountHeaderProgressBox__title}>80%</div>
            <div className={styles.AccountHeaderProgressBox__subtitle}>Completato</div>
            <div className={styles.AccountHeaderProgressBox__cta}>
              <Button plain plainColorBlue bold>
                Modifica i dati inseriti
              </Button>
            </div>
          </div>
          <div className={classNames(styles.AccountHeaderProgressBox, styles.missing)}>
            <div className={classNames(styles.AccountHeaderProgressBox__title, styles.missing)}>20%</div>
            <div className={classNames(styles.AccountHeaderProgressBox__subtitle, styles.missing)}>Mancante</div>
            <div className={styles.AccountHeaderProgressBox__cta}>
              <Button plain plainColorBlue bold>
                Modifica i dati inseriti
              </Button>
            </div>
          </div>
        </div> */}

      {/* Account navigation */}
      <div className={styles.AccountNavigation}>
        <div className={classNames(styles.AccountNavigationBox, (params.id === 'profile' || params.id === undefined) && styles.active)}>
          <UnstyledLink url="/account/profile" className={styles.AccountNavigationBox__link}>
            Profilo
          </UnstyledLink>
        </div>
        <div className={classNames(styles.AccountNavigationBox, params.id === 'personalData' && styles.active)}>
          <UnstyledLink url="/account/personalData" className={styles.AccountNavigationBox__link}>
            Anagrafica
          </UnstyledLink>
        </div>
        <div className={classNames(styles.AccountNavigationBox, params.id === 'documents' && styles.active)}>
          <UnstyledLink url="/account/documents" className={styles.AccountNavigationBox__link}>
            Documenti
          </UnstyledLink>
        </div>
      </div>

      {/* Account content */}
      <div className={styles.AccountContent}>{renderAccountContent()}</div>
    </Page>
  );

  return (
    <div className="AppContainer">
      <Frame
        topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
        navigation={<NavigationMarkup user={user} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
        offset="2rem"
      >
        {/* {loadingMarkup} */}
        {pageMarkup}
      </Frame>
    </div>
  );
}
