import { useState, useEffect, useRef } from 'react';
import { TextField, FormLayout } from '../../../../imb-react';
import styles from '../StartNewClaim/StartNewClaim.module.scss';

type ClaimDescriptionProps = {
  descriptionField: any;
  audioUrl: string | null;
  setAudioUrl: (url: string | null) => void;
  setAudioFile: (file: File | null) => void;
};

export function ClaimDescription({ descriptionField, audioUrl, setAudioUrl, setAudioFile }: ClaimDescriptionProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    let localAudioChunks: Blob[] = [];

    if (isRecording) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);

          recorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              localAudioChunks.push(event.data);
            }
          };

          recorder.onstop = () => {
            const blob = new Blob(localAudioChunks, { type: 'audio/wav' });
            const audioFile = new File([blob], 'recording.wav', { type: 'audio/wav' });
            setAudioFile(audioFile);
            setAudioUrl(URL.createObjectURL(blob));
            localAudioChunks = [];
          };

          recorder.start();
        })
        .catch((error) => {
          console.error('Microphone access denied or other error:', error);
        });
    } else if (mediaRecorder) {
      mediaRecorder.stop();
    }
  }, [isRecording]);

  useEffect(() => {
    const audioElement = audioRef.current;

    const updateTime = () => {
      if (audioElement) {
        setCurrentTime(audioElement.currentTime);
        setDuration(audioElement.duration);
      }
    };

    if (audioElement) {
      audioElement.addEventListener('timeupdate', updateTime);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('timeupdate', updateTime);
      }
    };
  }, [audioRef.current]);

  const handleRecordClick = () => {
    if (isRecording) {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
      setIsRecording(false);
    } else {
      setAudioUrl(null); // Clear previous URL
      setAudioFile(null); // Clear previous audio file
      setIsRecording(true);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (!isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleReset = () => {
    // Stop audio playback and reset time and duration
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setCurrentTime(0);
    setDuration(0);
    setAudioUrl(null);
    setAudioFile(null); // Clear audio file
    setIsPlaying(false);
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (audioRef.current && !audioRef.current.paused) {
        setCurrentTime(audioRef.current.currentTime);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const progressBarWidth = duration ? (currentTime / duration) * 100 : 0;

  return (
    <FormLayout.Group>
      <TextField label="Scrivici cos'è accaduto" type="text" autoComplete="off" multiline={10} maxHeight={250} isAccountField {...descriptionField} />

      <div className={styles.Claim_Recorder_Container}>
        <span className={styles.helperText}>Raccontaci cos'è accaduto</span>
        <div className={styles.Claim_Recorder_Box}>
          <span className={styles.Claim_Recorder_placeholder}>Registra qua la tua voce</span>
          {!audioUrl && (
            <div className={isRecording ? styles.stopButton : styles.recordButton}>
              <div onClick={handleRecordClick}>
                {isRecording ? (
                  <>
                    <img src="icon_mic.svg" /> <span>Stop</span>
                  </>
                ) : (
                  <>
                    <img src="icon_mic.svg" /> <span>Registra</span>
                  </>
                )}
              </div>
            </div>
          )}
          {audioUrl && (
            <>
              <div className={styles.audioControls}>
                <div className={styles.playerContainer}>
                  <div className={styles.progressBarContainer}>
                    <div className={styles.progressBar} style={{ width: `${progressBarWidth}%` }}></div>
                  </div>
                  <div className={styles.xButton} onClick={handleReset}>
                    <img src="/icon_x.svg" alt="delete" />
                  </div>
                </div>
                <div className={styles.audioTime}>{formatTime(currentTime)}</div>
                <div className={styles.playerControlButton} onClick={handlePlayPause}>
                  {isPlaying ? <img src="/icon_play.svg" /> : <img style={{ marginLeft: '7px' }} src="/icon_pause.svg" />}
                </div>
                <audio className={styles.audio} ref={audioRef} src={audioUrl} />
              </div>
            </>
          )}
        </div>
      </div>
    </FormLayout.Group>
  );
}
