import { useEffect, useState } from 'react';
import {
  Badge,
  Banner,
  EmptySearchResult,
  IndexTable,
  Link,
  Text,
  useIndexResourceState,
  ResourceList,
  ResourceItem,
  HorizontalGrid,
  Button,
} from '../../../../imb-react';
import styles from '../../Products.module.scss';
import axios, { AxiosError } from 'axios';
import { Order } from '../../../../types';
import { getPolicyExpirationDate } from '../../../../utils/Common';

type PoliciesType = {
  /** Handler for selecting the single quote */
  onSelect: (policies: Order) => void;
};

export function Policies({ onSelect }: PoliciesType) {
  const [errorBannerVisible, setErrorBannerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [policies, setPolicies] = useState([]);
  const [expiredPolicies, setExpiredPolicies] = useState([]);

  const resourceName = {
    singular: 'polizza',
    plural: 'polizze',
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(policies);

  /**
   * Fetch user quotes
   */
  useEffect(() => {
    const fetchUserQuotes = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/policies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          console.log('Polizze ricevute:', data.data);
          // Set all policies
          setPolicies(data.data);

          // Filter expired policies
          const expired = data.data.filter((policy: any) => policy.status === 'expired');
          setExpiredPolicies(expired);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        setErrorBannerVisible(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserQuotes();
  }, []);

  /**
   * Render policy expiration markup
   * @param {Date} date_created Data di creazione della polizza
   * @returns {string} Stato della polizza: 'Da rinnovare', 'in scadenza', 'attiva'
   */
  const renderPolicyExpirationMarkup = (date_end: Date | undefined): string => {
    if (!date_end) {
      return 'In attesa di emissione';
    }
    // data di scadenza come stringa nel formato "dd/mm/yyyy"
    const expirationDateStr = getPolicyExpirationDate(date_end);

    // Dividi la stringa in giorno, mese e anno
    const parts = expirationDateStr.split('/');
    // Costruisci una nuova stringa nel formato "yyyy-mm-dd"
    const formattedExpirationDateStr = `${parts[2]}-${parts[1]}-${parts[0]}`;

    // Converti la stringa formattata in un oggetto Date
    const expirationDate = new Date(formattedExpirationDateStr);

    // Calcola la data di inizio della finestra di "in scadenza" (15 giorni prima dalla data di scadenza)
    const startDate = new Date(expirationDate);
    startDate.setDate(startDate.getDate() - 15);

    // Ottieni la data corrente
    const currentDate = new Date();

    // Verifica se la polizza è attiva, in scadenza o da rinnovare
    if (currentDate < startDate) {
      // La polizza è attiva perché non sono ancora trascorsi i primi 15 giorni dalla creazione
      return 'attiva';
    } else if (currentDate <= expirationDate) {
      // La polizza è in scadenza perché mancano meno di 15 giorni alla data di scadenza
      return 'in scadenza';
    } else {
      // La polizza è scaduta (da rinnovare)
      return 'Da rinnovare';
    }
  };

  /**
   * Index table empty state markup
   */
  const emptyStateMarkup = <EmptySearchResult title={'Nessuna polizza'} withIllustration />;

  /**
   * Index table row markup
   */
  const rowMarkup = policies.map((order: Order, index) => {
    // Calcola lo stato della polizza in base alla data di scadenza
    const policyStatus = renderPolicyExpirationMarkup(order.date_end ? new Date(order.date_end) : undefined);
    let badgeColorClass;
    // Determina il colore del badge in base allo stato della polizza order.status === 'paid' ? order?.date_end : order.date_end
    switch (policyStatus) {
      case 'Da rinnovare':
        badgeColorClass = styles.redBadge;
        break;
      case 'in scadenza':
        badgeColorClass = styles.yellowBadge;
        break;
      case 'In attesa di emissione':
        badgeColorClass = styles.yellowBadge;
        break;
      default:
        badgeColorClass = styles.greenBadge;
    }

    return (
      <IndexTable.Row id={order._id} key={order._id} selected={selectedResources.includes(order._id)} position={index}>
        <IndexTable.Cell>
          <img
            className={styles.Products_icon}
            src={order.line_items[0].product.icon && process.env.REACT_APP_BLOB_IMAGES_URL + order.line_items[0].product.icon.key}
            alt={order.line_items[0].product.icon && order.line_items[0].product.icon.title}
          />
          <Link dataPrimaryLink onClick={() => onSelect(order)} removeUnderline imbDarkUp>
            <Text fontWeight="bold" as="span">
              {order.line_items[0].name}
            </Text>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge imb>{order.line_items[0].product.category.name}</Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <span className={`${styles.badge} ${badgeColorClass}`}>
            {order.date_end ? getPolicyExpirationDate(new Date(order.date_end)) : 'In attesa di emissione'}
          </span>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" numeric>
            {order.total_price.toFixed(2)} €
          </Text>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Banner markup
   */
  const bannerMarkup = errorBannerVisible && (
    <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setErrorBannerVisible(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </div>
  );

  return (
    <div className={styles.ProductsData}>
      {/* Banners */}
      {bannerMarkup}

      {/* Personal data */}
      <div className="TableWrapBorder">
        <IndexTable
          resourceName={resourceName}
          itemCount={policies.length}
          selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
          onSelectionChange={handleSelectionChange}
          selectable={false}
          emptyState={emptyStateMarkup}
          loading={isLoading}
          headings={[
            { title: 'Polizza' },
            { title: 'Categoria' },
            {
              id: 'status',
              title: <Text as="span">Scadenza</Text>,
            },
            {
              id: 'amount',
              hidden: false,
              title: <Text as="span">Totale</Text>,
            },
          ]}
        >
          {rowMarkup}
        </IndexTable>
      </div>
      {/* <div className={styles.ProductsBigBtn}>
        <Button primary sizeLarge>
          Nuova polizza
        </Button>
      </div> */}
      {/* expired quote history */}
      <div className={styles.ProductsDataList}>
        <div className={styles.ProductsDataListHeader}>Storico polizze scadute</div>
        <ResourceList
          resourceName={resourceName}
          items={expiredPolicies.map((order: Order) => ({
            id: order._id,
            accessibilityLabel: `View details for ${order.line_items[0].name}`,
            onClick: () => onSelect(order),
            persistActions: true,
            children: (
              <HorizontalGrid columns={3}>
                <div className={styles.ProductsDataListItem__title}>{order.line_items[0].name}</div>
                <div className={styles.ProductsDataListItem__text}>
                  scaduta in data {order.date_end ? getPolicyExpirationDate(new Date(order.date_end)) : 'In attesa di emissione'}
                </div>
                <div className={styles.ProductsDataListItem__text}>valore alla scadenza € {order.total_price.toFixed(2)}</div>
              </HorizontalGrid>
            ),
          }))}
          renderItem={(item, id, index) => (
            <ResourceItem id={id} accessibilityLabel={item.accessibilityLabel} onClick={item.onClick} persistActions={item.persistActions}>
              {item.children}
            </ResourceItem>
          )}
          emptyState={emptyStateMarkup}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
