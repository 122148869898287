import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Banner,
  ContextualSaveBar,
  DropZone,
  EmptySearchResult,
  FormLayout,
  HorizontalGrid,
  HorizontalStack,
  Icon,
  Modal,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
  Thumbnail,
  Toast,
} from '../../../../imb-react';
import styles from '../../Products.module.scss';
import axios, { AxiosError } from 'axios';
import { Order, PersonalOrder } from '../../../../types';
import { parseDateLabel } from '../../../../utils/Common';
import { NoteMinor, SearchMinor } from '@shopify/polaris-icons';
import { saveAs } from 'file-saver';

type PersonalPolicies = {
  /** Handler for selecting the single quote */
  onSelect: (quote: Order) => void;
};

export function PersonalPolicies() {
  const [isDirty, setIsDirty] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [errorBannerVisible, setErrorBannerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const handleDeleteConfirmationModalChange = useCallback(() => setDeleteConfirmationModal((deleteConfirmationModal) => !deleteConfirmationModal), []);

  const [policies, setPolicies] = useState([]);
  const resourceName = {
    singular: 'polizza',
    plural: 'polizze',
  };

  /**
   * New personal policy states
   */
  const [newPersonalPolicy, setNewPersonalPolicy] = useState({
    name: '',
    company: '',
    date_end: '',
    total_price: '',
  });
  const [file, setFile] = useState<File | null>(null);

  const [defaultState, setDefaultState] = useState({
    name: '',
    company: '',
    date_end: '',
    total_price: '',
  });

  const [emptyFields, setEmptyFields] = useState({
    name: false,
    date_end: false,
    total_price: false,
    file: false,
  });

  /** Global handler */
  const handlePersonalPolicyChange = useCallback((value: string, id: string) => {
    // Set dirty state
    setIsDirty(true);

    // Condition for price id
    if (id === 'total_price') {
      // Check if the value is a number
      if (!isNaN(Number(value))) {
        setNewPersonalPolicy((personalPolicy) => ({ ...personalPolicy, [id]: value }));
      }
    } else {
      setNewPersonalPolicy((personalPolicy) => ({ ...personalPolicy, [id]: value }));
    }
  }, []);

  /** Drop zone handler */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setFile(acceptedFiles[0]);
    setEmptyFields((emptyFields) => ({ ...emptyFields, file: false }));
  }, []);

  /** Discard handler */
  const handleDiscard = useCallback(() => {
    setNewPersonalPolicy(defaultState);
    setFile(null);
    setIsDirty(false);
  }, [defaultState]);

  /**
   * Drop zone
   */
  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <HorizontalStack gap={'5'} blockAlign="center">
      <Thumbnail size="small" alt={file.name} source={NoteMinor} />
      <div>{file.name}</div>
    </HorizontalStack>
  );

  /**
   * Fetch data:
   * - user personal policies
   * - companies
   * - categories
   */
  useEffect(() => {
    const fetchUserPersonalPolicies = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/personal_orders', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setPolicies(data.data);
          console.log(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserPersonalPolicies();
  }, [update]);

  /**
   * Save handler
   */
  const handleSave = useCallback(async () => {
    try {
      setIsSubmitting(true);

      // Check if fields are empty
      let errFlag = false;
      if (newPersonalPolicy.name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        errFlag = true;
      }
      if (newPersonalPolicy.date_end === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, date_end: true }));
        errFlag = true;
      }
      if (newPersonalPolicy.total_price === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, total_price: true }));
        errFlag = true;
      }
      if (!file) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, file: true }));
        errFlag = true;
      }

      if (errFlag) {
        setIsSubmitting(false);
        return;
      }

      // Create form data
      const formData = new FormData();

      // Files
      if (file) {
        formData.append('document', file);
      }

      // Data
      formData.append('name', newPersonalPolicy.name);
      formData.append('company', newPersonalPolicy.company);
      formData.append('date_end', newPersonalPolicy.date_end);
      formData.append('total_price', newPersonalPolicy.total_price);

      // Send request
      const response = await axios.post((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/personal_orders/new', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      const data = response.data;

      if (data.status === 'success') {
        // Show success toast
        setSuccess(true);
        setIsDirty(false);
        setUpdate(!update);

        // Clear form
        setNewPersonalPolicy(defaultState);
        setFile(null);
      } else {
        setErrorBannerVisible(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setErrorBannerVisible(true);
    } finally {
      setIsSubmitting(false);
    }
  }, [newPersonalPolicy, file, defaultState, update]);

  /**
   * Handle download file
   * @param id string
   * @param title string
   */
  const handleDownloadFile = useCallback(async (id: string, title: string) => {
    try {
      const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/user/personal_orders/${id}/files/`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;
      saveAs(data, title);
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
    }
  }, []);

  /**
   * Handle remove personal policy
   * @param id string
   */
  const handleDelete = useCallback(
    async (id: string) => {
      try {
        setIsDeleting(true);
        const response = await axios.delete((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/user/personal_orders/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setDeleteSuccess(true);
          setUpdate(!update);
          setDeleteId(null);
          handleDeleteConfirmationModalChange();
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        setErrorBannerVisible(true);
      } finally {
        setIsDeleting(false);
      }
    },
    [update, handleDeleteConfirmationModalChange],
  );

  /**
   * Empty state markup
   */
  const emptyStateMarkup = <EmptySearchResult title={'Nessuna polizza'} description={'Aggiungi una polizza'} withIllustration />;

  /**
   * Render row markup
   */
  const renderRowMarkup = (item: PersonalOrder) => {
    const { _id, name, date_end, total_price, file } = item;

    const shortcutActions = file
      ? [
          {
            accessibilityLabel: `Download ${name}’s file`,
            icon: <img src="/icon_download.svg" width={15} />,
            onAction: () => handleDownloadFile(_id, file.title),
          },
          {
            accessibilityLabel: `Download ${name}’s file`,
            icon: <img src="/icon_close.svg" width={15} />,
            onAction: () => {
              setDeleteId(_id);
              handleDeleteConfirmationModalChange();
            },
          },
        ]
      : undefined;

    return (
      <ResourceItem id={_id} accessibilityLabel={`View details for ${name}`} onClick={() => alert('to do')} shortcutActions={shortcutActions} persistActions>
        <HorizontalGrid columns={4}>
          <div className={styles.ProductsDataListItem__title}>{name}</div>
          <div className={styles.ProductsDataListItem__text}>{parseDateLabel(date_end || new Date())}</div>
          <div className={styles.ProductsDataListItem__text}>{total_price} €</div>
        </HorizontalGrid>
      </ResourceItem>
    );
  };

  /**
   * Delete confirmation modal
   */
  const deleteModalMarkup = deleteConfirmationModal && (
    <Modal
      open={deleteConfirmationModal}
      onClose={handleDeleteConfirmationModalChange}
      title="Elimina polizza"
      primaryAction={{
        content: 'Elimina',
        destructive: true,
        onAction: () => {
          handleDelete(deleteId || '');
        },
        loading: isDeleting,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: handleDeleteConfirmationModalChange,
        },
      ]}
    >
      <Modal.Section>
        <Text as="p">Sei sicuro di voler eliminare la polizza?</Text>
        <Text as="p">Una volta proceduto non è possibile tornare indietro.</Text>
      </Modal.Section>
    </Modal>
  );

  /**
   * Render account label
   * @param text string
   * @returns JSX.Element
   */
  const renderAccountLabel = (text: string) => {
    return <span className={styles.AccountFieldLabel}>{text}</span>;
  };

  /**
   * Save bar
   */
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: isSubmitting,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
    />
  ) : null;

  /**
   * Toast markup
   */
  const toggleToastActive = useCallback(() => setSuccess((success) => !success), []);
  const toastMarkup = success ? <Toast textColor="white" content="I dati sono stati aggiornati con successo." onDismiss={toggleToastActive} /> : null;

  const toggleToastDeleteActive = useCallback(() => setDeleteSuccess((success) => !success), []);
  const toastDeleteMarkup = deleteSuccess ? (
    <Toast textColor="white" content="La polizza è stata eliminata con successo." onDismiss={toggleToastDeleteActive} />
  ) : null;

  /**
   * Banner markup
   */
  const bannerMarkup = errorBannerVisible && (
    <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setErrorBannerVisible(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </div>
  );

  return (
    <div className={styles.ProductsData}>
      {contextualSaveBarMarkup}

      {/* Banners */}
      {bannerMarkup}

      {/* Header */}
      <div className={styles.ProductsDataHeader}>
        <div className={styles.ProductsDataHeader__title}>Hai già polizze attive stipulate con terzi?</div>
        <div className={styles.ProductsDataHeader__body}>
          <p>Carica le tue polizze nel cassetto assicurativo,</p>
          <p>ti aiuteremo a monitorarle e ti avviseremo quando saranno in scadenza</p>
        </div>
      </div>

      {/* New personal policy */}
      <div className={styles.ProductsDataCard}>
        <div className={styles.ProductsDataCardHeader}>Dettagli polizza</div>
        <div className={styles.ProductsDataCardBody}>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label={renderAccountLabel('Nome polizza')}
                autoComplete="off"
                isAccountField
                value={newPersonalPolicy.name}
                onChange={(value: string) => handlePersonalPolicyChange(value, 'name')}
                error={emptyFields.name && 'Il nome è obbligatorio'}
              />
              <TextField
                label={renderAccountLabel('Data di scadenza')}
                type="date"
                autoComplete="off"
                isAccountField
                value={newPersonalPolicy.date_end}
                onChange={(value: string) => handlePersonalPolicyChange(value, 'date_end')}
                error={emptyFields.date_end && 'La data di scadenza è obbligatoria'}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label={renderAccountLabel('Compagnia')}
                autoComplete="off"
                isAccountField
                value={newPersonalPolicy.company}
                onChange={(value: string) => handlePersonalPolicyChange(value, 'company')}
              />
              <TextField
                label={renderAccountLabel('Importo pagato')}
                autoComplete="off"
                isAccountField
                value={newPersonalPolicy.total_price}
                onChange={(value: string) => handlePersonalPolicyChange(value, 'total_price')}
                prefix="€"
                error={emptyFields.total_price && "L'importo pagato è obbligatorio"}
              />
            </FormLayout.Group>

            {/* Drop zone */}
            <FormLayout.Group>
              <DropZone
                error={emptyFields.file}
                allowMultiple={false}
                onDrop={handleDropZoneDrop}
                label={renderAccountLabel('Carica il documento della polizza')}
              >
                {uploadedFile}
                {fileUpload}
              </DropZone>
            </FormLayout.Group>
            {emptyFields.file && <div style={{ marginTop: '8px', color: '#c5280c', fontSize: '.875rem' }}>Il caricamento del documento è obbligatorio</div>}
          </FormLayout>
        </div>
      </div>

      {/* Personal policies list */}
      <div className={styles.ProductsDataList}>
        <div className={styles.ProductsDataListHeader}>Le tue polizze esterne</div>
        <ResourceList resourceName={resourceName} items={policies} emptyState={emptyStateMarkup} renderItem={renderRowMarkup} loading={isLoading} />
      </div>

      {/* Toast */}
      {toastMarkup}
      {toastDeleteMarkup}

      {/* Modal */}
      {deleteModalMarkup}
    </div>
  );
}
