import { useState, useCallback, useEffect, useRef } from 'react';
import { ClaimDate, ClaimDescription, ClaimLocation, ClaimMediaUpload, ClaimReport, PolicyChoice, PaymentIBAN, Preview } from './index';
import { Banner, Button, Form, Frame, LegacyStack } from '../../../../imb-react';
import styles from './StartNewClaim.module.scss';
import { notEmpty, useField, useForm } from '@shopify/react-form';
import axios, { AxiosError } from 'axios';
import { TopBarMarkup } from '../../../../components';
import { useUser } from '../../../../utils/PrivateRoute';

interface Policy {
  _id: string;
}

type BankAccount = {
  name: string;
  iban: string;
};

type ClaimAddress = {
  line: string;
  city: string;
  province: string;
  zip: string;
  country: string;
};

export type ClaimFile = {
  key: string;
  title: string;
  file_type: 'audio' | 'image' | 'other';
  file: File;
};

export function StartNewClaim() {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const { user } = useUser();
  const [step, setStep] = useState(0);
  const [selectedPolicy, setSelectedPolicy] = useState<Policy | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [errorBannerVisible, setErrorBannerVisible] = useState(false);
  const [validatesBannerVisible, setValidatesBannerVisible] = useState(false);
  const [missingReportVisible, setMissingReportVisible] = useState(false);
  const [proceedWithoutImageReport, setProceedWithoutImageReport] = useState(false);

  const IBAN_REGEX = /^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/;

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  const uploadFile = async (claimId: string, file: File, fileType: string) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('file_type', fileType);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_TEST ? process.env.REACT_APP_API_URL_TEST : '/api'}/user/claims/${claimId}/file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );

      if (response.status !== 200) {
        throw new Error('Errore nel caricamento del file');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log('Errore durante il caricamento del file:', axiosError);
      throw axiosError;
    }
  };

  const {
    fields: { accountHolder, IBAN, claimDate, region, city, address, file, imageReport, description },
    submit,
  } = useForm({
    fields: {
      accountHolder: useField({
        value: '',
        validates: notEmpty("Il nome dell'intestatario del conto è obbligatorio"),
      }),
      IBAN: useField({
        value: '',
        validates: (value) => {
          if (value === '') return "L'IBAN è obbligatorio";
          else if (!IBAN_REGEX.test(value)) {
            return 'Formato IBAN non valido';
          }
        },
      }),
      claimDate: useField({
        value: '',
        validates: notEmpty('La data del sinistro è obbligatoria'),
      }),
      region: useField({
        value: '',
        validates: notEmpty('La regione è obbligatoria'),
      }),
      city: useField({
        value: '',
        validates: notEmpty('La città è obbligatoria'),
      }),
      address: useField({
        value: '',
        validates: notEmpty("L'indirizzo è obbligatorio"),
      }),
      file: useField([] as ClaimFile[]),
      imageReport: useField<ClaimFile | null>(null),
      description: useField({
        value: '',
        validates: notEmpty('La descrizione del sinistro è obbligatoria'),
      }),
    },
    onSubmit: async (fieldValues) => {
      try {
        const bankAccount: BankAccount = {
          name: fieldValues.accountHolder,
          iban: fieldValues.IBAN,
        };

        const claimAddress: ClaimAddress = {
          line: fieldValues.address,
          city: fieldValues.city,
          province: fieldValues.region,
          zip: '-----',
          country: 'Italia',
        };

        let policyID = '';

        if (selectedPolicy && selectedPolicy._id) {
          policyID = selectedPolicy._id;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL_TEST ? process.env.REACT_APP_API_URL_TEST : '/api'}/user/claims`,
          {
            order_id: policyID,
            bank_account: bankAccount,
            address: claimAddress,
            date_claim: fieldValues.claimDate,
            description: fieldValues.description,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );

        const data = response.data;

        if (data.status === 'success') {
          const claimId = data.data._id;

          if (fieldValues.file.length > 0) {
            for (const claimFile of fieldValues.file) {
              await uploadFile(claimId, claimFile.file, claimFile.file_type);
            }
          }

          if (fieldValues.imageReport) {
            await uploadFile(claimId, fieldValues.imageReport.file, fieldValues.imageReport.file_type);
          }

          if (audioFile) {
            await uploadFile(claimId, audioFile, 'audio');
          }

          setStep(8);
          return { status: 'success' };
        } else {
          setErrorBannerVisible(true);
          return {
            status: 'fail',
            errors: [
              {
                message: "Errore durante l'invio della richiesta",
              },
            ],
          };
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        setErrorBannerVisible(true);
        return {
          status: 'fail',
          errors: [
            {
              message: "Errore durante l'invio della richiesta",
            },
          ],
        };
      }
    },
  });

  const handleValidation = useCallback(
    (currentStep: number) => {
      let errorFlag = false;

      switch (currentStep) {
        case 0:
          if (selectedPolicy === null) errorFlag = true;
          break;
        case 1:
          if (accountHolder.runValidation() !== undefined) errorFlag = true;
          if (IBAN.runValidation() !== undefined) errorFlag = true;
          break;
        case 2:
          if (claimDate.runValidation() !== undefined) {
            errorFlag = true;
            setValidatesBannerVisible(true);
          }
          break;
        case 3:
          if (address.runValidation() !== undefined) errorFlag = true;
          if (region.runValidation() !== undefined) errorFlag = true;
          if (city.runValidation() !== undefined) errorFlag = true;
          break;
        case 4:
          if (description.runValidation() !== undefined) errorFlag = true;
          break;
        case 6:
          if (imageReport.value === null && !proceedWithoutImageReport) {
            setMissingReportVisible(true);
            errorFlag = true;
          }
          break;
      }

      return !errorFlag;
    },
    [step, selectedPolicy, accountHolder, IBAN, claimDate, address, city, region, description, imageReport, proceedWithoutImageReport, setMissingReportVisible],
  );

  const handleStepClick = (clickedStep: number) => {
    if (clickedStep === step) {
      return;
    }

    let validationPassed = true;

    for (let i = step; i < clickedStep; i++) {
      if (!handleValidation(i)) {
        validationPassed = false;
        break;
      }
    }

    if (validationPassed) {
      setStep(clickedStep);
    } else {
      setValidatesBannerVisible(true);
    }
  };

  /* Stepper */
  const renderStepper = (step: number) => {
    return (
      <div className={`${styles.NewClaimStepper} ${(step === 8 || step === 9) && styles.hidden}`}>
        <div className={`${styles.NewClaimStepper__step} ${step === 0 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(0)} />
        <div className={`${styles.NewClaimStepper__step} ${step === 1 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(1)} />
        <div className={`${styles.NewClaimStepper__step} ${step === 2 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(2)} />
        <div className={`${styles.NewClaimStepper__step} ${step === 3 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(3)} />
        <div className={`${styles.NewClaimStepper__step} ${step === 4 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(4)} />
        <div className={`${styles.NewClaimStepper__step} ${step === 5 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(5)} />
        <div className={`${styles.NewClaimStepper__step} ${step === 6 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(6)} />
        <div className={`${styles.NewClaimStepper__step} ${step === 7 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(7)} />
        <div className={`${styles.NewClaimStepper__step} ${step === 8 && styles['NewClaimStepper__step--active']}`} onClick={() => handleStepClick(8)} />
      </div>
    );
  };

  const renderHeader = (step: number) => {
    switch (step) {
      case 0:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>Per quale polizza vuoi aprire il sinistro?</h1>
            <p>Seleziona una delle tue polizze e procedi</p>
          </div>
        );
      case 1:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>Dove vorresti ricevere il risarcimento?</h1>
            <p>Scrivi l’IBAN del conto corrente di interesse</p>
          </div>
        );
      case 2:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>Quando è accaduto l'incidente?</h1>
            <p>Seleziona la data e il giorno dell’incidente</p>
          </div>
        );
      case 3:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>Dov’è avvenuto l’incidente?</h1>
            <p>Dacci qualche informazione in più su dove è avvenuto l'incidente</p>
          </div>
        );
      case 4:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>Raccontaci cos’è successo</h1>
            <p>Puoi scegliere se scrivere o dirci direttamente a voce cosa è successo</p>
          </div>
        );
      case 5:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>Hai documenti / foto dell’accaduto?</h1>
            <p>Per noi è molto importante avere più dettagli possibile dell’evento</p>
          </div>
        );
      case 6:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>Hai fatto denuncia dell’accaduto?</h1>
            <p>Se sì, carica qui il documento dedicato</p>
          </div>
        );
      case 7:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>Conferma il sinistro</h1>
            <p>Controlla le informazioni che hai appena inserito nel form, se corrette premi pure in basso a destra “Conferma sinistro”</p>
          </div>
        );
      case 8:
        return (
          <div>
            <h1 className={styles.NewClaimHeader__title}>
              <span>Sinistro inviato!</span>
            </h1>
            <p className={styles.AlienHeader__subtitle}>
              Abbiamo preso in carico il tuo sinistro.
              <br />
              il consulente verificherà tutte le informazione e ti avviseremo sugli avanzamenti della procedura
            </p>
          </div>
        );
      default:
        return (
          <div>
            <h1 className={styles.AlienHeader__title}>Procedura per l'apertura del sinistro</h1>
            <div className={styles.AlienBanner}></div>
          </div>
        );
    }
  };

  /* Render the components */
  const renderComponents = (step: number) => {
    switch (step) {
      case 0:
        return <PolicyChoice handleSelectedPolicyField={handlePolicySelect} />;
      case 1:
        return <PaymentIBAN accountHolderField={accountHolder} IBANField={IBAN} />;
      case 2:
        return <ClaimDate claimDateField={claimDate} />;
      case 3:
        return <ClaimLocation regionField={region} cityField={city} addressField={address} />;
      case 4:
        return <ClaimDescription descriptionField={description} audioUrl={audioUrl} setAudioUrl={setAudioUrl} setAudioFile={setAudioFile} />;
      case 5:
        return <ClaimMediaUpload fileField={file} />;
      case 6:
        return (
          <>
            <ClaimReport imageReportField={imageReport} />
            {missingReportFieldMarkup}
          </>
        );
      case 7:
        return (
          <Preview
            selectedPolicy={selectedPolicy}
            claimDateField={claimDate}
            regionField={region}
            cityField={city}
            addressField={address}
            descriptionField={description}
            fileField={file}
            imageReportField={imageReport}
          />
        );
      case 8:
        return (
          <div className={styles.NewClaimSuccess__gif}>
            <img src="/success.gif" alt="Alien" width={300} height={300} />
          </div>
        );
    }
  };

  const handlePolicySelect = (policy: any) => {
    setSelectedPolicy(policy);
  };

  useEffect(() => {
    if (selectedPolicy) {
      handleNextStep();
    }
  }, [selectedPolicy]);

  /* Handle next step */
  const handleNextStep = () => {
    if (handleValidation(step)) {
      setStep((step) => step + 1);
    }
  };

  const handleBackStep = useCallback(() => {
    setStep((step) => {
      if (step > 0) {
        return step - 1;
      } else {
        window.location.href = '/claims';
        return 0;
      }
    });
  }, []);

  /* Handle footer */
  const renderFooter = (step: number) => {
    switch (step) {
      case 0:
        return (
          <LegacyStack>
            <LegacyStack.Item>
              <Button size="large" primaryPrev onClick={handleBackStep}>
                Indietro
              </Button>
            </LegacyStack.Item>
            <LegacyStack.Item fill />
          </LegacyStack>
        );
      case 6:
        return (
          <LegacyStack>
            <LegacyStack.Item>
              {step > 0 && (
                <Button size="large" primaryPrev onClick={handleBackStep}>
                  Indietro
                </Button>
              )}
            </LegacyStack.Item>
            <LegacyStack.Item fill />
            <LegacyStack.Item>
              <Button primary size="large" onClick={handleNextStep}>
                Avanti per la Preview
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        );
      case 7:
        return (
          <LegacyStack>
            <LegacyStack.Item>
              {step > 0 && (
                <Button size="large" primaryPrev onClick={handleBackStep}>
                  Indietro
                </Button>
              )}
            </LegacyStack.Item>
            <LegacyStack.Item fill />
            <LegacyStack.Item>
              <Button size="large" primary onClick={submit}>
                Conferma sinistro
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        );
      case 8:
        return (
          <LegacyStack distribution="center">
            <LegacyStack.Item>
              <Button size="large" primary url="/">
                Torna alla home
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        );
      default:
        return (
          <LegacyStack>
            <LegacyStack.Item fill>
              <Button size="large" primaryPrev onClick={handleBackStep}>
                Indietro
              </Button>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Button size="large" primary onClick={handleNextStep}>
                Avanti
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        );
    }
  };

  /* Instantiate error banner */
  const errorBannerMarkup = errorBannerVisible && (
    <Banner title="Ops! Qualcosa è andato storto." status="warning" onDismiss={() => setErrorBannerVisible(false)}>
      <p>
        Ci dispiace, ma sembra che ci sia un problema tecnico al momento. Il nostro team è al lavoro per risolverlo il prima possibile. Ti preghiamo di
        riprovare più tardi.
      </p>
    </Banner>
  );

  // /* Handle missing fields */
  // const missingFieldsBannerMarkup = submitErrors.length > 0 && (
  //   <Banner title="Campi obbligatori mancanti" status="warning" onDismiss={() => setErrorBannerVisible(false)}>
  //     <List type="bullet" spacing="extraTight">
  //       {submitErrors.map(({ message }, index) => {
  //         return <List.Item key={`${message}${index}`}>{message}</List.Item>;
  //       })}
  //     </List>
  //   </Banner>
  // );

  /* Handle missing report field */
  const missingReportFieldMarkup = missingReportVisible && (
    <>
      <div className={styles.missingFieldsBannerMarkup}>
        <div className={styles.missingFieldsBox}>
          <h1 className={styles.NewClaimHeader__title}>Attenzione!</h1>
          <p>Vuoi procedere senza caricare il documento di denuncia?</p>

          <LegacyStack>
            <LegacyStack.Item fill>
              <Button size="large" primaryPrev onClick={() => setMissingReportVisible(false)}>
                No, meglio caricarlo
              </Button>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <Button
                size="large"
                primary
                onClick={() => {
                  setMissingReportVisible(false);
                  setProceedWithoutImageReport(true);
                  handleNextStep;
                }}
              >
                Si, voglio procedere
              </Button>
            </LegacyStack.Item>
          </LegacyStack>
        </div>
      </div>
    </>
  );

  /* Instantiate warning validates banner */
  const validatesBannerMarkup = validatesBannerVisible && (
    <Banner title="Ops! Campi mancanti." status="warning" onDismiss={() => setValidatesBannerVisible(false)}>
      <p>Compila tutti i campi prima di passare ad uno step successivo</p>
    </Banner>
  );

  /* Rendering the page */
  return (
    <div className="AppContainer">
      {/* Content */}
      <Frame
        topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
        offset="2rem"
      >
        <div className={styles.NewClaim}>
          <Form onSubmit={submit}>
            <div className={styles.NewClaimContainer}>
              <div className={styles.NewClaimContent}>
                <div className={styles.NewClaimHeader}>{renderHeader(step)}</div>
                <div className={styles.AlienBanner}>
                  {errorBannerMarkup} {validatesBannerMarkup}
                </div>
                <div className={styles.NewClaimContentBody}>{renderComponents(step)}</div>
              </div>
            </div>
            <div className={styles.NewClaimStepper}>{renderStepper(step)}</div>
            <div className={styles.NewClaimFooter}>{renderFooter(step)}</div>
          </Form>
        </div>
      </Frame>
    </div>
  );
}
