import React, { useState, useCallback } from 'react';
import { Button, DropZone, VerticalStack, Banner, Select, Spinner } from '../../../../imb-react';
import axios from 'axios';
import styles from '../../Account.module.scss';

type UploadDocumentProps = {
  onUploadComplete: () => void;
  onCancel: () => void;
};

export enum FileTypeEnum {
  Identity = 'identity',
  Passport = 'passport',
  DriverLicense = 'driver_license',
  Other = 'other',
}

const fileTypeChoices = [
  { label: "Carta d'Identità", value: FileTypeEnum.Identity },
  { label: 'Passaporto', value: FileTypeEnum.Passport },
  { label: 'Patente', value: FileTypeEnum.DriverLicense },
  { label: 'Altro', value: FileTypeEnum.Other },
];

export function UploadDocument({ onUploadComplete, onCancel }: UploadDocumentProps) {
  const [file, setFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<FileTypeEnum>(FileTypeEnum.Identity);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);

  const handleFileTypeChange = (value: string) => {
    setFileType(value as FileTypeEnum);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('document', file);
    formData.append('file_type', fileType);

    try {
      setIsLoading(true);
      setError(null);

      const response = await axios.put((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/user/account/file', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === 'success') {
        onUploadComplete();
        onCancel(); // Chiama onCancel una volta completato l'upload
      } else {
        setError('Upload fallito. Riprova.');
      }
    } catch (error) {
      setError("Errore durante l'upload. Riprova.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.uploadDocumentContainer}>
      <div className={styles.uploadDocument}>
        <div className={styles.PersonalDataCardHeader}>Inserisci documento:</div>
        {error && (
          <Banner title="Errore" status="critical" onDismiss={() => setError(null)}>
            <p>{error}</p>
          </Banner>
        )}
        <VerticalStack>
          {!isLoading && (
            <div style={{ marginBottom: '10px' }}>
              <Select options={fileTypeChoices} onChange={handleFileTypeChange} value={fileType} label={undefined} />
            </div>
          )}

          {!file && !isLoading && (
            <DropZone onDrop={handleDrop} accept="image/*">
              <DropZone.FileUpload />
            </DropZone>
          )}
          {file && !isLoading && (
            <div className={styles.fileContainer}>
              <div className={styles.singleFile}>
                <span>{file.name}</span>
                <div onClick={handleRemoveFile}>
                  <img src="/icon_x.svg" alt="x" />
                </div>
              </div>
            </div>
          )}
          {isLoading && (
            <div className={styles.loadingContainer}>
              <Spinner size="large" /> <div>Caricamento in corso...</div>
            </div>
          )}
          <div className={styles.buttonContainer} style={{ border: 'none' }}>
            {file && !isLoading && (
              <Button primary onClick={handleUpload}>
                Salva
              </Button>
            )}
            <Button onClick={onCancel} disabled={isLoading}>
              Annulla
            </Button>
          </div>
        </VerticalStack>
      </div>
    </div>
  );
}
