import styles from './../ReportModal.module.scss';

export function PolicyAssistance() {
  return (
    <>
      <div className={styles.ReportModalBody__title}>Di cosa si tratta?</div>
      <div className={styles.ReportModalBody__text}>Lorem ipsum</div>
      {/* Cover */}
      <div className={styles.ReportModalBody__title}>Che cosa copre?</div>
      <div className={styles.ReportModalBody__list}>Lorem ipsum</div>
    </>
  );
}
