import { useCallback, useEffect, useRef, useState } from 'react';
import { Banner, Frame, Layout, Page, UnstyledLink } from '../../imb-react';
import { TopBarMarkup, NavigationMarkup } from '../../components';
import styles from './Claims.module.scss';
import { useUser } from '../../utils/PrivateRoute';
import { useParams } from 'react-router-dom';
import { ClaimActive } from './components/ClaimActive';
import { ClaimHistory } from './components/ClaimHistory';
import { classNames } from '../../imb-react/utilities/css';
import { Starter } from './components/StartNewClaim';

export function Claims() {
  const { user } = useUser();
  const params = useParams();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);
  const [printError, setPrintError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Render account content based on the current route
   */
  const renderClaimContent = () => {
    switch (params.id) {
      case 'newClaim':
        return <Starter />;
      case 'claimActive':
        return <ClaimActive />;
      case 'claimHistory':
        return <ClaimHistory />;
      default:
        return <Starter />;
    }
  };

  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  const printBannerMarkup = printError && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore" status="critical" onDismiss={() => setPrintError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  const pageMarkup = (
    <Page fullWidth>
      {bannerMarkup}
      {printBannerMarkup}

      {/* Claim Header*/}
      <div className={styles.ClaimsHeader}>
        <div className={styles.ClaimsHeader__title}>I tuoi sinistri</div>
      </div>

      {/* Claim navigation */}
      <div className={styles.ClaimNavigation}>
        <div className={classNames(styles.ClaimNavigationBox, (params.id === 'newClaim' || params.id === undefined) && styles.active)}>
          <UnstyledLink url="/claims/newClaim" className={styles.ClaimNavigationBox__link}>
            Apri Sinistro
          </UnstyledLink>
        </div>
        <div className={classNames(styles.ClaimNavigationBox, params.id === 'claimActive' && styles.active)}>
          <UnstyledLink url="/claims/claimActive" className={styles.ClaimNavigationBox__link}>
            Sinistri attivi
          </UnstyledLink>
        </div>
        <div className={classNames(styles.ClaimNavigationBox, params.id === 'claimHistory' && styles.active)}>
          <UnstyledLink url="/claims/claimHistory" className={styles.ClaimNavigationBox__link}>
            Storico Sinistri
          </UnstyledLink>
        </div>
      </div>

      {/* Claim content */}
      <div className={styles.ClaimContent}>{renderClaimContent()}</div>
    </Page>
  );

  return (
    <div className="AppContainer">
      <Frame
        topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
        navigation={<NavigationMarkup user={user} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
        offset="2rem"
      >
        {pageMarkup}
      </Frame>
    </div>
  );
}
