import React, { useCallback, useRef, useState } from 'react';

import { Banner, Frame, Layout, Page } from '../../imb-react';
import { TopBarMarkup, NavigationMarkup } from '../../components';

import styles from './Consultancies.module.scss';
import { useUser } from '../../utils/PrivateRoute';
import { ConsultantCard } from './components';

export function Consultancies() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);
  const [printError, setPrintError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  // const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Banner markup
   */
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  const printBannerMarkup = printError && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nella stampa del report" status="critical" onDismiss={() => setPrintError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  /**
   * Page markup
   */
  const pageMarkup = (
    <Page fullWidth>
      {/* Banners */}
      {bannerMarkup}
      {printBannerMarkup}

      {/* Header */}
      <div className={styles.ConsultanciesHeader}>
        <div className={styles.ConsultanciesHeader__title}>
          Ciao, {user.name} <br />
          ecco l'area dedicata alle tue consulenze
        </div>
      </div>
      {/* Main */}
      <div className={styles.ConsultanciesGrid}>
        {/* Consultant card */}
        <ConsultantCard />
        {/* Booking calendar */}
        <div>
          <iframe
            src="https://calendly.com/sebastiano-andreis/consulenza-assicurativa-ilmiobrokerassicurativo"
            width="100%"
            height="100%"
            scrolling="yes"
            style={{ border: '1px solid #2e455c', borderRadius: '30px' }}
            className={styles.Consultancies__booking}
          ></iframe>
        </div>
      </div>

      {/* Report & wallet */}
      {/* <div className={styles.ConsultanciesGrid} data-layout="2,2"> */}
      {/* Report */}
      {/* <BookingsList bookings={[]} handleModal={handleNewBookingModalChange} /> */}
      {/* </div> */}
    </Page>
  );

  return (
    <div className="AppContainer">
      <Frame
        topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
        navigation={<NavigationMarkup user={user} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
        offset="2rem"
      >
        {/* {loadingMarkup} */}
        {pageMarkup}
        {/* {<NewBookingModal user={user} active={newBookingModalActive} onClose={handleNewBookingModalChange} />} */}
      </Frame>
    </div>
  );
}
