import { useState } from 'react';
import { Checkbox, HorizontalGrid, HorizontalStack, Link, Modal, VerticalStack, Badge } from '../../../../imb-react';
import { Order } from '../../../../types';
import { getPolicyExpirationDate } from '../../../../utils/Common';

import styles from './PolicyModal.module.scss';

type PolicyModalType = {
  /** The product id that should be displayed */
  policy: Order;
  /** The modal status */
  active: boolean;
  /** Handler of the modal closing */
  onClose: () => void;
};

export function PolicyModal({ active, onClose, policy }: PolicyModalType) {
  console.log(policy);
  // Check if the quote is empty
  if (!policy.line_items) {
    return null;
  }

  /**
   * Documents state
   */
  const [checkedDocuments, setCheckedDocuments] = useState({
    informationPackage: true,
    dip: true,
    precontractual: true,
    privacy: true,
    brokerage_mandate: true,
  });

  // Handle documents checkbox change
  const handleDocumentsCheckboxChange = (checked: boolean, id: string) => {
    setCheckedDocuments((checkedDocuments) => ({ ...checkedDocuments, [id]: !checked }));
  };

  /**
   * Render the document link
   * @param {string} document
   */
  const renderDocumentLink = (document: string) => {
    return (
      <Link url={document} imbDarkLow>
        {document}
      </Link>
    );
  };

  const policyId = policy.line_items[0]._id;
  console.log(policyId);

  const handleCheckout = () => {
    //console.log('Azione di rinnovo personalizzata');
    /* reindirizzare l'utente alla pagina di pagamento*/
    const baseCheckoutUrl = 'https://checkout.dev.ilmiobrokerassicurativo.it';
    const checkoutUrl = (process.env.REACT_APP_API_URL ? process.env.REACT_APP_CHECKOUT_URL : baseCheckoutUrl) + `/?orderId=${policyId}`;
    //console.log(checkoutUrl);
    window.location.href = checkoutUrl;
  };

  /**
   * Render policy expiration markup
   * @param {Date} date_end Data di scadenza della polizza
   * @returns {string} Stato della polizza: 'Da rinnovare', 'in scadenza', 'attiva'
   */
  const renderPolicyExpirationMarkup = (date_end: Date): string => {
    // data di scadenza come stringa nel formato "dd/mm/yyyy"
    const expirationDateStr = getPolicyExpirationDate(date_end);

    // Dividi la stringa in giorno, mese e anno
    const parts = expirationDateStr.split('/');
    // Costruisci una nuova stringa nel formato "yyyy-mm-dd"
    const formattedExpirationDateStr = `${parts[2]}-${parts[1]}-${parts[0]}`;

    // Converti la stringa formattata in un oggetto Date
    const expirationDate = new Date(formattedExpirationDateStr);

    // Calcola la data di inizio della finestra di "in scadenza" (15 giorni prima dalla data di scadenza)
    const startDate = new Date(expirationDate);
    startDate.setDate(startDate.getDate() - 15);

    // Ottieni la data corrente
    const currentDate = new Date();

    // Verifica se la polizza è attiva, in scadenza o da rinnovare
    if (currentDate < startDate) {
      // La polizza è attiva perché non sono ancora trascorsi i primi 15 giorni dalla creazione
      return 'attiva';
    } else if (currentDate <= expirationDate) {
      // La polizza è in scadenza perché mancano meno di 15 giorni alla data di scadenza
      return 'in scadenza';
    } else {
      // La polizza è scaduta (da rinnovare)
      return 'Da rinnovare';
    }
  };

  // Determina lo stato della polizza in base alla data di scadenza
  const policyExpirationDate = (date_end: Date): string => {
    const policyStatus = renderPolicyExpirationMarkup(date_end);
    let badgeColorClass;

    switch (policyStatus) {
      case 'Da rinnovare':
        badgeColorClass = styles.redBadge;
        break;
      case 'in scadenza':
        badgeColorClass = styles.yellowBadge;
        break;
      default:
        badgeColorClass = styles.greenBadge;
    }

    return badgeColorClass; // Ritorna la classe del colore del badge
  };

  return (
    <Modal
      large
      open={active}
      onClose={onClose}
      title={'Dettaglio polizza'}
      titleIconSource={''}
      primaryAction={{
        content: 'Rinnova',
        onAction: handleCheckout,
      }}
      primaryActionBold
      buttonSizeLarge
    >
      <Modal.Section>
        <VerticalStack gap="5">
          <HorizontalStack align="space-between" blockAlign="center">
            <div className={styles.PolicyModal__header}>
              <img
                className={styles.PolicyModal__icon}
                src={policy.line_items[0].product.icon && process.env.REACT_APP_BLOB_IMAGES_URL + policy.line_items[0].product.icon.key}
                alt={policy.line_items[0].product.icon && policy.line_items[0].product.icon.title}
              />
              <div className={styles.PolicyModal__title}>{policy.line_items[0].name}</div>
            </div>
            <div className={`${styles.PolicyModalExp} ${policy.date_end && policyExpirationDate(new Date(policy.date_end))}`}>
              {!policy.date_end ? (
                <>
                  <div className={`${styles.PolicyModalExp} ${styles.PolicyModalExp__date} ${styles.yellowBadge}`}>In attesa di emissione</div>
                </>
              ) : policy.date_end && policyExpirationDate(new Date(policy.date_end)) === styles.redBadge ? (
                <>
                  <div className={styles.PolicyModalExp__text}>La polizza è scaduta il:</div>
                  <div className={styles.PolicyModalExp__date}>{getPolicyExpirationDate(new Date(policy.date_end))}</div>
                </>
              ) : (
                <>
                  <div className={styles.PolicyModalExp__text}>La polizza scade il:</div>
                  <div className={styles.PolicyModalExp__date}>{policy.date_end && getPolicyExpirationDate(new Date(policy.date_end))}</div>
                </>
              )}
            </div>
          </HorizontalStack>

          {/* Main content */}
          <HorizontalGrid columns={['twoThirds', 'oneThird']} gap="5">
            <VerticalStack scroll-y="auto">
              {/* Warranties */}
              <div className={styles.PolicyModalCard}>
                <div className={styles.PolicyModalCardBody}>
                  <VerticalStack gap="5">
                    <HorizontalStack>
                      <div className={styles.PolicyModalCard__title}>
                        <div>
                          <h5>Cosa comprende:</h5>
                        </div>
                        <div className={styles.PolicyModalCard__company}>
                          <img
                            src={process.env.REACT_APP_BLOB_IMAGES_URL + policy.line_items[0].company.logo.key}
                            alt={policy.line_items[0].company.logo.title}
                          />
                        </div>
                      </div>
                    </HorizontalStack>

                    {/* Warranty */}
                    <HorizontalStack align="space-between">
                      <div className={styles.PolicyModalCard__warranty}>Tipo di servizio</div>
                      <div className={styles.PolicyModalCard__warranty}>Premio</div>
                      <div className={styles.PolicyModalCard__warranty__body}>
                        {policy.line_items[0].warranties.map((warranty: { name: string; value: string }, index: number) => (
                          <div className={styles.PolicyModalCard__warranty__box} key={index}>
                            <div className={styles.PolicyModalCard__warranty__name}>{warranty.name}</div>
                            <div className={styles.PolicyModalCard__warranty_amount}>{warranty.value} €</div>
                          </div>
                        ))}
                      </div>
                    </HorizontalStack>
                  </VerticalStack>
                </div>
              </div>

              {/* Documents */}
              <div className={styles.PolicyModalCard}>
                <div className={styles.PolicyModalCardBody}>
                  <div className={styles.PolicyModalCard__title}>
                    <h5 style={{ marginBottom: '16px' }}>Allegati</h5>
                  </div>
                  <VerticalStack>
                    {/* Warranties */}
                    <HorizontalStack align="space-between">
                      {policy.line_items[0].files.map((file: any) => (
                        <a
                          key={file.key}
                          className={styles.PolicyModalCard__warranty__name}
                          target="__blank"
                          href={process.env.REACT_APP_STORAGE_PUBLIC_DOCUMENTS ? process.env.REACT_APP_STORAGE_PUBLIC_DOCUMENTS + file.key : '#'}
                        >
                          {file.title}
                        </a>
                      ))}
                    </HorizontalStack>
                  </VerticalStack>
                </div>
              </div>
            </VerticalStack>

            <VerticalStack gap="5">
              {/* Pricing */}
              <div className={styles.PolicyModalPricing}>
                <p>Premio</p>
                <p className={styles.PolicyModalPricing__amount}>{policy.total_price.toFixed(2)} €/anno</p>
              </div>

              {/* Documents */}
              <div className={styles.PolicyModalDocuments}>
                <div className={styles.PolicyModalDocuments__title}>Documenti da visionare</div>
                <div className={styles.PolicyModalDocuments__list}>
                  <Checkbox
                    disabled
                    checked={checkedDocuments.informationPackage}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.informationPackage, 'informationPackage')}
                    imbGreenBorder
                    label={renderDocumentLink('Fascicolo informativo')}
                  />
                  <Checkbox
                    disabled
                    checked={checkedDocuments.dip}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.dip, 'dip')}
                    imbGreenBorder
                    label={renderDocumentLink('DIP')}
                  />
                  <Checkbox
                    disabled
                    checked={checkedDocuments.precontractual}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.precontractual, 'precontractual')}
                    imbGreenBorder
                    label={renderDocumentLink('Informativa precontrattuale')}
                  />
                  <Checkbox
                    disabled
                    checked={checkedDocuments.privacy}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.privacy, 'privacy')}
                    imbGreenBorder
                    label={renderDocumentLink('Privacy')}
                  />
                  <Checkbox
                    disabled
                    checked={checkedDocuments.brokerage_mandate}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.brokerage_mandate, 'brokerage_mandate')}
                    imbGreenBorder
                    label={renderDocumentLink('Mandato di brokeraggio')}
                  />
                </div>
              </div>
              {/* discount codes */}
              {/* <VerticalStack>
                <div className={styles.PolicyModalDiscountCodes}>
                  <div className={styles.PolicyModalDiscountCodes_box}>
                    <p className={styles.PolicyModalDiscountCodes__text}>Codice sconto in uso</p>
                    <div className={styles.PolicyModalCodeinUse__code}>sadsfdsdfs</div>
                  </div>
                  <div className={styles.PolicyModalDiscountCodes_box}>
                    <p className={styles.PolicyModalDiscountCodes__text}>Ottieni 5€ di sconto</p>
                    <div className={styles.PolicyModalFriendCode__code}>
                      <Button outline>Codice Amico</Button>
                    </div>
                  </div>
                </div>
              </VerticalStack> */}
            </VerticalStack>
          </HorizontalGrid>
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
}
