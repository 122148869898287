import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form, FormLayout, TextField, InlineError, Link, Icon } from '../../imb-react';
import { HideMinor, ViewMinor } from '@shopify/polaris-icons';

import './Login.css';
import logo from '../../logo_bw.svg';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { app } from '../../utils/firebase';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  getIdToken,
  signInWithPopup,
} from 'firebase/auth';

const HideIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M11.977 4.751a7.598 7.598 0 0 0-1.977-.251c-2.444 0-4.196 1.045-5.325 2.233a7.188 7.188 0 0 0-1.243 1.773c-.26.532-.432 1.076-.432 1.494 0 .418.171.962.432 1.493.172.354.4.734.687 1.116l1.074-1.074a5.388 5.388 0 0 1-.414-.7c-.221-.453-.279-.753-.279-.835 0-.082.058-.382.279-.835a5.71 5.71 0 0 1 .983-1.398c.89-.937 2.264-1.767 4.238-1.767.24 0 .471.012.693.036l1.284-1.285Z"/><path fill-rule="evenodd" d="M4.25 14.6a.75.75 0 0 0 1.067 1.053l1.062-1.061c.975.543 2.177.908 3.621.908 2.45 0 4.142-1.05 5.24-2.242 1.078-1.17 1.588-2.476 1.738-3.076a.749.749 0 0 0 0-.364c-.15-.6-.66-1.906-1.738-3.076a7.245 7.245 0 0 0-.51-.502l.923-.923a.75.75 0 0 0-1.053-1.068l-.008.008-10.335 10.336-.008.007Zm5.75-.6c-.978 0-1.809-.204-2.506-.523l1.108-1.109a2.75 2.75 0 0 0 3.766-3.766l1.3-1.299c.169.147.325.3.469.455a6.387 6.387 0 0 1 1.332 2.242 6.387 6.387 0 0 1-1.332 2.242c-.86.933-2.17 1.758-4.137 1.758Zm0-2.75c-.087 0-.172-.01-.254-.026l1.478-1.478a1.25 1.25 0 0 1-1.224 1.504Z"/></svg>';

const ShowIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-1.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/><path fill-rule="evenodd" d="M10 4c-2.476 0-4.348 1.23-5.577 2.532a9.266 9.266 0 0 0-1.4 1.922 5.98 5.98 0 0 0-.37.818c-.082.227-.153.488-.153.728s.071.501.152.728c.088.246.213.524.371.818.317.587.784 1.27 1.4 1.922 1.229 1.302 3.1 2.532 5.577 2.532 2.476 0 4.348-1.23 5.577-2.532a9.265 9.265 0 0 0 1.4-1.922 5.98 5.98 0 0 0 .37-.818c.082-.227.153-.488.153-.728s-.071-.501-.152-.728a5.984 5.984 0 0 0-.371-.818 9.269 9.269 0 0 0-1.4-1.922c-1.229-1.302-3.1-2.532-5.577-2.532Zm-5.999 6.002v-.004c.004-.02.017-.09.064-.223a4.5 4.5 0 0 1 .278-.608 7.768 7.768 0 0 1 1.17-1.605c1.042-1.104 2.545-2.062 4.487-2.062 1.942 0 3.445.958 4.486 2.062a7.77 7.77 0 0 1 1.17 1.605c.13.24.221.447.279.608.047.132.06.203.064.223v.004c-.004.02-.017.09-.064.223a4.503 4.503 0 0 1-.278.608 7.768 7.768 0 0 1-1.17 1.605c-1.042 1.104-2.545 2.062-4.487 2.062-1.942 0-3.445-.958-4.486-2.062a7.766 7.766 0 0 1-1.17-1.605 4.5 4.5 0 0 1-.279-.608c-.047-.132-.06-.203-.064-.223Z"/></svg>';

export function LoginPage() {
  const navigate = useNavigate();
  const auth = getAuth(app);
  auth.useDeviceLanguage();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoading(true);
        getIdToken(user)
          .then((token) => {
            const checkToken = 'Bearer ' + token;
            axios
              .post(
                (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/firebase-login',
                { authorization: checkToken },
                {
                  timeout: 5000,
                },
              )
              .then((response) => {
                if (response.data.status === 'success') {
                  // Save bearer token into axios
                  localStorage.setItem('mb__access_token', response.data.data.token);

                  // Redirect
                  navigate('/');
                }
              })
              .catch((error) => {
                const axiosError = error as AxiosError;
                console.log(axiosError);
                const status = axiosError.response?.status || 500;
                if (status === 404) {
                  setLoginError(true);
                } else {
                  setError(true);
                }
              });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  }, []);

  const handleGoogleLogin = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    await signInWithPopup(auth, provider);
  }, []);

  const handleMicrosoftLogin = useCallback(async () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('email');
    await signInWithRedirect(auth, provider);
  }, []);

  const handleAppleLogin = useCallback(async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    await signInWithRedirect(auth, provider);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoginError(false);
      setError(false);

      const req = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/login',
        { email, password },
        {
          timeout: 5000,
        },
      );
      const response = req.data;

      if (response.status === 'success') {
        // Save bearer token into axios
        localStorage.setItem('mb__access_token', response.data.token);

        // Redirect
        navigate('/');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;
      if (status === 404) {
        setLoginError(true);
      } else {
        setError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [email, history, password]);

  const handleEmailChange = useCallback((value: any) => setEmail(value), []);
  const handlePwdChange = useCallback((value: any) => setPassword(value), []);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);
  const togglePasswordConfirmationVisibility = () => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
  return (
    <div className="page-main">
      <div className="page-content">
        <div className="login-card ">
          <header className="login-card__header">
            <h1 className="login-card__logo">
              <a href="/">
                <img src={logo} />
              </a>
            </h1>
          </header>

          <div className="login-card__content">
            <div className="login-card__hero">
              <img src="/login_hero.png" />
            </div>
            <div className="main-card-section">
              <h2 className="ui-heading">Login</h2>
              <h3 className="ui-subheading ui-subheading--subdued">Ciao! Entra nella tua area dedicata, dove l'assicurazione si fa semplice</h3>
              <div className="firebaseui-container">
                <div className="firebaseui-method-content">
                  <img src="/icon_google.svg" onClick={handleGoogleLogin} />
                </div>
              </div>
              <div className="login-card__divider">
                <span className="login-card__divider--line" />
                <span>oppure</span>
                <span className="login-card__divider--line" />
              </div>
              <div>
                <Form onSubmit={handleSubmit}>
                  <FormLayout>
                    <TextField
                      autoComplete="on"
                      value={email}
                      onChange={handleEmailChange}
                      label="Email"
                      type="email"
                      placeholder="Scrivi il tuo indirizzo email..."
                    />
                    <TextField
                      autoComplete="on"
                      value={password}
                      onChange={handlePwdChange}
                      label="Password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      placeholder="Inserisci la tua password..."
                      labelAction={{ content: 'Password dimenticata?', url: '/forgot-password' }}
                      suffix={
                        <div onClick={togglePasswordVisibility}>
                          <Icon source={isPasswordVisible ? HideIcon : ShowIcon} />
                        </div>
                      }
                    />
                    {loginError === true ? <InlineError message="Controllare i dati di accesso." fieldID="myFieldID" /> : ''}
                    {error === true ? <InlineError message="Qualcosa è andato storto. Riprovare più tardi." fieldID="myFieldID" /> : ''}
                    <div className="login-card__button">
                      <Button primary loading={isLoading} size="large" submit>
                        ENTRA
                      </Button>
                      <div className="login-card__button__text">
                        <p>Non hai un account?</p>
                        <p>
                          <Link url="/register" removeUnderline>
                            Registrati
                          </Link>
                        </p>
                      </div>
                    </div>
                  </FormLayout>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <footer className="login-footer">
          <a className="login-footer__link" target="_blank" href="#" title="Centro di assistenza">
            Richiesta di supporto
          </a>
          <a
            className="login-footer__link"
            target="_blank"
            href="https://nano-insurance.com/legal/ilmiobrokerassicurativo/privacy-policy"
            title="Privacy Policy"
          >
            Privacy
          </a>
          <a
            className="login-footer__link"
            target="_blank"
            href="https://nano-insurance.com/legal/ilmiobrokerassicurativo/termini-e-condizioni"
            title="Termini di servizio"
          >
            Termini
          </a>
        </footer>
      </div>
    </div>
  );
}
