import { Link } from 'react-router-dom';
import styles from '../../Claims.module.scss';
import { Button } from '../../../../imb-react';

export function Starter() {
  return (
    <>
      <div className={styles.ClaimsBody}>
        <div className={styles.ClaimsEmpty__box}>
          <h5>Apri Sinistro</h5>
          <p>Premi il pulsante qui sotto per avviare il processo di apertura del sinistro.</p>
          <Link to="/StartNewClaim">
            <Button primary sizeLarge>
              Nuovo Sinistro
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
