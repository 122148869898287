import { useCallback } from 'react';
import { FormLayout, DropZone, VerticalStack } from '../../../../imb-react';
import styles from '../StartNewClaim/StartNewClaim.module.scss';
import { classNames } from '../../../../imb-react/utilities/css';
import { ClaimFile } from './StartNewClaim';

type ClaimReportProps = {
  imageReportField: {
    value: ClaimFile | null;
    onChange: (file: ClaimFile | null) => void;
  };
};

export function ClaimReport({ imageReportField }: ClaimReportProps) {
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const claimFile: ClaimFile = {
        key: file.name,
        title: file.name,
        file_type: 'image',
        file: file,
      };
      imageReportField.onChange(claimFile);
    },
    [imageReportField],
  );

  const handleRemoveFile = useCallback(() => {
    imageReportField.onChange(null);
  }, [imageReportField]);

  return (
    <div className={classNames(styles.Claims_form_maxWidth)}>
      <FormLayout.Group>
        <VerticalStack>
          {!imageReportField.value && (
            <DropZone label="Carica qua un documento" onDrop={handleDrop} accept="image/*" allowMultiple={false}>
              <DropZone.FileUpload />
            </DropZone>
          )}

          {imageReportField.value && (
            <div className={styles.fileContainer}>
              <div className={styles.singleFile} style={{ display: 'flex', alignItems: 'center' }}>
                <span>{imageReportField.value.title}</span>
                <div onClick={handleRemoveFile} style={{ cursor: 'pointer', marginLeft: '10px' }}>
                  <img src="/icon_x.svg" alt="Rimuovi" />
                </div>
              </div>
            </div>
          )}
        </VerticalStack>
      </FormLayout.Group>
    </div>
  );
}
