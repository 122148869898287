import { ChevronRightMinor } from '@shopify/polaris-icons';
import { Button } from '../../../../imb-react';
import styles from './Report.module.scss';
import dashboardStyles from '../../Dashboard.module.scss';

import products, { ProductsReportType } from './productsReport';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Products } from '../../../Products';

type ReportType = {
  /** Handler of the modal visibility */
  handleOpenReportModal: (item: ProductsReportType) => void;
  // /** User */
  // user: any;
};

export function Report({ handleOpenReportModal }: ReportType) {
  // TODO: Remove this when the API will be ready
  // Pick 3 random products
  // const randomProducts = products.sort(() => Math.random() - Math.random()).slice(0, 3);
  const [items, setItems] = useState<ProductsReportType>({ label: '', type: '' });

  /**
   * Fetch alien results from the API
   */
  useEffect(() => {
    const fetchAlienResults = async () => {
      try {
        const response = await axios.get(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : process.env.REACT_APP_ALIEN_API_URL ? process.env.REACT_APP_ALIEN_API_URL : '/api') +
            '/risks/individual',
          {
            // headers: {
            //   Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            // },
          },
        );
        const data = response.data;

        if (data.status === 'success') {
          // Iterate through the data and if the id is present in the products array, add it to the items array
          const items: any = {};
          data.data.forEach((item: any) => {
            const productLabel = products.find((product) => product.label === item.label);
            const productType = products.find((product) => product.type === item.type);
            if (productType && productLabel) {
              items.label = productLabel;
              items.type = productType;
            }
          });
          console.log(items);
          setItems(items);
        }
      } catch (error) {
        console.log(error);
        // setIsLoading(false);
      }
    };
    fetchAlienResults();
  }, []);

  /**
   * Empty markup
   */
  const emptyMarkup = (
    <div className={styles.ReccomendPoliciesBody}>
      <div className={styles.ReccomendPoliciesEmpty__box}>
        <div className={styles.ReportHeaderActions}>
          <Button uppercase>Cerca una soluzione</Button>
        </div>
      </div>
    </div>
  );

  const lastReportResultMarkup = (
    <div>
      <p className={dashboardStyles.DashboardItemHeader__title}>Le soluzioni consigliate per te</p>

      <h1 className={styles.ReportBody__item__header}>{items.label}</h1>
      {/* <div className={styles.ReccomendPoliciesBox__text}>
        a partire da
        <div className={styles.ReccomendPoliciesBox__price}> 100 € /anno </div>
      </div> */}
      <div className={styles.ReccomendPoliciesBox__button}>
        <Button plain plainColorBlue bold icon={ChevronRightMinor} onClick={() => handleOpenReportModal(items)}>
          Scopri di più
        </Button>
      </div>
      <div className={styles.ReportHeaderActions}>
        <Button uppercase>Cerca nuove soluzioni</Button>
      </div>
    </div>
  );

  return <div>{items.type ? lastReportResultMarkup : emptyMarkup}</div>;
}
