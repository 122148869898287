import styles from './ConsultantCard.module.scss';
// import { Button } from '../../../../imb-react';

export function ConsultantCard() {
  return (
    <div>
      <div className={styles.ConsultantCard}>
        {/* header */}
        <div className={styles.ConsultantCardHeader}>
          <div className={styles.ConsultantCardHeader__photo}>
            <img src="/consultant.svg" alt="" />
          </div>
          <div className={styles.ConsultantCardHeader__title}>
            <h5>Sebastiano Andreis</h5>
          </div>
        </div>
        {/* contactInfo */}
        <div className={styles.ConsultantCardDescription}>
          <h6>Informazioni generali</h6>
          <div className={styles.ConsultantCardInfo}>
            <div>
              <img src="/icon_linkedin.svg" alt="" />
              <img src="/icon_mail.svg" alt="" />
              <img src="/icon_rui.svg" alt="" />
            </div>
            <div className={styles.ConsultantCardInfoText}>
              <a href="https://www.linkedin.com/in/%F0%9F%9A%80sebastiano-andreis-214688189/">Profilo LinkedIn</a>
              <a href="mailto:consulente@imba.it">consulente@imba.it</a>
              <span>Numero di iscrizione al RUI: B000697788C</span>
            </div>
          </div>
        </div>
        {/* description */}
        <div className={styles.ConsultantCardDescription}>
          <h6>Riguardo il tuo consulente</h6>
          <p>
            Sono un consulente assicurativo esperto, dedicato a fornire consulenze personalizzate e soluzioni assicurative su misura per le esigenze uniche dei
            miei clienti.
          </p>
        </div>
        {/* cta */}
        {/* <div className={styles.ConsultantCardCta}>
          <Button primary sizeLarge url="https://outlook.office365.com/book/PartiamodallaCyberilmiobrokerassicurativoit@nano-insurance.com/">
            Richiedi consulenza
          </Button>
        </div> */}
      </div>
    </div>
  );
}
