import React, { useCallback, useEffect, useState, useRef } from 'react';
import axios, { AxiosError } from 'axios';
import { Policies } from '../../../Dashboard/components';
import styles from './ClaimDetailsCard.module.scss';
import { Button, DropZone, TextField, DatePicker } from '../../../../imb-react';
import { classNames } from '../../../../imb-react/utilities/css';
import saveAs from 'file-saver';

type ClaimFile = {
  key: string;
  title: string;
  file_type: 'audio' | 'image' | 'other';
  file: File;
};

export function ClaimDetailsCard({ claims, onUpdate, disableEditing }: any) {
  const [expandedClaims, setExpandedClaims] = useState<Record<number, boolean>>({});
  const [editClaimIndex, setEditClaimIndex] = useState<number | null>(null);
  const [audioUrls, setAudioUrls] = useState<Record<number, string | null>>({});
  const [isPlaying, setIsPlaying] = useState<Record<number, boolean>>({});
  const [currentTime, setCurrentTime] = useState<Record<number, number>>({});
  const [duration, setDuration] = useState<Record<number, number>>({});
  const audioRefs = useRef<Record<number, HTMLAudioElement | null>>({});
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const [files, setFiles] = useState<ClaimFile[]>([]);
  const [editData, setEditData] = useState<Record<string, any>>({});
  const [visibleDatePickers, setVisibleDatePickers] = useState<Record<number, boolean>>({});
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  /**
   *
   * @param dateString
   * @returns date gg-mm-aaaa
   */
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  /**
   *
   * @param time
   * @returns time mm:ss
   */
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${formattedSeconds}`;
  };

  /**
   * Claim service: upload file
   * @param claimId
   * @param file
   * @param fileType
   */
  const uploadFile = async (claimId: string, file: File, fileType: string) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('file_type', fileType);

      const response = await axios.put(`${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api'}/user/claims/${claimId}/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      if (response.status !== 200) {
        throw new Error('Errore nel caricamento del file');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log('Errore durante il caricamento del file:', axiosError);
      throw axiosError;
    }
  };

  /**
   * Claim service: download file
   * @param id
   * @param key
   * @param title
   * @param fileType
   */
  const handleDownloadFile = useCallback(async (id: string, key: string, title: string, fileType: string) => {
    try {
      const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/user/claims/${id}/files/${key}`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;
      if (fileType === 'audio') {
        return URL.createObjectURL(data);
      } else {
        saveAs(data, title);
        return null;
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      return null;
    }
  }, []);

  /**
   * Handle DropZone drop file
   */
  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const updatedFiles: ClaimFile[] = acceptedFiles.map((file) => ({
      key: file.name,
      title: file.name,
      file_type: 'image',
      file: file,
    }));
    setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  }, []);

  /**
   * Handle file removal from DropZone locally
   */
  const handleRemoveFile = (fileKey: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.key !== fileKey));
  };

  /**
   * Handle dropdown states
   * @param index
   */
  const toggleClaim = (index: number) => {
    setExpandedClaims((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  /**
   * @param status
   * @returns progress bar status
   */
  const getStatusIndex = (status: string) => {
    switch (status) {
      case 'draft':
        return 0;
      case 'open':
        return 1;
      case 'rejected':
        return 2;
      case 'closed':
        return 3;
      default:
        return 0;
    }
  };

  /**
   * Handle edit fields before save
   * @param index
   * @param claim
   */
  const handleEdit = (index: number, claim: any) => {
    if (disableEditing) return;
    setEditClaimIndex(index);
    setEditData({
      date_claim: claim.date_claim,
      address: {
        country: 'Italia',
        city: claim.address.city,
        province: claim.address.province,
        zip: claim.address.zip,
        line: claim.address.line,
      },
      description: claim.description,
    });
  };

  //Date picker managing function
  const handleMonthChange = (newMonth: any, newYear: any) => {
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const handleDateChange = (date: any) => {
    setEditData((prevData) => ({
      ...prevData,
      date_claim: date.start,
    }));
  };

  const toggleDatePicker = (index: number) => {
    setVisibleDatePickers((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  /**
   * Reset all the states
   */
  const resetEditing = () => {
    setEditClaimIndex(null);
    setFiles([]);
    setIsRecording(false);
    setAudioChunks([]);
    setRecordingTime(0);
  };

  /**
   * Function that update claim
   * @param claimId
   * @returns
   */
  const handleSave = async (claimId: string) => {
    if (disableEditing) return;
    try {
      for (const file of files) {
        await uploadFile(claimId, file.file, file.file_type);
      }

      let newAudioUrl: any;
      if (audioChunks.length > 0) {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        const audioFile = new File([audioBlob], `recording_${Date.now()}.wav`, { type: 'audio/wav' });
        await uploadFile(claimId, audioFile, 'audio');
        newAudioUrl = URL.createObjectURL(audioBlob);
        setAudioUrls((prev) => ({
          ...prev,
          [editClaimIndex!]: newAudioUrl,
        }));
        setAudioChunks([]);
      }

      await axios.put(`${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api'}/user/claims/${claimId}`, editData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      onUpdate();
      resetEditing();
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      return null;
    }
  };

  // functions for managing audio playback and recording
  const fetchAudioUrl = async (claim: any, index: number) => {
    const audioFile = claim.files.find((file: any) => file.file_type === 'audio');
    if (audioFile) {
      const url = await handleDownloadFile(claim._id, audioFile.key, audioFile.title, audioFile.file_type);
      setAudioUrls((prev) => ({
        ...prev,
        [index]: url,
      }));
    }
  };

  const handlePlayPause = (index: number) => {
    const audioElement = audioRefs.current[index];
    if (audioElement) {
      if (!isPlaying[index]) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
      setIsPlaying((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));

      setCurrentTime((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
      setRecordingTime(0);

      mediaRecorder.ondataavailable = (event) => {
        setAudioChunks((prev) => [...prev, event.data]);
      };

      const interval = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);

      mediaRecorder.onstop = () => {
        clearInterval(interval);
      };
    } catch (error) {
      console.error("Errore durante l'inizializzazione della registrazione:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const deleteRecording = () => {
    setIsRecording(false);
    setAudioChunks([]);
    setRecordingTime(0);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      Object.keys(audioRefs.current).forEach((index) => {
        const audioElement = audioRefs.current[Number(index)];
        if (audioElement && !audioElement.paused) {
          setCurrentTime((prev) => ({
            ...prev,
            [index]: audioElement.currentTime,
          }));
        }
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.ClaimDetailsContainer}>
      <ul>
        {claims.map((claim: any, index: number) => {
          const policyId = claim.order?._id;
          const audioFile = claim.files.find((file: any) => file.file_type === 'audio');
          const documents = claim.files.filter((file: any) => file.file_type !== 'audio');
          const statusIndex = getStatusIndex(claim.status);
          const isEditing = editClaimIndex === index;
          const audioUrl = audioUrls[index];

          useEffect(() => {
            if (audioFile && !audioUrls[index]) {
              fetchAudioUrl(claim, index);
            }
          }, [claim, audioFile, audioUrl]);

          const progressBarWidth = currentTime[index] && duration[index] ? (currentTime[index] / duration[index]) * 100 : 0;

          return (
            <li key={index} className={styles.ClaimDetailsItem}>
              <div className={styles.ClaimDetailsHeader} onClick={() => toggleClaim(index)}>
                <div className={classNames(styles.ClaimDetailsCardTitle, styles.ClaimTitle, statusIndex === 2 && styles.RejectedTitle)}>
                  SINISTRO DEL {formatDate(claim.date_claim)} {statusIndex === 2 && <img src="/icon_warning.svg" alt="warning" />}
                </div>
                <img src={expandedClaims[index] ? '/icon_arrow_up_simple.svg' : '/icon_arrow_down_simple.svg'} alt="toggle" className={styles.icon} />
              </div>
              {expandedClaims[index] && (
                <div className={styles.ClaimDetailsBody}>
                  <div className={styles.ClaimDetailsCard}>
                    <div className={styles.ClaimDetailsCardTitle}>Panoramica Sinistro:</div>
                    <p>
                      Grazie a questo grafico potrai avere un resoconto di tempo reale dello stato del tuo sinistro. Sapere tutti i passaggi dall’apertura alla
                      sua chiusura e su cosa stiamo lavorando
                    </p>
                    {statusIndex === 2 && (
                      <p className={styles.rejectedText}>
                        <img src="/icon_warning.svg" alt="warning" />
                        Purtroppo ci sono stati dei problemi e non siamo potuti andare avanti con il sinistro.
                      </p>
                    )}
                    <div className={styles.CardDetailsStatusGraphic}>
                      <div className={styles.ProgressContainer}>
                        <div className={classNames(styles.ProgressBar, statusIndex >= 0 && styles.active)} />
                        <div className={classNames(styles.ProgressBar, statusIndex === 3 && styles.active)}>
                          <div className={classNames(statusIndex === 1 && styles.SpecialBar, statusIndex === 2 && styles.RejectedBar)} />
                        </div>
                        <div className={classNames(styles.ProgressBar, statusIndex >= 3 && styles.active)} />
                      </div>
                      <div className={styles.ProgressLabels}>
                        <span className={classNames(statusIndex === 0 && styles.activeLabel)}>Apertura sinistro</span>
                        <span className={classNames(statusIndex === 1 && styles.activeLabel, statusIndex === 2 && styles.rejectedLabel)}>
                          Valutazione del danno
                        </span>
                        <span className={classNames(statusIndex === 3 && styles.activeLabel)}>Liquidato</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.ClaimDetailsCard}>
                    <div className={styles.ClaimDetailsCardTitle}>Polizza vigente</div>
                    <div className={styles.PolicyDetailsContainer}>
                      <div>
                        <Policies purpose="view" policyId={policyId} />
                      </div>
                      <div className={classNames(styles.LabelContainer, styles.Policies)}>
                        <div className={styles.LabelBox}>
                          <div className={styles.LabelTitle}>Nome polizza</div>
                          <span>{claim.order.line_items[0].name}</span>
                        </div>
                        <div className={styles.LabelBox}>
                          <div className={styles.LabelTitle}>Fornitore</div>
                          <span>{claim.order.line_items[0].product.company}</span>
                        </div>
                        <div className={styles.LabelBox}>
                          <div className={styles.LabelTitle}>Data di scadenza</div>
                          <span>{formatDate(claim.order.date_end)}</span>
                        </div>
                        <div className={styles.LabelBox}>
                          <div className={styles.LabelTitle}>Massimale</div>
                          <span>{claim.order.total_price} euro</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.ClaimDetailsCard}>
                    <div className={styles.ClaimDetailsCardTitle}>Informazioni sinistro</div>
                    <div className={styles.LabelContainer}>
                      <div className={styles.LabelBoxTextField}>
                        <div className={styles.LabelTitle}>Data Sinistro</div>
                        {isEditing ? (
                          <div className={styles.DatePickerSelector}>
                            <span>{formatDate(editData.date_claim)}</span> <img src="/consulente.svg" alt="calendar" onClick={() => toggleDatePicker(index)} />
                            {visibleDatePickers[index] && (
                              <div className={styles.DatePickerContainer}>
                                <DatePicker
                                  month={currentMonth}
                                  year={currentYear}
                                  disableDatesAfter={today}
                                  allowRange={false}
                                  onChange={handleDateChange}
                                  onMonthChange={handleMonthChange}
                                  selected={new Date(editData.date_claim)}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <span>{formatDate(claim.date_claim)}</span>
                        )}
                      </div>
                      <div className={styles.LabelBoxTextField}>
                        <div className={styles.LabelTitle}>Apertura sinistro</div>
                        <span>{formatDate(claim.date_created)}</span>
                      </div>
                    </div>
                    <div className={styles.LabelContainer}>
                      {!isEditing ? (
                        <>
                          <div className={styles.LabelBoxTextField}>
                            <div className={styles.LabelTitle}>Stato</div>
                            <span>{claim.address.country}</span>
                          </div>
                          <div className={styles.LabelBoxTextField}>
                            <div className={styles.LabelTitle}>Città (provincia)</div>
                            <span>
                              {claim.address.city} ({claim.address.province})
                            </span>
                          </div>
                          <div className={styles.LabelBoxTextField}>
                            <div className={styles.LabelTitle}>Cap</div>
                            <span>{claim.address.zip}</span>
                          </div>
                          <div className={styles.LabelBoxTextField}>
                            <div className={styles.LabelTitle}>Via</div>
                            <span>{claim.address.line}</span>
                          </div>
                          <div>
                            <div className={styles.LabelTitle}>Cosa è accaduto:</div>
                            <span className={styles.TextArea}>{claim.description}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.LabelBoxTextField}>
                            <div className={styles.LabelTitle}>Via</div>
                            <TextField
                              autoComplete="off"
                              isAccountField
                              label=""
                              value={editData.address.line}
                              onChange={(value) =>
                                setEditData((prevData) => ({
                                  ...prevData,
                                  address: { ...prevData.address, line: value },
                                }))
                              }
                            />
                          </div>
                          <div className={styles.LabelBoxTextField}>
                            <div className={styles.LabelTitle}>Città</div>
                            <TextField
                              autoComplete="off"
                              isAccountField
                              label=""
                              value={editData.address.city}
                              onChange={(value) =>
                                setEditData((prevData) => ({
                                  ...prevData,
                                  address: { ...prevData.address, city: value },
                                }))
                              }
                            />
                          </div>
                          <div className={styles.LabelBoxTextField}>
                            <div className={styles.LabelTitle}>Provincia</div>
                            <TextField
                              autoComplete="off"
                              isAccountField
                              label=""
                              value={editData.address.province}
                              onChange={(value) =>
                                setEditData((prevData) => ({
                                  ...prevData,
                                  address: { ...prevData.address, province: value },
                                }))
                              }
                            />
                          </div>
                          <div className={styles.LabelBoxTextField}>
                            <div className={styles.LabelTitle}>Cap</div>
                            <TextField
                              autoComplete="off"
                              isAccountField
                              label=""
                              value={editData.address.zip}
                              onChange={(value) =>
                                setEditData((prevData) => ({
                                  ...prevData,
                                  address: { ...prevData.address, zip: value },
                                }))
                              }
                            />
                          </div>
                          <div className={styles.TextArea}>
                            <div className={styles.LabelTitle}>Cosa è accaduto:</div>
                            <TextField
                              autoComplete="off"
                              isAccountField
                              label=""
                              multiline={4}
                              maxHeight={100}
                              value={editData.description}
                              onChange={(value) => setEditData((prevData) => ({ ...prevData, description: value }))}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    {audioFile ? (
                      <div className={styles.AudioPlayerContainer}>
                        <div className={styles.LabelTitle}>Registrazione dell'accaduto</div>
                        <div className={styles.AudioPlayer}>
                          <audio
                            ref={(element) => {
                              audioRefs.current[index] = element;
                            }}
                            src={audioUrl ? audioUrl : ''}
                            onLoadedMetadata={(e) => {
                              setDuration((prev) => ({
                                ...prev,
                                [index]: e.currentTarget.duration,
                              }));
                            }}
                            onEnded={() => {
                              setIsPlaying((prev) => ({
                                ...prev,
                                [index]: false,
                              }));
                              setCurrentTime((prev) => ({
                                ...prev,
                                [index]: 0,
                              }));
                            }}
                          />
                          <div className={styles.AudioControls}>
                            <div onClick={() => handlePlayPause(index)} className={classNames(styles.playerControlButton, isPlaying[index] && styles.Pause)}>
                              {isPlaying[index] ? <img src="/icon_play.svg" /> : <img style={{ marginLeft: '7px' }} src="/icon_pause.svg" />}
                            </div>
                            <div className={styles.progressBarContainer}>
                              <div className={styles.progressBar} style={{ width: `${progressBarWidth}%` }}></div>
                            </div>
                            <div className={styles.AudioTime}>
                              <span>{currentTime[index] ? formatTime(currentTime[index]) : '0:00'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      isEditing && (
                        <div className={styles.RecordingContainer}>
                          {isRecording ? (
                            <>
                              <div onClick={stopRecording}>
                                <img src="/icon_mic_red.svg" alt="recorder" />
                              </div>
                              <span style={{ display: 'flex', gap: '10px' }}>
                                Registrazione audio: {formatTime(recordingTime)} <img onClick={deleteRecording} src="/icon_x.svg" alt="" />
                              </span>
                            </>
                          ) : (
                            <>
                              <div onClick={startRecording}>
                                <img src="/icon_mic_dark.svg" alt="recorder" />
                              </div>
                              <span>Raccontaci cos'è successo</span>
                            </>
                          )}
                        </div>
                      )
                    )}

                    <div className={styles.Documents}>
                      <div className={styles.LabelTitle}>Documenti / Immagini allegate</div>
                      {isEditing ? (
                        <>
                          <DropZone label="" onDrop={handleDrop} accept="image/*">
                            <DropZone.FileUpload />
                          </DropZone>
                          {files.map((file) => (
                            <div className={styles.DocumentsContainer}>
                              <div key={file.key} className={styles.DocumentItem}>
                                <div>{file.title}</div>
                                <div className={styles.DocumentActions}>
                                  <img src="/icon_x.svg" alt="Download" onClick={() => handleRemoveFile(file.key)} style={{ cursor: 'pointer' }} />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className={styles.DocumentsContainer}>
                          {documents.length > 0 ? (
                            documents.map((file: any) => (
                              <div key={file._id} className={styles.DocumentItem}>
                                <div>{file.title}</div>
                                <div className={styles.DocumentActions}>
                                  <img
                                    src="/icon_download.svg"
                                    alt="Download"
                                    onClick={() => handleDownloadFile(claim._id, file.key, file.title, file.file_type)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className={styles.TextCentered}>Non ci sono Documenti / Immagini allegate</div>
                          )}
                        </div>
                      )}
                    </div>

                    {!disableEditing && (
                      <div className={styles.ButtonContainer}>
                        {isEditing ? (
                          <>
                            <Button width150 primary onClick={() => handleSave(claim._id)}>
                              Salva
                            </Button>
                            <Button width150 primaryPrev onClick={resetEditing}>
                              Annulla
                            </Button>
                          </>
                        ) : (
                          <Button primary onClick={() => handleEdit(index, claim)}>
                            Modifica Informazioni
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
