import { Modal } from '../../../../imb-react';
import { ProductsReportType } from '../Report/productsReport';
import {
  PolicyAssistance,
  PolicyCivilLiability,
  PolicyFire,
  PolicyInjuries,
  PolicyLegal,
  PolicyElectronics,
  PolicyFinancialLoss,
  PolicyHealth,
  PolicyTheft,
} from './markup';

import styles from './ReportModal.module.scss';

type ReportModalType = {
  /** The modal status */
  active: boolean;
  /** Handler of the modal closing */
  onClose: () => void;
  /** The product that should be displayed */
  item: ProductsReportType;
  /** The markup that should be displayed inside the modal */
  content?:
    | 'ASSICURAZIONE (età piano sanitario)'
    | 'ASSICURAZIONE (attività lavorativa 1)'
    | 'ASSICURAZIONE (attività lavorativa 2)'
    | 'ASSICURAZIONE (attività lavorativa 3)'
    | 'ASSICURAZIONE (locale commerciale rct)'
    | 'ASSICURAZIONE (locale commerciale inc&scoppio)'
    | 'ASSICURAZIONE (locale commerciale tutela legale)'
    | 'ASSICURAZIONE (locale commerciale furto)'
    | 'ASSICURAZIONE (locale commerciale rct)'
    | 'ASSICURAZIONE  (figli infortuni famiglia)'
    | 'ASSICURAZIONE (immobili rc casa)';
};

export function ReportModal({ active, onClose, item }: ReportModalType) {
  /**
   * Handler of correct report modal content
   */
  const handleContent = () => {
    switch (item.type) {
      case 'ASSICURAZIONE (età piano sanitario)':
        return <PolicyHealth />;
      case 'ASSICURAZIONE (attività lavorativa 1)':
        return <PolicyAssistance />;
      case 'ASSICURAZIONE (attività lavorativa 2)':
        return <PolicyLegal />;
      case 'ASSICURAZIONE (attività lavorativa 3)':
        return <PolicyInjuries />;
      case 'ASSICURAZIONE (locale commerciale rct)':
        return <PolicyAssistance />;
      case 'ASSICURAZIONE (locale commerciale inc&scoppio)':
        return <PolicyFire />;
      case 'ASSICURAZIONE (locale commerciale tutela legale)':
        return <PolicyLegal />;
      case 'ASSICURAZIONE (locale commerciale furto)':
        return <PolicyTheft />;
      case 'ASSICURAZIONE (locale commerciale rct)':
        return <PolicyAssistance />;
      case 'ASSICURAZIONE  (figli infortuni famiglia)':
        return <PolicyAssistance />;
      case 'ASSICURAZIONE (immobili rc casa':
        return <PolicyAssistance />;
    }
  };

  /**
   * Render the modal title right content
   */
  const titleRightContent = (
    <div className={styles.ReportModalHeader__right}>
      <p>A partire da</p>
      <p>{/* <span className={styles.ReportModalHeader__right__price}>{(item.price / 100).toFixed(2)} €</span>/mese */}</p>
    </div>
  );

  return (
    <Modal
      open={active}
      onClose={onClose}
      title={`${item.label}`}
      titleRightContent={titleRightContent}
      primaryAction={{
        content: 'Voglio il preventivo gratuito',
        onAction: onClose,
      }}
      primaryActionBold
      primaryActionUppercase
      primaryActionCentered
    >
      <Modal.Section>{handleContent()}</Modal.Section>
    </Modal>
  );
}
