import { ChevronRightMinor } from '@shopify/polaris-icons';
import { Button, Link } from '../../../../imb-react';
import styles from './Policies.module.scss';

import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { classNames } from '../../../../imb-react/utilities/css';
import { Order } from '../../../../types';
import { getPolicyExpirationDate } from '../../../../utils/Common';

type PoliciesProps = {
  purpose?: string;
  handleSelectedPolicy?: (policy: any) => void;
  policyId?: string;
};

export function Policies({ purpose, handleSelectedPolicy, policyId }: PoliciesProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [policies, setPolicies] = useState<any[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePolicyClick = (policy: any) => {
    if (handleSelectedPolicy != undefined) {
      handleSelectedPolicy(policy);
    }
  };

  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        setIsLoading(true);
        let response;
        if (policyId) {
          response = await axios.get(`${process.env.REACT_APP_API_URL || '/api'}/user/orders/${policyId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          });
          setPolicies([response.data.data]);
        } else {
          response = await axios.get(`${process.env.REACT_APP_API_URL || '/api'}/user/policies`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          });
          setPolicies(response.data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPolicies();
  }, [policyId]);

  const emptyMarkup = (
    <div className={styles.PoliciesBody}>
      <div className={styles.PoliciesEmpty__box}>
        <div className={styles.PoliciesEmpty__text}>
          <p>Non hai polizze attive</p>
        </div>
        <div className={styles.PoliciesEmpty__box__icon}>
          <img src="/icon_add.svg" alt="Empty policies" />
        </div>
        <div className={styles.PoliciesEmpty__box__button}>
          <Link url="/products/other" imbDarkLow>
            Aggiungi le tue polizze attive
          </Link>
        </div>
      </div>
    </div>
  );

  const renderStatus = (date: Date) => {
    const expirationDate = new Date(date);
    expirationDate.setDate(expirationDate.getDate() + 30);

    const today = new Date();
    // const expirationDate = new Date(date_end);

    if (expirationDate < today) {
      return { expiring: true, message: 'Scaduta' };
    } else {
      return { expiring: false, message: 'Attiva' };
    }
  };

  const policiesMarkup = (
    <>
      <div className={styles.PoliciesContainer}>
        <div className={styles.PoliciesBody}>
          {policies.map((order: Order, index) => (
            <div
              key={index}
              className={classNames(
                styles.PoliciesBox,
                order.date_created && renderStatus(new Date(order.date_created)).expiring ? styles.expiring : styles.active,
              )}
            >
              <div className={styles.PoliciesBox__data}>
                <div>
                  <div className={styles.PoliciesBox__title}>{order.line_items[0].name}</div>
                  <div className={`${styles.PoliciesBox__text} ${styles.PoliciesBox__status}`}>
                    {order.date_created && renderStatus(new Date(order.date_created)).message}
                  </div>
                  <div className={styles.PoliciesBox__icon}>
                    <img
                      src={order.line_items[0].product.icon && process.env.REACT_APP_BLOB_IMAGES_URL + order.line_items[0].product.icon.key}
                      alt={order.line_items[0].product.icon && order.line_items[0].product.icon.title}
                    />
                  </div>
                </div>

                <div className={styles.PoliciesBox__button}>
                  {purpose === 'view' ? (
                    <Button
                      primary={!renderStatus(new Date(order.date_created)).expiring}
                      destructive={renderStatus(new Date(order.date_created)).expiring}
                      url="/products/policies"
                    >
                      Vedi dettagli
                    </Button>
                  ) : (
                    <Button
                      primary={!renderStatus(new Date(order.date_created)).expiring}
                      destructive={renderStatus(new Date(order.date_created)).expiring}
                      onClick={() => handleSelectedPolicy && handleSelectedPolicy(order)}
                    >
                      Apri sinistro
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return <div>{policies.length > 0 ? policiesMarkup : emptyMarkup}</div>;
}
