import React, { useCallback, useRef, useState } from 'react';

import { Banner, Frame, Layout, Loading, Page, UnstyledLink } from '../../imb-react';
import { TopBarMarkup, NavigationMarkup } from '../../components';

import styles from './Products.module.scss';
import { useUser } from '../../utils/PrivateRoute';
import { classNames } from '../../imb-react/utilities/css';
import { PersonalPolicies, Policies, PolicyModal, QuoteModal, Quotes } from './components';
import { Order } from '../../types';
import { useParams } from 'react-router-dom';

export function Products() {
  const { user } = useUser();
  const params = useParams();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Quotes
   */
  const [quoteModalActive, setQuoteModalActive] = useState(false);
  const [quote, setQuote] = useState<Order>({} as Order);

  const handleQuoteModalChange = useCallback(() => setQuoteModalActive((quoteModalActive) => !quoteModalActive), []);
  const handleSelectQuote = useCallback((quote: any) => {
    setQuoteModalActive((quoteModalActive) => !quoteModalActive);
    setQuote(quote);
  }, []);

  /**
   * Policies
   */
  const [policyModalActive, setPolicyModalActive] = useState(false);
  const [policy, setPolicy] = useState<Order>({} as Order);

  const handlePolicyModalChange = useCallback(() => setPolicyModalActive((policyModalActive) => !policyModalActive), []);
  const handleSelectPolicy = useCallback((quote: any) => {
    setPolicyModalActive((policyModalActive) => !policyModalActive);
    setPolicy(quote);
  }, []);

  /**
   * Loading markup
   */
  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Render products content based on the current route
   */
  const renderProductsContent = () => {
    switch (params.id) {
      case 'quotes':
        return <Quotes onSelect={handleSelectQuote} />;
      case 'policies':
        return <Policies onSelect={handleSelectPolicy} />;
      case 'other':
        return <PersonalPolicies />;
      default:
        return <Quotes onSelect={handleSelectQuote} />;
    }
  };

  /**
   * Banner markup
   */
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  /**
   * Page markup
   */
  const pageMarkup = (
    <Page fullWidth>
      {/* Banners */}
      {bannerMarkup}

      {/* Header */}
      <div className={styles.ProductsHeader}>
        <div className={styles.Header}>
          {/* title */}
          <div className={styles.Header__title}>
            Ciao, {user.name}
            <br />
            ecco la sezione dedicata alle tue assicurazioni
          </div>
          {/* search input */}
        </div>
      </div>

      {/* Products navigation */}
      <div className={styles.ProductsNavigation}>
        <div className={classNames(styles.ProductsNavigationBox, (params.id === 'quotes' || params.id === undefined) && styles.active)}>
          <UnstyledLink url="/products/quotes" className={styles.ProductsNavigationBox__link}>
            Preventivi
          </UnstyledLink>
        </div>
        <div className={classNames(styles.ProductsNavigationBox, params.id === 'policies' && styles.active)}>
          <UnstyledLink url="/products/policies" className={styles.ProductsNavigationBox__link}>
            Polizze
          </UnstyledLink>
        </div>
        <div className={classNames(styles.ProductsNavigationBox, params.id === 'other' && styles.active)}>
          <UnstyledLink url="/products/other" className={styles.ProductsNavigationBox__link}>
            Altro
          </UnstyledLink>
        </div>
      </div>

      {/* Products content */}
      <div className={styles.ProductsContent}>{renderProductsContent()}</div>
    </Page>
  );

  return (
    <div className="AppContainer">
      <Frame
        topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
        navigation={<NavigationMarkup user={user} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef}
        offset="2rem"
      >
        {/* {loadingMarkup} */}
        {pageMarkup}
        {<QuoteModal active={quoteModalActive} onClose={handleQuoteModalChange} quote={quote} />}
        {<PolicyModal active={policyModalActive} onClose={handlePolicyModalChange} policy={policy} />}
      </Frame>
    </div>
  );
}
