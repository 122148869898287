import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form, FormLayout, TextField, InlineError, Link, Frame, Toast, Icon } from '../../imb-react';

const HideIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M11.977 4.751a7.598 7.598 0 0 0-1.977-.251c-2.444 0-4.196 1.045-5.325 2.233a7.188 7.188 0 0 0-1.243 1.773c-.26.532-.432 1.076-.432 1.494 0 .418.171.962.432 1.493.172.354.4.734.687 1.116l1.074-1.074a5.388 5.388 0 0 1-.414-.7c-.221-.453-.279-.753-.279-.835 0-.082.058-.382.279-.835a5.71 5.71 0 0 1 .983-1.398c.89-.937 2.264-1.767 4.238-1.767.24 0 .471.012.693.036l1.284-1.285Z"/><path fill-rule="evenodd" d="M4.25 14.6a.75.75 0 0 0 1.067 1.053l1.062-1.061c.975.543 2.177.908 3.621.908 2.45 0 4.142-1.05 5.24-2.242 1.078-1.17 1.588-2.476 1.738-3.076a.749.749 0 0 0 0-.364c-.15-.6-.66-1.906-1.738-3.076a7.245 7.245 0 0 0-.51-.502l.923-.923a.75.75 0 0 0-1.053-1.068l-.008.008-10.335 10.336-.008.007Zm5.75-.6c-.978 0-1.809-.204-2.506-.523l1.108-1.109a2.75 2.75 0 0 0 3.766-3.766l1.3-1.299c.169.147.325.3.469.455a6.387 6.387 0 0 1 1.332 2.242 6.387 6.387 0 0 1-1.332 2.242c-.86.933-2.17 1.758-4.137 1.758Zm0-2.75c-.087 0-.172-.01-.254-.026l1.478-1.478a1.25 1.25 0 0 1-1.224 1.504Z"/></svg>';

const ShowIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-1.5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/><path fill-rule="evenodd" d="M10 4c-2.476 0-4.348 1.23-5.577 2.532a9.266 9.266 0 0 0-1.4 1.922 5.98 5.98 0 0 0-.37.818c-.082.227-.153.488-.153.728s.071.501.152.728c.088.246.213.524.371.818.317.587.784 1.27 1.4 1.922 1.229 1.302 3.1 2.532 5.577 2.532 2.476 0 4.348-1.23 5.577-2.532a9.265 9.265 0 0 0 1.4-1.922 5.98 5.98 0 0 0 .37-.818c.082-.227.153-.488.153-.728s-.071-.501-.152-.728a5.984 5.984 0 0 0-.371-.818 9.269 9.269 0 0 0-1.4-1.922c-1.229-1.302-3.1-2.532-5.577-2.532Zm-5.999 6.002v-.004c.004-.02.017-.09.064-.223a4.5 4.5 0 0 1 .278-.608 7.768 7.768 0 0 1 1.17-1.605c1.042-1.104 2.545-2.062 4.487-2.062 1.942 0 3.445.958 4.486 2.062a7.77 7.77 0 0 1 1.17 1.605c.13.24.221.447.279.608.047.132.06.203.064.223v.004c-.004.02-.017.09-.064.223a4.503 4.503 0 0 1-.278.608 7.768 7.768 0 0 1-1.17 1.605c-1.042 1.104-2.545 2.062-4.487 2.062-1.942 0-3.445-.958-4.486-2.062a7.766 7.766 0 0 1-1.17-1.605 4.5 4.5 0 0 1-.279-.608c-.047-.132-.06-.203-.064-.223Z"/></svg>';

import './../LoginPage/Login.css';
import logo from '../../logo_bw.svg';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { getAuth, getIdToken, GoogleAuthProvider, OAuthProvider, onAuthStateChanged, signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { app } from '../../utils/firebase';

export function RegisterPage() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [privacy, setPrivacy] = useState(true); // TO-DO
  // Used for different passwords
  const [passwordError, setPasswordError] = useState(false);
  // Used for password length
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  // Used for generic error
  const [error, setError] = useState(false);
  // Used for already existing user
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  // Used for toast
  const [success, setSuccess] = useState(false);
  // Used for error on fields
  const [emptyFields, setEmptyFields] = useState({
    name: false,
    lastName: false,
    email: false,
    password: false,
    repeatedPassword: false,
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);
  const togglePasswordConfirmationVisibility = () => setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);

  const auth = getAuth(app);
  useEffect(() => {
    console.log('here');
    onAuthStateChanged(auth, (user) => {
      console.log(user);
      if (user) {
        setIsLoading(true);
        axios
          .post(
            (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/register',
            { name: user.providerData[0].displayName, email: user.providerData[0].email, firebase_uuid: user.uid, privacy: 'true', alien: 'false' },
            {
              timeout: 5000,
            },
          )
          .then((response) => {
            if (response.data.status === 'success') {
              // Save bearer token into axios
              localStorage.setItem('mb__access_token', response.data.data.token);
              setSuccess(true);
              // Redirect to login page after 5 seconds
              navigate('/login');
            }
          })
          .catch((error) => {
            const axiosError = error as AxiosError;
            console.log(axiosError);
            const status = axiosError.response?.status || 500;
            if (status === 409) {
              navigate('/login');
            } else {
              setError(true);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  }, []);

  const handleGoogleLogin = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    await signInWithPopup(auth, provider);
  }, []);

  const handleMicrosoftLogin = useCallback(async () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('email');
    await signInWithRedirect(auth, provider);
  }, []);

  const handleAppleLogin = useCallback(async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    await signInWithRedirect(auth, provider);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      setPasswordError(false);
      setPasswordLengthError(false);
      setError(false);
      setUserAlreadyExists(false);

      // Check if fields are empty and set the corresponging emptyFields state
      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
      } else {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: false }));
      }
      if (lastName === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, lastName: true }));
      } else {
        setEmptyFields((emptyFields) => ({ ...emptyFields, lastName: false }));
      }
      if (email === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, email: true }));
      } else {
        setEmptyFields((emptyFields) => ({ ...emptyFields, email: false }));
      }
      if (password === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, password: true }));
      } else {
        setEmptyFields((emptyFields) => ({ ...emptyFields, password: false }));
      }
      if (repeatedPassword === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, repeatedPassword: true }));
      } else {
        setEmptyFields((emptyFields) => ({ ...emptyFields, repeatedPassword: false }));
      }

      // Check if there are empty fields
      if (name === '' || lastName === '' || email === '' || password === '' || repeatedPassword === '') {
        return;
      }

      // Check if password is long enough
      if (password.length < 6) {
        setPasswordLengthError(true);
        return;
      }

      // Check if passwords are the same
      if (password !== repeatedPassword) {
        setPasswordError(true);
        return;
      }

      const req = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/register',
        { name: name, email: email, password: password, privacy: privacy ? 'true' : 'false', lastname: lastName, alien: 'false' },
        {
          timeout: 5000,
        },
      );
      const response = req.data;

      if (response.status === 'success') {
        setSuccess(true);

        // Redirect to login page after 5 seconds
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;
      if (status === 409) {
        setUserAlreadyExists(true);
      } else {
        setError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [name, lastName, email, history, password, privacy, repeatedPassword, emptyFields]);

  /** Name handler */
  const handleNameChange = useCallback(
    (value: any) => {
      // If the field is not empty, remove the error
      if (value !== '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: false }));
      }
      setName(value);
    },
    [emptyFields],
  );

  /** Last name handler */
  const handleLastNameChange = useCallback(
    (value: any) => {
      // If the field is not empty, remove the error
      if (value !== '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, lastName: false }));
      }
      setLastName(value);
    },
    [emptyFields],
  );

  /** Email handler */
  const handleEmailChange = useCallback(
    (value: any) => {
      // If the field is not empty, remove the error
      if (value !== '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, email: false }));
      }
      setEmail(value);
    },
    [emptyFields],
  );

  /** Password handler */
  const handlePwdChange = useCallback(
    (value: any) => {
      // If the field is not empty, remove the error
      if (value !== '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, password: false }));
      }
      setPassword(value);
    },
    [emptyFields],
  );

  /** Repeated password handler */
  const handleRepeatedPwdChange = useCallback(
    (value: any) => {
      // If the field is not empty, remove the error
      if (value !== '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, repeatedPassword: false }));
      }
      setRepeatedPassword(value);
    },
    [emptyFields],
  );

  /**
   * Toast markup
   */
  const toggleToastActive = useCallback(() => setSuccess((success) => !success), []);
  const toastMarkup = success ? (
    <Toast textColor="white" content="L'utente è stato creato con successo. A breve sarai reindirizzato alla pagina di login." onDismiss={toggleToastActive} />
  ) : null;

  return (
    <Frame offset="0">
      <div className="page-main">
        <div className="page-content">
          <div className="login-card ">
            <header className="login-card__header">
              <h1 className="login-card__logo">
                <a href="/">
                  <img src={logo} />
                </a>
              </h1>
            </header>

            <div className="login-card__content">
              <div className="login-card__hero">
                <img src="/login_hero.png" />
              </div>
              <div className="main-card-section">
                <h2 className="ui-heading">Registrazione</h2>
                <h3 className="ui-subheading ui-subheading--subdued">Ciao! Scopri un mondo assicurativo semplice, veloce e a portata di mano</h3>
                <div className="firebaseui-container">
                  <div className="firebaseui-method-content">
                    <img src="/icon_google.svg" onClick={handleGoogleLogin} />
                  </div>
                </div>
                <div className="login-card__divider">
                  <span className="login-card__divider--line" />
                  <span>oppure</span>
                  <span className="login-card__divider--line" />
                </div>
                <div>
                  <Form onSubmit={handleSubmit}>
                    <FormLayout>
                      <FormLayout.Group>
                        <TextField
                          autoComplete="on"
                          value={name}
                          onChange={handleNameChange}
                          label="Nome"
                          type="text"
                          placeholder="Scrivi il tuo nome..."
                          error={emptyFields.name && 'Il campo è obbligatorio'}
                        />
                        <TextField
                          autoComplete="on"
                          value={lastName}
                          onChange={handleLastNameChange}
                          label="Cognome"
                          type="text"
                          placeholder="Scrivi il tuo cognome..."
                          error={emptyFields.lastName && 'Il campo è obbligatorio'}
                        />
                      </FormLayout.Group>
                      <TextField
                        autoComplete="on"
                        value={email}
                        onChange={handleEmailChange}
                        label="Email"
                        type="email"
                        placeholder="Scrivi il tuo indirizzo email..."
                        error={(emptyFields.email && 'Il campo è obbligatorio') || (userAlreadyExists && 'Usare un indirizzo email diverso')}
                      />
                      <TextField
                        autoComplete="on"
                        value={password}
                        onChange={handlePwdChange}
                        label="Password"
                        type={isPasswordVisible ? 'text' : 'password'}
                        placeholder="Inserisci la tua password..."
                        helpText="La password deve essere lunga almeno 6 caratteri"
                        suffix={
                          <div onClick={togglePasswordVisibility}>
                            <Icon source={isPasswordVisible ? HideIcon : ShowIcon} />
                          </div>
                        }
                        error={
                          (emptyFields.password && 'Il campo è obbligatorio') || (passwordLengthError && 'La password deve essere lunga almeno 6 caratteri')
                        }
                      />
                      <TextField
                        autoComplete="on"
                        value={repeatedPassword}
                        onChange={handleRepeatedPwdChange}
                        label="Ripeti password"
                        type={isPasswordConfirmationVisible ? 'text' : 'password'}
                        placeholder="Inserisci di nuovo la password..."
                        error={
                          (emptyFields.repeatedPassword && 'Il campo è obbligatorio') ||
                          (passwordLengthError && 'La password deve essere lunga almeno 6 caratteri')
                        }
                        suffix={
                          <div onClick={togglePasswordConfirmationVisibility}>
                            <Icon source={isPasswordConfirmationVisible ? HideIcon : ShowIcon} />
                          </div>
                        }
                      />
                      {passwordError === true ? <InlineError message="Le password devono coincidere." fieldID="myFieldID" /> : ''}
                      {userAlreadyExists === true ? <InlineError message="Esiste già un utente associato a questo indirizzo email." fieldID="myFieldID" /> : ''}
                      {error === true ? <InlineError message="Qualcosa è andato storto. Riprovare più tardi." fieldID="myFieldID" /> : ''}
                      <div className="login-card__button">
                        <Button primary loading={isLoading} size="large" submit>
                          ENTRA
                        </Button>
                        <div className="login-card__button__text">
                          <p>Hai già l'account?</p>
                          <p>
                            <Link url="/login" removeUnderline>
                              Fai il login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </FormLayout>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <footer className="login-footer">
            <a className="login-footer__link" target="_blank" href="#" title="Centro di assistenza">
              Richiesta di supporto
            </a>
            <a
              className="login-footer__link"
              target="_blank"
              href="https://nano-insurance.com/legal/ilmiobrokerassicurativo/privacy-policy"
              title="Privacy Policy"
            >
              Privacy
            </a>
            <a
              className="login-footer__link"
              target="_blank"
              href="https://nano-insurance.com/legal/ilmiobrokerassicurativo/termini-e-condizioni"
              title="Termini di servizio"
            >
              Termini
            </a>
          </footer>
        </div>
      </div>

      {/* Toast markup */}
      {toastMarkup}
    </Frame>
  );
}
