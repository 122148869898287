import { Badge } from '../imb-react';
import axios from 'axios';
import React from 'react';

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
};

// set the token and user from the session storage
export const setUserSession = (token: string, user: any) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
};

/**
 * Verify authentication
 */
export const verifyToken = async () => {
  try {
    const response = await axios.post(
      (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/auth',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        timeout: 5000,
      },
    );
    const data = response.data;
    if (data.status === 'success') {
      return { status: true, data: data.data };
    } else {
      return { status: false, data: null };
    }
  } catch (err) {
    console.log(`⚡️ An error occurred: ${err}`);
    return false;
  }
};

/**
 * Refresh token
 */
export const refreshToken = async () => {
  try {
    const response = await axios.post(
      (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/refresh-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        timeout: 5000,
      },
    );
    const data = response.data;
    if (data.status === 'success') {
      console.log(data.data);
      // Save bearer token into axios
      localStorage.setItem('mb__access_token', data.data.token);
      return { status: 'success' };
    } else {
      return { status: 'failed', data: null };
    }
  } catch (err) {
    console.log(`⚡️ An error occurred: ${err}`);
    return false;
  }
};

/**
 * Parse date label
 * @param {Date} date
 * @returns {string} dd/mm/yyyy
 */
export const parseDateLabel = (date: Date): string => {
  const newDate = new Date(date);
  return `${('0' + newDate.getDate()).slice(-2)}/${('0' + (newDate.getMonth() + 1)).slice(-2)}/${newDate.getFullYear()}`;
};

/**
 * Get quote expiration date
 * @param {Date} date
 * @returns {string} dd/mm/yyyy
 */
export const getQuoteExpirationDate = (date: Date) => {
  // The expiration date is 30 days after the creation date
  const expirationDate = new Date(date);
  expirationDate.setDate(expirationDate.getDate() + 7);

  const dateLabel = parseDateLabel(expirationDate);
  return dateLabel;
};

/**
 * Get quote expiration date in format 19g 23h 59min 00sec
 * @param {Date} date
 * @returns {string} 19g 23h 59min 00sec
 */
export const getQuoteExpirationDateFormatted = (date: Date) => {
  // The expiration date is 30 days after the creation date
  const expirationDate = new Date(date);
  expirationDate.setDate(expirationDate.getDate() + 7);

  const days = Math.floor((expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60)) % 24;
  const minutes = Math.floor((expirationDate.getTime() - new Date().getTime()) / (1000 * 60)) % 60;
  const seconds = Math.floor((expirationDate.getTime() - new Date().getTime()) / 1000) % 60;

  return `${days}g ${hours}h ${minutes}min ${seconds}sec`;
};
/**
 * Get policy expiration date
 * @param {Date} date
 * @returns {string} dd/mm/yyyy
 */
export const getPolicyExpirationDate = (date: Date) => {
  const expirationDate = new Date(date);

  const dateLabel = parseDateLabel(expirationDate);
  return dateLabel;
};

/**
 * Get policy expiration date in format 19g
 * @param {Date} date
 * @returns {string} 19g
 */
export const getPolicyExpirationDateFormatted = (date: Date) => {
  const expirationDate = new Date(date);

  const days = Math.floor((expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
  return `${days} giorni`;
};

/**
 * Convert date in format DD and short month name
 * @param date
 * @returns string
 */
export const parseDateLabelChart = (date: Date) => {
  return `${('0' + date.getDate()).slice(-2)} ${date.toLocaleString('it-IT', { month: 'short' })}`;
};

/**
 * Get initials from full name
 */
export const getInitials = (name: string): string => {
  const initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
  return String(initials?.join(''));
};

const branchOptions = [
  { label: "RC Verso Terzi e Prestatori d'Opera", value: '0' },
  { label: 'RC del Prodotto', value: '1' },
  { label: 'Sperimentazione Clinica', value: '2' },
  { label: 'Aviation', value: '3' },
  { label: 'Eventi Metereologici Catastrofali e Non', value: '4' },
  { label: 'Cancellazione Eventi', value: '5' },
  { label: 'Over Redemption', value: '6' },
  { label: 'Prize Indemnity', value: '7' },
  { label: 'RCT/O', value: '8' },
  { label: 'RC Patrimoniale', value: '9' },
  { label: 'Colpa Grave Dirigenti e Dipendenti di Enti Pubblici', value: '10' },
  { label: 'Colpa Grave Dirigenti e Dipendenti del Servizio Sanitario Nazionale', value: '11' },
  { label: 'Trattative Private', value: '12' },
  { label: 'Partecipazione a Gare', value: '13' },
  { label: 'Trasporti', value: '14' },
  { label: 'Project Cargo', value: '15' },
  { label: 'Private Collectors', value: '16' },
  { label: 'SicurArredo', value: '17' },
  { label: 'I Grandi Vini', value: '18' },
];
export const bindRamo = (ramo: number) => {
  for (const item of branchOptions) {
    if (Number(ramo) === Number(item.value)) return item.label;
  }
};

/**
 * Render status badge
 * @param status
 * @returns
 */
export const renderOrderStatusBadge = (status: string | undefined | null) => {
  let statusMarkup;
  if (status === 'quote') {
    statusMarkup = (
      <Badge status="info" progress="incomplete">
        Preventivo
      </Badge>
    );
  } else if (status === 'quote_accepted') {
    statusMarkup = (
      <Badge status="attention" progress="partiallyComplete">
        Preventivo accettato
      </Badge>
    );
  } else if (status === 'quote_rejected') {
    statusMarkup = (
      <Badge status="attention" progress="partiallyComplete">
        Preventivo rifiutato
      </Badge>
    );
  } else if (status === 'paid') {
    statusMarkup = (
      <Badge status="success" progress="complete">
        Pagato
      </Badge>
    );
  } else if (status === 'expired') {
    statusMarkup = (
      <Badge status="attention" progress="complete">
        Scaduta
      </Badge>
    );
  } else {
    statusMarkup = '';
  }

  return statusMarkup;
};
